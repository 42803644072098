import styled from '@emotion/styled';
import clsx from 'clsx';

import { useGameContext, useLogicStateContext } from '@roulette/contexts';

import { ChipRow, PopupBox } from '@roulette/components';
import { GameSettings } from '../components/game-settings';

import { Timer, WinComponent, PopupMinimized } from '@roulette/components';
import { RoundWinners } from '@shared/ui/components';

import { useWebsocketContext } from '@shared/ui/contexts';

import { DuplicatePopUp } from '@shared/ui/components';
import { Racetrack } from '../components/racetrack';

import { FaPlus } from 'react-icons/fa';
import { FaMinus } from 'react-icons/fa';
import { RouletteStates, boardNumbers } from '@roulette/models';
import { useEffect, useState } from 'react';
import { Board } from '../components/new-board/board';
import { ExpandRacetrackButton } from '@roulette/components';

const PERSPECTIVE_TIMER = 1000;

const Container = styled.div`
    display: flex;

    width: 100%;
    height: 100%;

    box-sizing: border-box;
    flex-direction: column;
    position: relative;

    pointer-events: none;

    user-select: none;

    color: var(--roulette-white-color);
    font-size: 1.4rem;

    svg .hover {
        opacity: 0;
    }
`;

const AppBody = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;

    box-sizing: border-box;

    height: 100%;

    overflow: hidden;
`;

const LeftColumnSide = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    pointer-events: none;

    width: 56.8rem;
    height: 100%;

    box-sizing: border-box;
`;

const RightColumnSide = styled.div`
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    pointer-events: none;

    width: 56.8rem;
    height: 100%;

    box-sizing: border-box;

    /* border: 0.2rem solid purple; */
    box-sizing: border-box;
`;

const ColumnCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;

    pointer-events: auto;

    box-sizing: border-box;

    width: 78.4rem;
    height: 100%;

    .winning-number {
        top: 20rem;
        position: absolute;
    }
`;

const GameInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;

    box-sizing: border-box;

    width: 78.4rem;
`;

const GameBoard = styled.div<{ changePerspective: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    width: 78.4rem;
    height: 100%;

    box-sizing: border-box;
`;

const BoardContainer = styled.div<{ changePerspective: boolean }>`
    transform: ${({ changePerspective }) =>
        changePerspective
            ? `translateY(10rem) scale(0.8)`
            : 'rotateX(0deg) translateY(0)'};
    transition: transform ${PERSPECTIVE_TIMER}ms ease-in-out;
`;

const ChipRowContainer = styled.div<{ changePerspective: boolean }>`
    transition: transform ${PERSPECTIVE_TIMER}ms ease-in-out,
        opacity ${PERSPECTIVE_TIMER}ms ease-in-out;
    transform: ${({ changePerspective }) =>
        changePerspective ? 'translateY(10rem)' : 'translateY(0)'};
    opacity: ${({ changePerspective }) => (changePerspective ? 0 : 1)};
    margin-top: 1rem;
`;

const LeftHeader = styled.div`
    display: flex;
    flex-direction: column;
    width: 56.8rem;
    height: 58rem;

    justify-content: end;

    margin-top: 2rem;

    box-sizing: border-box;

    pointer-events: auto;
    cursor: pointer;

    z-index: 1;
`;

const LeftGameInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: auto;
    cursor: pointer;
    width: 56.8rem;

    box-sizing: border-box;

    .left-button-row {
        display: flex;
        width: 50rem;
        height: 4rem;
    }

    .counter-component {
        margin-left: 13rem;
        width: 6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        background: black;
        border: 0.1rem solid black;
        border-radius: 50% / 100%;
        padding: 1rem;
        opacity: 0.5;

        transition: transform 500ms, opacity 200ms ease-in-out;

        .counter {
            text-align: center;
            width: 2rem;
        }

        .icon {
            width: 1.4rem;
            height: 1.4rem;
        }
    }

    .expand-racetrack {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        position: relative;
        /* left: -40%; */

        min-width: 7rem;
        min-height: 3.2rem;
    }

    .hide-racetrack {
        position: relative;
        transform: translateX(-90%);
        transition: transform 500ms ease-in-out;
    }

    .hide-racetrack-counter {
        position: relative;
        transform: translateY(10rem);
        opacity: 0;
    }

    .racetrack {
        position: relative;
        transform: translateX(0);
        transition: transform 500ms ease-in-out;
    }

    .balance-totalbet {
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: end;
        width: 56.8rem;
        height: 19.1rem;

        pointer-events: none;

        /* border: 0.2rem solid red; */
        box-sizing: border-box;

        padding-left: 2rem;
        padding-bottom: 2rem;

        .container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            min-width: 11rem;
            height: 5rem;

            margin-right: 1rem;

            border: 0.1rem solid var(--roulette-border-color);
            border-radius: 1rem;
            background: var(--roulette-opaque-background);

            .box {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;

                min-width: 7.8rem;
                height: 3.9rem;
                padding: 1rem;

                text-transform: uppercase;
                font-family: 'Chakra Petch', sans-serif;

                .title {
                    font-size: 1.2rem;
                    font-weight: 400;
                    line-height: 1.5rem;
                    color: var(--roulette-white-color);
                }

                .value {
                    font-size: 1.9rem;
                    font-weight: 600;
                    line-height: 2.4rem;
                    color: var(--roulette-balance-color);
                }
            }
        }
    }
`;

const RightHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;

    width: 56.8rem;
    height: 63.2rem;
    pointer-events: auto;
    cursor: pointer;

    /* border: 0.2rem solid green; */
    box-sizing: border-box;

    margin-top: 2rem;
`;

const RightGameInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;

    z-index: 1;

    width: 56.8rem;
    height: 43.8rem;

    position: relative;
    top: 4rem;

    box-sizing: border-box;
`;

const GameStateContainer = styled.span`
    position: absolute;
    color: var(--roulette-white-color);
    bottom: 0;
    right: 2rem;
`;

const BottomContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
`;

interface winNumber {
    value: number | null;
    color: string;
}

export const DesktopAppLayout = () => {
    const { duplicateSession } = useWebsocketContext();

    const {
        gameState,
        racetrackAdjacentCounter,
        handleRacetrackAdjacentCounter,
    } = useGameContext();

    const { payoutData } = useLogicStateContext();

    const [winNumber, setWinNumber] = useState<winNumber>({
        value: null,
        color: '',
    });

    const [activePopupBox, setActivePopupBox] = useState('');

    const [showRacetrack, setShowRacetrack] = useState(true);

    const [changePerspective, setChangePerspective] = useState(false);

    const endTime = gameState.endTime;

    const handleLogout = () => {
        window.location.reload();
    };

    useEffect(() => {
        if (gameState.ballPosition || gameState.ballPosition === 0) {
            boardNumbers.forEach((bet) => {
                if (bet.value === gameState.ballPosition) {
                    setWinNumber({ value: bet.value, color: bet.color });
                }
            });
        }
    }, [gameState.ballPosition]);

    useEffect(() => {
        if (
            gameState.currentState.previous_state ===
                RouletteStates.FinishedBetting ||
            gameState.currentState.next_state ===
                RouletteStates.WaitingForWheelSpin
        ) {
            setTimeout(() => {
                setChangePerspective(true);
            }, 500);
        }

        if (gameState.currentState.next_state === RouletteStates.GameEnd) {
            setChangePerspective(false);
        }
    }, [gameState]);

    return (
        <>
            <Container>
                <AppBody>
                    <LeftColumnSide>
                        <LeftHeader>
                            <RoundWinners
                                borderColour="var(--roulette-hover)"
                                fontColour="var(--color-primary)"
                                width={40.5}
                                height={28}
                                data={payoutData}
                                currency={gameState.user?.currency || 'USD'}
                            />
                        </LeftHeader>
                        <LeftGameInfo>
                            <div
                                className={clsx(
                                    showRacetrack
                                        ? 'racetrack'
                                        : 'hide-racetrack'
                                )}
                            >
                                <Racetrack showRacetrack={showRacetrack} />
                            </div>
                            <div className="left-button-row">
                                <div className="expand-racetrack">
                                    <ExpandRacetrackButton
                                        showRacetrack={showRacetrack}
                                        setShowRacetrack={setShowRacetrack}
                                    />
                                </div>
                                <div
                                    className={clsx(
                                        'counter-component',
                                        showRacetrack
                                            ? ''
                                            : 'hide-racetrack-counter'
                                    )}
                                >
                                    <FaMinus
                                        className="icon"
                                        onClick={() =>
                                            handleRacetrackAdjacentCounter(
                                                'decrement'
                                            )
                                        }
                                    />
                                    <span className="counter">
                                        {racetrackAdjacentCounter}
                                    </span>
                                    <FaPlus
                                        className="icon"
                                        onClick={() =>
                                            handleRacetrackAdjacentCounter(
                                                'increment'
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </LeftGameInfo>
                    </LeftColumnSide>
                    <ColumnCenter>
                        <GameInfoContainer>
                            {gameState.roundInfo?.roundId && (
                                <Timer
                                    endTime={endTime}
                                    duration={
                                        gameState.gameInfo?.playerBetDuration
                                    }
                                />
                            )}
                            {(gameState.ballPosition ||
                                gameState.ballPosition === 0) &&
                                (winNumber.value || winNumber.value === 0) &&
                                winNumber.color && (
                                    <div className="winning-number">
                                        <WinComponent
                                            winNumber={winNumber?.value}
                                            totalWon={
                                                gameState.roundInfo?.totalWon ||
                                                0
                                            }
                                            currency={gameState.user?.currency}
                                        />
                                    </div>
                                )}
                            <GameBoard changePerspective={changePerspective}>
                                <BoardContainer
                                    changePerspective={changePerspective}
                                >
                                    <Board />
                                </BoardContainer>
                                <ChipRowContainer
                                    changePerspective={changePerspective}
                                >
                                    <ChipRow />
                                </ChipRowContainer>
                            </GameBoard>
                        </GameInfoContainer>
                    </ColumnCenter>
                    <RightColumnSide>
                        <RightHeader>
                            {process.env.NODE_ENV === 'development' && (
                                <GameSettings />
                            )}
                        </RightHeader>
                        <RightGameInfo>
                            <div className="box">
                                {!activePopupBox && (
                                    <PopupMinimized
                                        setActivePopupBox={setActivePopupBox}
                                    />
                                )}
                                {activePopupBox && (
                                    <PopupBox
                                        type={activePopupBox}
                                        setActivePopupBox={setActivePopupBox}
                                    />
                                )}
                            </div>
                        </RightGameInfo>
                    </RightColumnSide>
                </AppBody>
                <BottomContainer>
                    {process.env.NODE_ENV === 'development' && (
                        <GameStateContainer>
                            <span>{gameState.currentState.next_state}</span>
                        </GameStateContainer>
                    )}
                </BottomContainer>
            </Container>
            <DuplicatePopUp
                isVisible={duplicateSession}
                onClose={handleLogout}
            />
        </>
    );
};
