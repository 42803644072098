import styled from '@emotion/styled';
import { popupBoxComponents } from './popup-box/popup-box.constant';

interface PopupBoxMinizedProps {
    setActivePopupBox: (string: string) => void;
}

interface PopupBoxComponentProps {
    name: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const Container = styled.div`
    width: 5.9rem;
    min-height: 17.9rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; /* Align items to the right */

    box-sizing: border-box;

    position: relative;
    top: 1rem;

    .parent-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;

        box-sizing: border-box;

        gap: 2rem;

        pointer-events: auto;

        border-radius: 0.7rem 0rem 0rem 0.7rem;

        padding: 2rem;

        background: rgba(0, 0, 0, 0.7);

        border: 0.1rem solid var(--color-primary);
        border-right: 0;

        svg {
            fill: var(--color-primary);
        }

        .icon {
            height: 2.4rem;
            width: 2.4rem;

            cursor: pointer;
        }
    }
`;

// Terrible name
export const PopupMinimized = ({ setActivePopupBox }: PopupBoxMinizedProps) => {
    return (
        <Container>
            <div className="parent-container">
                {popupBoxComponents.map((stat: PopupBoxComponentProps) => (
                    <div
                        key={stat.name}
                        className={stat.name}
                        onClick={() => setActivePopupBox(stat.name)}
                    >
                        <stat.icon className="icon" />
                    </div>
                ))}
            </div>
        </Container>
    );
};
