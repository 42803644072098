const hexToRgb = (hex: string): { r: number; g: number; b: number } | null => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
          }
        : null;
};

const rgbToHex = (r: number, g: number, b: number): string => {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()}`;
};

const getMiddleColor = (color1: string, color2: string): string => {
    const rgb1 = hexToRgb(color1);
    const rgb2 = hexToRgb(color2);

    if (!rgb1 || !rgb2) {
        throw new Error('Invalid color format');
    }

    const middleR = Math.round((rgb1.r + rgb2.r) / 2);
    const middleG = Math.round((rgb1.g + rgb2.g) / 2);
    const middleB = Math.round((rgb1.b + rgb2.b) / 2);

    return rgbToHex(middleR, middleG, middleB);
};

export type ChipColors = {
    [key: number]: {
        color: string;
        startColor: string;
        stopColor: string;
    };
};

export type ChipAmountMap = {
    '0.1': number;
    '0.5': number;
    '1': number;
    '5': number;
    '20': number;
    '100': number;
    '250': number;
    '1000': number;
};

export type ChipDefinition = {
    key: keyof ChipAmountMap;
    amount: number;
    color: string;
    startColor: string;
    stopColor: string;
};

export const chipDefinitions: ChipDefinition[] = [
    {
        key: '0.1',
        amount: 0.1,
        color: '#5ebe61',
        startColor: '#E4E4E4',
        stopColor: '#8F8F8F',
    },
    {
        key: '0.5',
        amount: 0.5,
        color: '#5ebe61',
        startColor: '#F5D840',
        stopColor: '#D28A00',
    },
    {
        key: '1',
        amount: 1,
        color: '#4dbb85',
        startColor: '#F58240',
        stopColor: '#AC3400',
    },
    {
        key: '5',
        amount: 5,
        color: '#56bfab',
        startColor: '#EE5C5C',
        stopColor: '#830018',
    },
    {
        key: '20',
        amount: 20,
        color: '#4481c4',
        startColor: '#EE5CB4',
        stopColor: '#83004F',
    },
    {
        key: '100',
        amount: 100,
        color: '#4550a6',
        startColor: '#9353D4',
        stopColor: '#31179C',
    },
    {
        key: '250',
        amount: 250,
        color: '#35268a',
        startColor: '#3892C5',
        stopColor: '#174C9C',
    },
    {
        key: '1000',
        amount: 1000,
        color: '#35268a',
        startColor: '#38C571',
        stopColor: '#008358',
    },
];

export const CHIP_COLORS: ChipColors = Object.fromEntries(
    chipDefinitions.map(({ amount, ...colors }) => [amount, colors])
);

export const getChipColor = (
    value: number,
    conversionRate = 1
): { color: string; startColor: string; stopColor: string } => {
    const foundEntry = [...chipDefinitions]
        .reverse()
        .find((chip) => value / conversionRate >= chip.amount);
    return foundEntry ? foundEntry : chipDefinitions[0];
};

export type ChipSizes =
    | 'large'
    | 'x-large'
    | 'medium'
    | 'small'
    | 'x-small'
    | 'blackjack-3d';

export type ChipScaling = Record<ChipSizes, number>;

export const ChipSizeScaling: ChipScaling = {
    large: 78, // Base size
    'x-large': 88,
    medium: 50,
    small: 40,
    'x-small': 30,
    'blackjack-3d': 20,
};

// Sizes at large chip
export const FontSizes = [
    0,
    30, // 30px
    30,
    22,
    18,
    16,
];

export const getChipValue = (value: number) => {
    const v = Math.round((+value + Number.EPSILON) * 100) / 100;

    if (v >= 1000) {
        return `${Math.round(+v / 100) / 10}K`;
    }

    return v.toString();
};

export const getChipScale = (size: ChipSizes) => {
    return ChipSizeScaling[size] / 10;
};

export const getChipFontSize = (value: string, size: ChipSizes) => {
    const scale = getChipScale(size);
    const baseScale = getChipScale('large');

    const ratio = scale / baseScale;

    const length = +value < 1 ? 2 : value.length;

    if (length > 5) return (FontSizes[5] * ratio) / 10;
    if (length < 1) return (FontSizes[1] * ratio) / 10;

    return (FontSizes[length] * ratio) / 10;
};

export const getChipStyle = (value: string, size: ChipSizes) => {
    const scale = getChipScale(size);
    const fontSize = getChipFontSize(value, size);
    return {
        width: `${scale}rem`,
        height: `${scale}rem`,
        fontSize: fontSize,
    };
};
