import styled from '@emotion/styled';

import { UserMessage } from './chat';
import clsx from 'clsx';

const Container = styled.div<{ isExpanded: boolean; isMobile?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: ${({ isExpanded, isMobile }) =>
        isExpanded ? (isMobile ? '20.8rem' : '36.4') : '22.7rem'};

    .message-text {
        display: flex;
        flex-direction: row;

        justify-content: start;

        width: 21.6rem;

        /* border: 0.1rem solid blue; */

        font-size: 1.1rem;
        font-weight: lighter;

        padding-left: ${({ isExpanded }) => (isExpanded ? '0.8rem' : '1.5rem')};
    }

    &.isUsersMessage {
        flex-direction: row-reverse;

        .message-text {
            justify-content: end;
            text-align: right;
        }
    }
`;

export const ChatBubble = ({
    userMessage,
    isUsersMessage,
    isExpanded,
    isMobile,
}: {
    userMessage: UserMessage;
    isUsersMessage: boolean;
    isExpanded: boolean;
    isMobile?: boolean;
}) => {
    return (
        <Container
            className={clsx(isUsersMessage && 'isUsersMessage')}
            isExpanded={isExpanded}
            isMobile={isMobile}
        >
            <p className="message-text"> {userMessage.message} </p>
        </Container>
    );
};
