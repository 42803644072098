import React from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { useGameContext } from '@roulette/contexts';
import { RouletteStates } from '@roulette/models';

import { Chip } from '@shared/ui/components/chips';

import Chip1 from '@shared/assets/audio/1chip.mp3';
import Chip5 from '@shared/assets/audio/5chip.mp3';
import Chip10 from '@shared/assets/audio/10chip.mp3';
import Chip25 from '@shared/assets/audio/25chip.mp3';
import Chip100 from '@shared/assets/audio/100chip.mp3';
import Chip500 from '@shared/assets/audio/500chip.mp3';
import { useAudio } from '@shared/ui/contexts';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;

    .chips {
        display: flex;
        gap: 0.2rem;

        .chip {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 6.4rem;
            height: 6.4rem;
            box-sizing: border-box;

            .chip-wrapper {
                box-sizing: border-box;
                border: 1px solid transparent;
                border-radius: 50%;
            }

            &.selected {
                .chip-wrapper {
                    padding: 0.5rem;
                    border-color: var(--primary-border-color);
                }

                &.disabled {
                    padding: 0;
                    border-color: transparent;
                }
            }

            svg .hover {
                opacity: 0;
                transform: translate(7.8rem, 7.8rem);
                transition: opacity 250ms, transform 500ms ease-in-out;
            }

            &:hover {
                .hover {
                    transform: translate(-10rem, -10rem);
                    opacity: 1;
                }

                &.disabled {
                    padding: 0;
                    border-color: transparent;
                    svg .hover {
                        opacity: 0;
                    }
                }
            }
        }
    }
`;

export interface ChipSelectorProps {
    betAmounts: number[];
    activeChip: number | null;
    totalCredits: number;
    setActiveChip: (amount: number | null) => void;
    isMobile?: boolean;
}

// sufficient number of chips sounds to match number of chips
const CHIP_SOUNDS = [
    Chip1,
    Chip5,
    Chip10,
    Chip25,
    Chip25,
    Chip100,
    Chip500,
    Chip500,
];

export const ChipSelector = React.memo((props: ChipSelectorProps) => {
    const { betAmounts, activeChip, totalCredits, setActiveChip, isMobile } =
        props;

    const { gameState } = useGameContext();

    const { effectsVolume } = useAudio();

    const disabled = React.useCallback(
        (amount: number) => {
            const nextState = gameState?.currentState?.next_state;
            const minBet = gameState?.gameInfo?.minBet;

            // Check stable conditions
            if (
                nextState !== RouletteStates.WaitingForBets &&
                nextState !== RouletteStates.WaitingForPlayers
            ) {
                return true;
            }

            if (amount > totalCredits) return true;
            if (minBet !== undefined && amount < minBet) return true;

            return false;
        },
        [
            gameState?.currentState?.next_state,
            gameState?.gameInfo?.minBet,
            totalCredits,
        ]
    );

    const handleClick = React.useCallback(
        (amount: number, idx: number) => {
            return () => {
                if (disabled(amount)) return;

                if (activeChip !== amount) {
                    const sound = new Audio(CHIP_SOUNDS[idx]);
                    sound.volume = 0.4 * effectsVolume;
                    sound.play();
                }

                setActiveChip(amount);
            };
        },
        [activeChip, setActiveChip, disabled, effectsVolume]
    );

    const renderedChips = React.useMemo(() => {
        return betAmounts.map((amount: number, idx: number) => (
            <div
                key={`chip-${idx}`}
                className={clsx('chip', 'chip-selected', {
                    selected: amount === activeChip,
                    disabled: disabled(amount),
                })}
                onClick={handleClick(amount, idx)}
            >
                <div className="chip-wrapper">
                    <Chip
                        key={`chip-${idx}-${amount}`}
                        size={'medium'}
                        value={amount}
                        disabled={disabled(amount)}
                        isMobile={isMobile}
                    />
                </div>
            </div>
        ));
    }, [betAmounts, activeChip, handleClick, disabled, isMobile]);

    return (
        <Container>
            <div className="chips">{renderedChips}</div>
        </Container>
    );
});
