import {
    BaseEvent,
    BaseEvents,
    IBaseEvent,
    ReadyEvent,
    ServerInfoEvent,
} from '@shared/events';
import {
    ReactNode,
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import { useWebsocketContext } from './websocket-context';
import { log } from '@shared/utils';

interface BaseState {
    isReady: boolean;
    serverInfo: {
        lobbyUrl: string;
    };
}

export interface BaseContextProps {
    baseState: BaseState;
}

const BaseContext = createContext<BaseContextProps | undefined>(undefined);

export const initialState: BaseState = {
    isReady: false,
    serverInfo: {
        lobbyUrl: '',
    },
};

type BaseReducer = (
    state: BaseState,
    event: IBaseEvent<BaseEvents, unknown>[1]
) => BaseState;

const isServerInfo = (
    event: IBaseEvent<BaseEvents, unknown>[1]
): event is ServerInfoEvent => {
    return event.event === BaseEvents.ServerInfo;
};

const isReady = (
    event: IBaseEvent<BaseEvents, unknown>[1]
): event is ReadyEvent => {
    return event.event === BaseEvents.Ready;
};

const baseReducer: BaseReducer = (state, event): BaseState => {
    if (isReady(event)) {
        return {
            ...state,
            isReady: true,
        };
    }
    if (isServerInfo(event)) {
        return {
            ...state,
            serverInfo: { ...event.payload },
        };
    }
    return state;
};

interface BaseProviderProps {
    children: ReactNode;
}

export const BaseContextProvider = (props: BaseProviderProps) => {
    const { children } = props;
    const { socket } = useWebsocketContext();
    const [baseState, dispatch] = useReducer(baseReducer, initialState);

    useEffect(() => {
        socket.on(BaseEvents.Ready, (readyEvent) => {
            log.socket(BaseEvents.Ready);
            dispatch(readyEvent);
        });

        socket.on(BaseEvents.ServerInfo, (event: ServerInfoEvent) => {
            dispatch(event);
        });

        // socket.onAny((eventName, ...args) => {
        //     console.log(eventName, args);
        // });

        return () => {
            socket.off(BaseEvents.Ready);
            socket.off(BaseEvents.ServerInfo);
        };
    }, [socket]);

    const value = useMemo(() => {
        return {
            baseState,
        };
    }, [baseState]);

    return (
        <BaseContext.Provider value={value}>{children}</BaseContext.Provider>
    );
};

export const useBaseContext = (): BaseContextProps => {
    const context = useContext(BaseContext);
    if (!context) {
        throw new Error('useSocket must be used within a WebSocketProvider');
    }
    return context;
};
