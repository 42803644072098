import styled from '@emotion/styled';
import { useState, useEffect, useRef, type ChangeEvent } from 'react';

// CSS jankery...
// yes you can just use accent-color: red; and call it a day
// however I was unable to find a way to remove the darkening effect when hovering, without using -webkit-appearance: none; which would in-turn disable accent-color...
// so we built this component which gives us more control
// https://stackoverflow.com/questions/48880523/how-to-precisely-find-thumb-position-of-input-type-range

const RangeSlider = styled.div`
    position: relative;
    width: 100%;
    height: 2rem;
    display: flex;
    align-items: center;
    color: white;
`;

const Range = styled.input`
    -webkit-appearance: none;
    appearance: none;
    position: absolute;
    width: 100%;
    height: 0.4rem;
    margin: 0;
    background: transparent;

    &::-webkit-slider-runnable-track {
        background: var(--color-light-black);
        height: 0.4rem;
        border-radius: 1rem;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 1.2rem;
        height: 1.2rem;
        background: transparent;
        border-radius: 50%;
        margin-top: -0.4rem;
        cursor: pointer;
    }
`;

const Thumb = styled.div<{
    minValue: number;
    maxValue: number;
    value: number;
    totalInputWidth: number;
}>`
    position: absolute;
    top: 50%;
    left: ${({ minValue, maxValue, value, totalInputWidth }) => {
        const thumbWidth = 1.2;
        const remInPx = parseFloat(
            getComputedStyle(document.documentElement).fontSize
        );
        const totalInputWidthInRem = totalInputWidth / remInPx;
        return `${
            ((value - minValue) / (maxValue - minValue)) *
            (totalInputWidthInRem - thumbWidth)
        }rem`;
    }};
    transform: translateY(-50%);
    width: 1.2rem;
    height: 1.2rem;
    background: var(--color-primary);
    filter: drop-shadow(0px 0px 7.3px rgba(0, 0, 0, 0.46));
    border-radius: 50%;
    pointer-events: none;
`;

const Bar = styled.div<{ progress: number }>`
    position: absolute;
    top: 50%;
    left: 0;
    height: 0.4rem;
    background: var(--color-primary);
    border-radius: 1rem;
    transform: translateY(-50%);
    width: ${({ progress }) => `${progress}%`};
    pointer-events: none;
`;

interface InputRangeProps {
    minValue?: number;
    maxValue?: number;
    value?: number;
    onChange: (value: number) => void;
}

export const InputRange = ({
    minValue = 0,
    maxValue = 100,
    value = 50,
    onChange,
}: InputRangeProps) => {
    const [progress, setProgress] = useState<number>(value);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = Number(e.target.value);
        setProgress(newValue);
        onChange(newValue);
    };

    const [totalInputWidth, setTotalInputWidth] = useState<number>(0);
    const rangeRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (rangeRef.current) {
            setTotalInputWidth(rangeRef.current.clientWidth);
        }
    }, []);

    return (
        <RangeSlider>
            <Range
                ref={rangeRef}
                type="range"
                min={minValue}
                max={maxValue}
                value={progress}
                onChange={handleChange}
            />
            <Bar progress={progress} />
            <Thumb
                value={progress}
                minValue={minValue}
                maxValue={maxValue}
                totalInputWidth={totalInputWidth}
            />
        </RangeSlider>
    );
};
