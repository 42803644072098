import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect, useRef, useState } from 'react';

export interface TimerProps {
    endTime: number;
    duration: number | undefined;
    onFinish?: () => void;
    isMobile?: boolean;
}

const getBackground = (countdown: number) => {
    if (countdown >= 13) {
        return 'radial-gradient(112.5% 112.5% at 50% 15.28%, rgba(0, 0, 0, 0.374) 0%, rgba(20, 174, 92, 0.85) 100%), rgba(102, 102, 102, 0.19);';
    } else if (countdown >= 10) {
        return 'radial-gradient(112.5% 112.5% at 50% 15.28%, rgba(0, 0, 0, 0.374) 0%, rgba(171, 174, 20, 0.85) 100%), rgba(102, 102, 102, 0.19);';
    } else if (countdown >= 7) {
        return 'radial-gradient(112.5% 112.5% at 50% 15.28%, rgba(0, 0, 0, 0.374) 0%, rgba(174, 112, 20, 0.85) 100%), rgba(102, 102, 102, 0.19);';
    } else {
        return 'radial-gradient(112.5% 112.5% at 50% 15.28%, rgba(0, 0, 0, 0.374) 0%, rgba(242, 77, 34, 0.85) 100%), rgba(102, 102, 102, 0.19);';
    }
};

const getBorder = (countdown: number) => {
    if (countdown >= 13) {
        return '0.5px solid rgba(0, 207, 120, 0.51)';
    } else if (countdown >= 10) {
        return '0.5px solid rgba(207, 186, 0, 0.51)';
    } else if (countdown >= 7) {
        return '0.5px solid rgba(207, 124, 0, 0.51)';
    } else {
        return '0.5px solid #FF5D5D';
    }
};

const Container = styled.div<{
    endTime: number | null;
    countdown: number | null;
    duration: number | undefined;
}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    visibility: ${({ countdown }) => (countdown ? 'visible' : 'hidden')};

    width: 17.63rem;
    height: 3.12rem;

    margin-bottom: 1.7rem;

    position: relative;

    .progress-bar {
        width: 27.4rem;
        height: 2rem;
        border-radius: 0.6rem;
        position: absolute;
        overflow: hidden;
        border: 0.05rem solid #000000b2;
        background: rgba(0, 0, 0, 0.7);
    }

    .progress-bar > div {
        position: absolute;
        height: 100%;

        border-radius: 0.4rem;

        background: linear-gradient(
            270deg,
            rgba(0, 113, 38, 0) 2.62%,
            #007126 14.72%,
            #37d074 56.91%
        );

        transition: ${({ countdown, duration }) =>
            countdown && duration && countdown * 1000 === duration
                ? 'width 200ms'
                : 'width 1000ms linear'};
        transistion-delay: 3s;
        width: ${({ countdown, duration }) =>
            countdown && duration ? (countdown / (duration / 1000)) * 100 : 0}%;
    }
`;

const MobileContainer = styled.div<{ countdown: number }>`
    display: ${({ countdown }) => (countdown === 0 ? 'none' : 'flex')};
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 3.6rem;
    width: 3.6rem;

    font-size: 1.8rem;
    font-family: 'Chakra Petch', sans-serif;

    box-sizing: border-box;

    position: absolute;
    left: 4.42%;
    right: 87.14%;
    top: 5.79%;
    bottom: 90.31%;

    background: ${({ countdown }) => countdown > 0 && getBackground(countdown)};
    border: ${({ countdown }) => countdown > 0 && getBorder(countdown)};

    border-radius: 23px;

    color: white;
`;

const anim = keyframes`
    0% {
        font-size: 1rem;
        opacity: 1;
    }
    30%, 70% {
        font-size: 1.5rem;
    }
    70% {
        opacity: 1;
    }
    100% {
        font-size: 1rem;
        opacity: 0;
    }

    `;

const fadeOut = keyframes`
    from {
        opacity: 1;
        font-size: 5rem;
    } to {
        font-size: 1rem;
        opacity: 0;
    }

    `;

const Number = styled.div`
    color: #fff;
    font-size: 1.3rem;
    font-family: 'Chakra Petch';
    font-weight: 500;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-style: normal;
    line-height: normal;

    position: absolute;

    &.animated {
        font-size: 1rem;
        animation: ${anim} 1000ms;
    }

    &.number-0 {
        font-size: 2rem;
        animation: ${fadeOut} 1000ms;
        opacity: 0;
    }

    .place-your-bets {
        text-transform: uppercase;

        .countdown {
            display: inline-block;
            width: 2rem;
            text-align: start;
        }
    }
`;

export const Timer = (props: TimerProps) => {
    const { endTime, duration, isMobile } = props;

    const timerRef = useRef<ReturnType<typeof setTimeout>>();
    const [remaining, setRemaining] = useState(
        Math.round(endTime - Date.now())
    );

    useEffect(() => {
        const updateTimer = () => {
            timerRef.current = setTimeout(() => {
                const re = endTime - Date.now();
                setRemaining(Math.max(0, re));

                if (re >= 0) {
                    updateTimer();
                }
            }, 100);
        };

        if (endTime) {
            updateTimer();
        }

        return () => {
            clearTimeout(timerRef.current);
        };
    }, [endTime]);

    const countdown = Math.ceil(remaining / 1000);

    const className = `number-${countdown} ${
        countdown > 0 && countdown < 4 ? 'animated' : ''
    }`;

    return isMobile ? (
        <MobileContainer countdown={countdown}>
            {countdown > 0 && countdown}
        </MobileContainer>
    ) : countdown > 0 ? (
        <Container endTime={endTime} countdown={countdown} duration={duration}>
            <div className="progress-bar">
                <div></div>
            </div>
            <Number className={className} key={countdown}>
                {countdown <= 3 && countdown >= 1 ? (
                    <span>{countdown}</span>
                ) : (
                    <span className="place-your-bets">
                        Place Your Bets -{' '}
                        <span className="countdown"> {countdown}</span>
                    </span>
                )}
            </Number>
        </Container>
    ) : (
        ''
    );
};
