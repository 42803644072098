// External
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

// Internal
import { AspectRatioWrapper } from '@shared/ui/hooks';
import { VideoStreamData } from '@shared/websocket';

// Local
import { BaseGameLayoutMobile } from './base-game-layout-mobile';
import { BaseGameLayoutDesktop } from './base-game-layout-desktop';
import { VideoStream } from '@shared/ui/components/generic';
import { AnimoUserData } from '@shared/aggregator/models';
import { AudioPlayer } from '@shared/ui/components';
import { useAudio } from '@shared/ui/contexts';

const BackgroundImage = styled.div<{
    backgroundImage: string;
    position: string;
}>`
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: ${(props) => props.position || 'center center'};

    z-index: -2;
`;

const Video = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
`;

export interface BaseGameLayoutProps {
    isVertical: boolean;
    backgroundImage: string;
    gameName: string;
    minBet: number;
    maxBet: number;
    userId: string | undefined;
    baseBetTotal: number;
    sideBetTotals: number;
    user: Pick<AnimoUserData, 'balance' | 'currency'>;
    sound: string;
    videoStream?: VideoStreamData;
}

export const BaseGameLayout = ({
    isVertical,
    backgroundImage,
    gameName,
    minBet,
    maxBet,
    userId,
    baseBetTotal,
    sideBetTotals,
    user,
    sound,
    videoStream,
    children,
}: PropsWithChildren<BaseGameLayoutProps>) => {
    // Handle Audio Volume globally
    const { ambientVolume, isAmbientMuted, isDealerMuted, dealerVolume } =
        useAudio();

    const layoutProps = {
        gameName: gameName,
        minBet: minBet,
        maxBet: maxBet,
        userId: userId ?? '',
        baseBetTotal: baseBetTotal,
        sideBetTotals: sideBetTotals,
        user: user,
    };

    return (
        <AspectRatioWrapper isVertical={isVertical}>
            <BackgroundImage
                backgroundImage={backgroundImage}
                position={isVertical ? 'center 10.5rem;' : 'center center'}
            />

            {isVertical ? (
                <BaseGameLayoutMobile {...layoutProps}>
                    {children}
                </BaseGameLayoutMobile>
            ) : (
                <BaseGameLayoutDesktop {...layoutProps}>
                    {children}
                </BaseGameLayoutDesktop>
            )}

            <AudioPlayer
                src={sound}
                muted={isAmbientMuted}
                loop={true}
                autoPlay
                volume={ambientVolume}
            />
            <Video>
                {videoStream && videoStream.account && videoStream.name && (
                    <VideoStream
                        name={videoStream?.name}
                        accountId={videoStream?.account || ''}
                        type={videoStream?.type || 'millicast'}
                        muted={isDealerMuted}
                        volume={dealerVolume}
                    />
                )}
            </Video>
        </AspectRatioWrapper>
    );
};
