// External
import {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react';

// Internal
import {
    BallEvent,
    createPlayerBetEvent,
    Events,
    GameInfoData,
    GameInfoEvent,
    GameStateEvent,
    PlayerBaseBet,
    PlayerPayout,
    ResultedHistoryEvent,
    RouletteStates,
    RoundEndEvent,
    RoundStartEvent,
    UserBet,
} from '@roulette/models';
import { AnimoUserData } from '@shared/aggregator/models';
import { BaseEvents, IBaseEvent, UserEvent } from '@shared/events';
import { useWebsocketContext } from '@shared/ui/contexts';
import { log } from '@shared/utils';

// Local
import { RoundData } from './types/round-data';

export interface GameContextProps {
    gameState: GameState;
    racetrackAdjacentCounter: number;
    handlePlayerBets: (bets: UserBet[]) => void;
    handleRacetrackAdjacentCounter: (type: string) => void;
}

interface GameProviderProps {
    children: ReactNode;
}

const GameContext = createContext<GameContextProps | undefined>(undefined);

interface GameState {
    user: AnimoUserData | null;
    gameInfo: GameInfoData | null;
    roundInfo: RoundData | null;
    currentState: GameStateEvent['payload'];
    betStats: {
        type: PlayerBaseBet;
        totalBetAmount: number;
    };
    ballPosition: number | null;
    payouts: PlayerPayout[];
    resultHistory: number[];
    endTime: number;
}

const initialState: GameState = {
    user: null,
    gameInfo: null,
    roundInfo: null,
    currentState: {
        next_state: RouletteStates.WaitingForBets,
        previous_state: RouletteStates.WaitingForBets,
    },
    betStats: {
        type: PlayerBaseBet.NULL,
        totalBetAmount: 0,
    },
    ballPosition: null,
    payouts: [],
    resultHistory: [],
    endTime: 0,
};

type GameReducer = (
    state: GameState,
    event: IBaseEvent<Events | BaseEvents | unknown>[1]
) => GameState;

const gameReducer: GameReducer = (state, event): GameState => {
    switch (event.event) {
        case BaseEvents.User: {
            const eventData = event as UserEvent;
            return {
                ...state,
                user: eventData.payload.user,
            };
        }
        case Events.GameInfo: {
            const eventData = event as GameInfoEvent;
            return {
                ...state,
                gameInfo: eventData.payload,
            };
        }
        case Events.RoundStart: {
            const eventData = event as RoundStartEvent;
            return {
                ...state,
                roundInfo: eventData.payload,
                ballPosition: null,
                payouts: [],
                endTime: Date.now() + (eventData.payload?.remainingTime || 0),
            };
        }
        case Events.GameState: {
            const eventData = event as GameStateEvent;
            return {
                ...state,
                currentState: eventData.payload,
            };
        }
        case Events.RoundEnd: {
            const eventData = event as RoundEndEvent;
            return {
                ...initialState,
                user: state.user,
                gameInfo: state.gameInfo,
                roundInfo: eventData.payload,
                currentState: state.currentState,
                ballPosition: null,
                resultHistory: [...state.resultHistory],
            };
        }

        case Events.BallPosition: {
            const eventData = event as BallEvent;
            return {
                ...state,
                gameInfo: state.gameInfo,
                ballPosition: eventData.payload.value,
            };
        }

        case Events.ResultHistory: {
            const eventData = event as ResultedHistoryEvent;
            return {
                ...state,
                gameInfo: state.gameInfo,
                resultHistory: eventData.payload.resultedNumbers,
            };
        }

        default:
            return state;
    }
};

export const GameContextProvider = (props: GameProviderProps) => {
    const { children } = props;
    const { socket } = useWebsocketContext();

    const [gameState, dispatch] = useReducer(gameReducer, initialState);

    const [muted, setMuted] = useState(false);

    const [racetrackAdjacentCounter, setRacetrackAdjacentCounter] = useState(1);

    const handlePlayerBets = useCallback(
        async (bet: UserBet[]) => {
            const event = createPlayerBetEvent(bet);
            log.socket('PlayerBets', event);
            /**
             * TODO: upgrade to emitWithResponse
             * Which will need the shared WebSocketContext to become more generic
             */
            socket.emit(...event);
        },
        [socket]
    );

    const handleRacetrackAdjacentCounter = useCallback(
        (type: string) => {
            switch (type) {
                case 'increment':
                    if (racetrackAdjacentCounter < 9)
                        setRacetrackAdjacentCounter(
                            racetrackAdjacentCounter + 1
                        );
                    break;
                case 'decrement':
                    setRacetrackAdjacentCounter(
                        racetrackAdjacentCounter > 0
                            ? racetrackAdjacentCounter - 1
                            : racetrackAdjacentCounter
                    );
            }
        },
        [racetrackAdjacentCounter]
    );

    useEffect(() => {
        socket.on(Events.GameInfo, (gameInfo) => {
            log.socket(Events.GameInfo);
            dispatch(gameInfo);
        });

        socket.on(Events.RoundStart, (roundStartEvent) => {
            log.socket(Events.RoundStart);
            dispatch(roundStartEvent);
        });

        socket.on(Events.GameState, (gameState) => {
            log.socket(Events.GameState);
            dispatch(gameState);
        });

        socket.on(BaseEvents.User, (userEvent: UserEvent) => {
            log.socket(BaseEvents.User);
            dispatch(userEvent);
        });

        socket.on(Events.RoundEnd, (roundEnd) => {
            log.socket(Events.RoundEnd, roundEnd);
            dispatch(roundEnd);
        });

        socket.on(Events.BallPosition, (ballPosition) => {
            log.socket(Events.BallPosition, ballPosition);
            setRacetrackAdjacentCounter(1);
            dispatch(ballPosition);
        });

        socket.on(Events.ResultHistory, (resultedNumbers) => {
            log.socket(Events.ResultHistory, resultedNumbers);
            dispatch(resultedNumbers);
        });

        return () => {
            socket.off(Events.GameInfo);
            socket.off(Events.RoundStart);
            socket.off(Events.GameState);
            socket.off(BaseEvents.User);
            socket.off(Events.RoundEnd);
            socket.off(Events.BallPosition);
            socket.off(Events.ResultHistory);
        };
    }, [socket]);

    const toggleMute = useCallback(() => {
        setMuted((m) => !m);
    }, []);

    const value = useMemo(() => {
        return {
            gameState,

            racetrackAdjacentCounter,

            handlePlayerBets,
            handleRacetrackAdjacentCounter,
        };
    }, [
        gameState,
        racetrackAdjacentCounter,
        handlePlayerBets,
        handleRacetrackAdjacentCounter,
    ]);

    return (
        <GameContext.Provider value={value}>{children}</GameContext.Provider>
    );
};

export const useGameContext = (): GameContextProps => {
    const context = useContext(GameContext);
    if (!context) {
        throw new Error('useSocket must be used within a WebSocketProvider');
    }
    return context;
};
