import { getChipColor } from '@shared/models';
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useState,
} from 'react';

type ChipContextProviderProps = PropsWithChildren;

interface ChipContextValues {
    conversionRate: number;
    setConversionRate: (value: number) => void;
    getChipColor: (value: number) => {
        color: string;
        startColor: string;
        stopColor: string;
    };
}

const ChipContext = createContext<ChipContextValues>({
    conversionRate: 1,
    setConversionRate: () => {
        /* */
    },
    getChipColor: getChipColor,
});

export const ChipContextProvider = (props: ChipContextProviderProps) => {
    const { children } = props;
    const [conversionRate, setConversionRate] = useState(1);

    const getChipColorFn = useCallback(
        (amount: number) => {
            return getChipColor(amount, conversionRate);
        },
        [conversionRate]
    );

    return (
        <ChipContext.Provider
            value={{
                conversionRate,
                setConversionRate,
                getChipColor: getChipColorFn,
            }}
        >
            {children}
        </ChipContext.Provider>
    );
};

export const useChipContext = () => {
    const context = useContext(ChipContext);
    return context;
};
