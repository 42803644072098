import styled from '@emotion/styled';
import { FormEvent, useCallback, useMemo, useState } from 'react';

import { useAspectRatioHandler } from '@shared/ui/hooks';
import { CURRENCY_MAP } from '@shared/utils';

interface DemoCasinoProps {
    app: string;
}

const HEADER_HEIGHT = 60;

const MIN = `
            min(calc(100vw - ${HEADER_HEIGHT * 2}px),
            calc(177vh - ${HEADER_HEIGHT * 4}px))`;

const Container = styled.div`
    box-sizing: border-box;

    background-color: #09090a;

    height: 100vh;

    overflow: hidden;

    iframe {
        box-sizing: border-box;
        margin: 40px;
        width: ${MIN};
        height: calc((1080 / 1920) * ${MIN});
    }

    &.vertical {
        iframe {
            margin: 0px;
            box-sizing: border-box;
            width: 100vw;
            height: calc(100vh - ${HEADER_HEIGHT}px);
        }
    }

    font-size: 14px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100vw;
    height: ${HEADER_HEIGHT}px;
    background-color: #000;
    border-bottom: 1px solid #242424;

    padding: 0px 40px;

    font-family: 'Chakra Petch';

    .currency-symbol {
        color: #949494;
    }

    .random-name {
        background-color: #242424;
        border: 1px solid #343434;

        border-radius: 6px;

        color: #949494;
        font-weight: bold;
    }

    form {
        display: flex;
        align-items: center;
        gap: 8px;
        box-sizing: border-box;
    }

    input,
    select {
        width: 100px;
        padding: 2px 6px 2px;
        border: 1px solid #343434;

        border-radius: 8px;
        background: #141414;

        color: #fff;
    }

    select {
        padding: 0px 6px;
    }
`;

const CURRENCIES = Object.entries(CURRENCY_MAP).map(([key, value]) => {
    return { value: key, label: `${key} ${value.symbol}` };
});

const createSession = (data: Record<string, unknown>) => {
    return btoa(JSON.stringify(data));
};

const getSessionUrl = (username: string, credits: number, currency: string) => {
    const token = createSession({
        username,
        credits,
        type: 'user',
        currency: currency,
    });
    return {
        url: `${window.location.origin}?session=${token}&t=${Date.now()}`,
        token,
    };
};

const NAMES = [
    'fish',
    'cat',
    'money',
    'boy',
    'harry',
    'lover',
    'killer',
    'angry',
    'happy',
    'winner',
    'dread',
    'ugly',
    'mother',
    'daddy',
    'welsh',
    'ozzie',
    'lime',
    'lemon',
    'watermelon',
    'pepper',
    'onion',
    'sexy',
    'monkey',
    'sammy',
    'clamy',
    'jawa',
    'wookie',
    'storm',
    'flower',
    'puppy',
    'desire',
    'loser',
    'numpty',
    'queen',
    'king',
    'potato',
    'pizza',
    'pasta',
    'tomato',
];

const randomUserName = () => {
    const first = NAMES[Math.floor(Math.random() * NAMES.length)];
    const last = NAMES[Math.floor(Math.random() * NAMES.length)];
    const digits = Math.floor(Math.random() * 99);

    return `${first}${last}${digits}`;
};

export const DemoCasino = (props: DemoCasinoProps) => {
    const { app } = props;

    const { isVertical } = useAspectRatioHandler();

    const [form, setForm] = useState<{
        username: string;
        credits: number;
        currency: string;
    }>({
        username:
            localStorage.getItem(`${app}_demo_casino_username`) ||
            randomUserName(),
        credits: 10000,
        currency: 'USD',
    });

    const [gameUrl, setGameUrl] = useState(
        localStorage.getItem(`${app}_demo_casino_url`)
    );

    const updateForm = (field: 'username' | 'credits' | 'currency') => {
        return (ev: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
            ev.preventDefault();
            const value = ev.currentTarget.value;
            setForm((prev) => {
                return {
                    ...prev,
                    [field]: field === 'credits' ? +value : value,
                };
            });
        };
    };

    const updateUser = (ev: FormEvent) => {
        ev.preventDefault();
        const data = getSessionUrl(form.username, form.credits, form.currency);
        localStorage.setItem(`${app}_demo_casino_url`, data.url);
        localStorage.setItem(`${app}_demo_casino_session`, data.token);
        localStorage.setItem(`${app}_demo_casino_username`, form.username);
        setGameUrl(data.url);
    };

    const symbol = useMemo(() => {
        return CURRENCY_MAP[form.currency as keyof typeof CURRENCY_MAP].symbol;
    }, [form.currency]);

    const randomName = () => {
        setForm((p) => ({ ...p, username: randomUserName() }));
    };

    return (
        <Container className={isVertical ? 'vertical' : ''}>
            <Header>
                <form onSubmit={updateUser}>
                    <input
                        type="text"
                        placeholder="username"
                        value={form.username}
                        onChange={updateForm('username')}
                    />
                    <button className={'random-name'} onClick={randomName}>
                        ?
                    </button>
                    <div className="currency-symbol">{symbol}</div>
                    <input
                        type="text"
                        placeholder="credits"
                        value={form.credits}
                        onChange={updateForm('credits')}
                    />
                    <select
                        value={form.currency}
                        onChange={updateForm('currency')}
                    >
                        {CURRENCIES.map((cur) => (
                            <option
                                key={cur.value}
                                value={cur.value}
                                label={cur.label}
                            />
                        ))}
                    </select>
                    <input type="submit" value="Update" />
                </form>
            </Header>
            {gameUrl && <iframe src={gameUrl} title="Animo Game" />}
        </Container>
    );
};
