export const calculateBoxesFitCount = () => {
    const screenWidth = window.innerWidth;

    const screenWidthInRem = screenWidth / 14;

    const boxWidthInRem = 2.5;

    const boxesFit = Math.floor(screenWidthInRem / boxWidthInRem);

    return boxesFit;
};
