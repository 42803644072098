import styled from '@emotion/styled';
import { HTMLAttributes } from 'react';

const ButtonContainer = styled.button<{ noValue: boolean }>`
    display: flex;
    align-items: ${({ noValue }) => (noValue ? 'center' : 'start')};
    flex-direction: ${({ noValue }) => (noValue ? 'row' : 'column')};
    justify-content: center;
    min-width: 13rem;
    padding: ${({ noValue }) =>
        noValue
            ? '1.7rem 1.2rem 1.7rem 1.2rem'
            : '0.5rem 1.2rem 0.5rem 0.2rem'};

    background: radial-gradient(
            112.5% 112.5% at 50% 15.28%,
            rgba(12, 12, 12, 0.85) 24.5%,
            rgba(0, 0, 0, 0.374) 100%
        ),
        rgba(102, 102, 102, 0.19);

    border: 0.05rem solid rgba(var(--color-primary-opacity), 0.5);
    border-radius: 1rem;

    text-transform: uppercase;
    font-family: 'Chakra Petch', sans-serif;
    cursor: pointer;
`;

const ButtonSpan = styled.span<{
    primary: string;
    noValue: boolean;
    isVertical: boolean;
}>`
    color: ${({ primary }) => (primary ? primary : 'var(--color-white-100)')};
    font-size: ${({ noValue, isVertical }) =>
        noValue
            ? isVertical
                ? '1.6rem'
                : '2rem'
            : isVertical
            ? '1.1rem'
            : '1.2rem'};
    letter-spacing: ${({ noValue, isVertical }) =>
        noValue ? (isVertical ? '' : '') : isVertical ? '0.075rem' : ''};
    font-weight: 400;
    line-height: ${({ noValue }) => (noValue ? '1rem' : '1rem')};
    margin-left: ${({ noValue, isVertical }) =>
        noValue ? '0rem' : !isVertical ? '1.4rem' : '0.5rem'};
    margin-top: ${({ noValue }) => (noValue ? '0.5rem' : '0.2rem')};
    margin-bottom: ${({ noValue }) => (noValue ? '0.2rem' : '0.2rem')};
    transform: ${({ noValue }) => (noValue ? '0.2rem' : 'translateY(0.2rem)')};

    &.value,
    .label {
        font-size: ${({ isVertical }) => (isVertical ? '1.6rem' : '1.9rem')};
        font-weight: 600;
        line-height: 2.4rem;
        margin-left: ${({ isVertical }) => (isVertical ? '0.5rem' : '1.4rem')};
        margin-top: -0.1rem;

        color: var(--color-primary);
        white-space: normal; /* Allow text to wrap */
    }
`;

interface Button2Props extends HTMLAttributes<HTMLButtonElement> {
    title: string;
    value?: string;
    primary?: string;
    secondary?: string;
    isVertical: boolean;
}

export const Button2 = ({
    title,
    value,
    primary,
    secondary,
    isVertical,
    onClick,
}: Button2Props) => {
    const noValue = !value;

    return (
        <ButtonContainer noValue={noValue} onClick={onClick}>
            <ButtonSpan
                className="title"
                primary={primary ? primary : secondary ?? ''}
                noValue={noValue}
                isVertical={isVertical}
            >
                {title}
            </ButtonSpan>

            {value && (
                <ButtonSpan
                    className="value"
                    primary={primary ? primary : secondary ?? ''}
                    noValue={false}
                    isVertical={isVertical}
                >
                    {value}
                </ButtonSpan>
            )}
        </ButtonContainer>
    );
};
