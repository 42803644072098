import {
    RouletteState,
    PlayerBaseBet,
    EvenOddOutcome,
    LowHighOutcome,
    TwelveRange,
    RedBlackOutcome,
    RoulettePlayer,
} from '../types';

import { BoardColor, boardNumbers } from '../../constants';
import { getWinRatio } from './payout.util';

interface Bets {
    type: PlayerBaseBet;
    amount: number;
    betValues: number | number[] | null;
}

export const calculateWinner = (
    bets: Bets[],
    wheelNumber?: number
): PlayerBaseBet => {
    const win = bets.filter(
        (bet) =>
            Array.isArray(bet.betValues) && bet.betValues[0] === wheelNumber
    );
    if (win.length) {
        return win[0].type;
    }
    return PlayerBaseBet.NO_WIN;
};

export const calculateStreetDoubleStreet = (
    bets: Bets[],
    wheelNumber: number
): PlayerBaseBet => {
    const streetBet = bets
        .filter((bet) => bet.type === PlayerBaseBet.STREET && bet.betValues)
        .flatMap((bet) => bet.betValues);

    const doubleStreet = bets
        .filter(
            (bet) => bet.type === PlayerBaseBet.DOUBLE_STREET && bet.betValues
        )
        .flatMap((bet) => bet.betValues);

    if (streetBet.includes(wheelNumber)) {
        return PlayerBaseBet.STREET;
    }

    if (doubleStreet.includes(wheelNumber)) {
        return PlayerBaseBet.DOUBLE_STREET;
    }

    return PlayerBaseBet.NO_WIN;
};

export const calculateSplitBet = (
    playerNumber: (number | null)[],
    wheelNumber: number
): PlayerBaseBet => {
    if (!wheelNumber || !playerNumber.length) {
        return PlayerBaseBet.NULL;
    }

    const values = playerNumber
        .filter((value) => Array.isArray(value))
        .flatMap((value) => value!);
    if ([...values].includes(wheelNumber)) {
        return PlayerBaseBet.SPLIT;
    }

    return PlayerBaseBet.NO_WIN;
};

export const calculateCornerBet = (
    playerNumber: (number | null)[],
    wheelNumber: number
): PlayerBaseBet => {
    if (!wheelNumber || !playerNumber.length) {
        return PlayerBaseBet.NULL;
    }

    const values = playerNumber
        .filter((value) => Array.isArray(value))
        .flatMap((value) => value!);

    if ([...values].includes(wheelNumber)) {
        return PlayerBaseBet.CORNER;
    }

    return PlayerBaseBet.NO_WIN;
};

// Calculate if the number is even or odd and determine the winner
export const calculateEvenOddOutcome = (
    wheelNumber?: number
): PlayerBaseBet | EvenOddOutcome => {
    return wheelNumber && wheelNumber % 2 === 0
        ? EvenOddOutcome.EVEN
        : EvenOddOutcome.ODD;
};

export const calculateRacetrackBaseBetOutcome = (
    playerNumber?: (number | null)[],
    wheelNumber?: number
): PlayerBaseBet => {
    return playerNumber?.includes(wheelNumber as number)
        ? PlayerBaseBet.RACETRACK_STRAIGHT_UP
        : PlayerBaseBet.NO_WIN;
};

//calculate low/high outcome
export const calculateLowHighOutcome = (
    wheelNumber?: number
): PlayerBaseBet | LowHighOutcome => {
    return wheelNumber && wheelNumber <= 18
        ? LowHighOutcome.LOW
        : LowHighOutcome.HIGH;
};

export const calculateRedBlackOutcome = (wheelNumber?: number) => {
    const res = boardNumbers
        .filter((numbers) => numbers.value === wheelNumber)
        .flatMap((nunmbers) => nunmbers.color);
    return res[0] === 'red' ? RedBlackOutcome.RED : RedBlackOutcome.BLACK;
};

export const calculateTwelveRangeOutcome = (wheelNumber?: number) => {
    return wheelNumber && wheelNumber <= 12
        ? TwelveRange.FIRST_TWELVE
        : wheelNumber && wheelNumber > 12 && wheelNumber <= 24
        ? TwelveRange.SECOND_TWELVE
        : wheelNumber && wheelNumber > 24 && wheelNumber <= 36
        ? TwelveRange.THIRD_TWELVE
        : PlayerBaseBet.NO_WIN;
};

export const calculateColumnBetOutcome = (
    wheelNumber: number
): PlayerBaseBet => {
    if (!wheelNumber) {
        return PlayerBaseBet.NULL;
    }

    if (wheelNumber % 3 === 0) {
        return PlayerBaseBet.THIRD_COLUMN;
    }

    if (wheelNumber % 3 === 2) {
        return PlayerBaseBet.SECOND_COLUMN;
    }

    return PlayerBaseBet.FIRST_COLUMN;
};

export const calculateViosinsBetOutcome = (
    bets: Bets[],
    wheelNumber: number
): PlayerBaseBet => {
    const voisinsSplit = bets
        .filter(
            (bet) =>
                bet.type === PlayerBaseBet.VOISINS_BET_SPLITS && bet.betValues
        )
        .flatMap((bet) => bet.betValues);

    const voisinsCorner = bets
        .filter(
            (bet) =>
                bet.type === PlayerBaseBet.VOISINS_BET_CORNER && bet.betValues
        )
        .flatMap((bet) => bet.betValues);

    const voisinsTrio = bets
        .filter(
            (bet) =>
                bet.type === PlayerBaseBet.VOISINS_BET_TRIO && bet.betValues
        )
        .flatMap((bet) => bet.betValues);

    if (voisinsSplit.includes(wheelNumber)) {
        return PlayerBaseBet.VOISINS_BET_SPLITS;
    }

    if (voisinsCorner.includes(wheelNumber)) {
        return PlayerBaseBet.VOISINS_BET_CORNER;
    }

    if (voisinsTrio.includes(wheelNumber)) {
        return PlayerBaseBet.VOISINS_BET_TRIO;
    }

    return PlayerBaseBet.NO_WIN;
};

export const calculateJeuZeroBetOutcome = (
    bets: Bets[],
    wheelNumber: number
): PlayerBaseBet => {
    const jeuZeroSplit = bets
        .filter(
            (bet) => bet.type === PlayerBaseBet.JEU_ZERO_SPLIT && bet.betValues
        )
        .flatMap((bet) => bet.betValues);

    const jeuZeroStraight = bets
        .filter(
            (bet) =>
                bet.type === PlayerBaseBet.JEU_ZERO_STRAIGHT && bet.betValues
        )
        .flatMap((bet) => bet.betValues);

    if (jeuZeroSplit.includes(wheelNumber)) {
        return PlayerBaseBet.JEU_ZERO_SPLIT;
    }

    if (jeuZeroStraight.includes(wheelNumber)) {
        return PlayerBaseBet.JEU_ZERO_STRAIGHT;
    }

    return PlayerBaseBet.NO_WIN;
};

export const calculateOrphelinsBetOutcome = (
    bets: Bets[],
    wheelNumber: number
): PlayerBaseBet => {
    const orphelinsSplits = bets
        .filter(
            (bet) =>
                bet.type === PlayerBaseBet.ORPHELINS_BET_SPLITS && bet.betValues
        )
        .flatMap((bet) => bet.betValues);
    const orphelinsStraight = bets
        .filter(
            (bet) =>
                bet.type === PlayerBaseBet.ORPHELINS_BET_STRAIGHT &&
                bet.betValues
        )
        .flatMap((bet) => bet.betValues);

    if (!wheelNumber) {
        return PlayerBaseBet.NULL;
    }

    if (orphelinsSplits.includes(wheelNumber)) {
        return PlayerBaseBet.ORPHELINS_BET_SPLITS;
    }

    if (orphelinsStraight.includes(wheelNumber)) {
        return PlayerBaseBet.ORPHELINS_BET_STRAIGHT;
    }

    return PlayerBaseBet.NO_WIN;
};

export const calculateTiersBetOutcome = (
    bets: Bets[],
    wheelNumber: number
): PlayerBaseBet => {
    const tiersSplit = bets
        .filter(
            (bet) => bet.type === PlayerBaseBet.TIERS_BET_SPLIT && bet.betValues
        )
        .flatMap((bet) => bet.betValues);

    if (!wheelNumber) {
        return PlayerBaseBet.NULL;
    }

    if (tiersSplit.includes(wheelNumber)) {
        return PlayerBaseBet.TIERS_BET_SPLIT;
    }

    return PlayerBaseBet.NO_WIN;
};

export const isNumberColor = (value: number, color: BoardColor) => {
    const boardNumber = boardNumbers.find((b) => b.value === value);

    if (!boardNumber) return false;

    return boardNumber && boardNumber.color === color;
};

export const isNumberRed = (value: number) =>
    isNumberColor(value, BoardColor.RED);

export const isNumberBlack = (value: number) =>
    isNumberColor(value, BoardColor.BLACK);

export const isNumberGreen = (value: number) =>
    isNumberColor(value, BoardColor.GREEN);

export const isNumberLow = (value: number) => {
    if (value < 1 || value > 36) return false;
    return value > 0 && value < 19;
};

export const isNumberHigh = (value: number) => {
    if (value < 1 || value > 36) return false;
    return value > 18;
};

export const isNumberOdd = (value: number) => {
    if (value < 1 || value > 36) return false;
    return value % 2;
};

export const isNumberEven = (value: number) => {
    if (value < 1 || value > 36) return false;
    return !(value % 2);
};

export const isNumberInColumn = (value: number, column: number) => {
    if (value < 1 || value > 36) return false;
    return value % 3 === (column === 3 ? 0 : column);
};

export const isNumberInTwelve = (value: number, section: number) => {
    if (value < 1 || value > 36) return false;
    return value > (section - 1) * 12 && value < section * 12 + 1;
};

export const didBetWin = (
    wheelNumber: number,
    betType: PlayerBaseBet,
    value?: number[]
) => {
    switch (betType) {
        case PlayerBaseBet.ZERO:
        case PlayerBaseBet.JEU_ZERO_STRAIGHT:
            return wheelNumber === 0;

        case PlayerBaseBet.STRAIGHT_UP:
        case PlayerBaseBet.ORPHELINS_BET_STRAIGHT:
        case PlayerBaseBet.RACETRACK_STRAIGHT_UP:
            return Array.isArray(value) && value.includes(wheelNumber);

        case PlayerBaseBet.STREET:
        case PlayerBaseBet.DOUBLE_STREET:
        case PlayerBaseBet.CORNER:
            return Array.isArray(value) && value.includes(wheelNumber);

        case PlayerBaseBet.RED:
            return isNumberRed(wheelNumber);
        case PlayerBaseBet.BLACK:
            return isNumberBlack(wheelNumber);

        case PlayerBaseBet.LOW:
            return isNumberLow(wheelNumber);
        case PlayerBaseBet.HIGH:
            return isNumberHigh(wheelNumber);

        case PlayerBaseBet.ODD:
            return isNumberOdd(wheelNumber);
        case PlayerBaseBet.EVEN:
            return isNumberEven(wheelNumber);

        case PlayerBaseBet.FIRST_COLUMN:
            return isNumberInColumn(wheelNumber, 1);
        case PlayerBaseBet.SECOND_COLUMN:
            return isNumberInColumn(wheelNumber, 2);
        case PlayerBaseBet.THIRD_COLUMN:
            return isNumberInColumn(wheelNumber, 3);

        case PlayerBaseBet.FIRST_TWELVE:
            return isNumberInTwelve(wheelNumber, 1);
        case PlayerBaseBet.SECOND_TWELVE:
            return isNumberInTwelve(wheelNumber, 2);
        case PlayerBaseBet.THIRD_TWELVE:
            return isNumberInTwelve(wheelNumber, 3);
    }
};
