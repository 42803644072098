import React, { ReactNode, useEffect, useState } from 'react';
import {
  UserBet,
  orphelinsBetTypes,
  tiersBetTypes,
  voisinsBetTypes,
} from '@roulette/models';

interface ChipPosition {
  x: number;
  y: number;
  value: number;
}

interface ChipAnimationGroupProps {
  children: (chipPositions: ChipPosition[]) => ReactNode;
  bets: UserBet[] | number;
  betType: string;
  radius: number;
  x: number;
  y: number;
  offsetX: number;
  offsetY: number;
}

export const ChipAnimationGroup: React.FC<ChipAnimationGroupProps> = ({
  bets,
  betType,
  radius,
  x,
  y,
  offsetX,
  offsetY,
  children,
}) => {
  const [chipPositions, setChipPositions] = useState<ChipPosition[]>([]);

  useEffect(() => {
    const calculateChips = () => {
      const betTypes =
        betType === 'voisins'
          ? voisinsBetTypes
          : betType === 'orphelins'
          ? orphelinsBetTypes
          : tiersBetTypes;

      if (Array.isArray(bets)) {
        const newChipPositions = bets.map((bet, i) => {
          const angle = (i / betTypes.length) * (2 * Math.PI);
          const chipX = Math.cos(angle) * radius + offsetX;
          const chipY = Math.sin(angle) * radius + offsetY;

          return { x: chipX, y: chipY, value: bet.amount };
        });
        setChipPositions(newChipPositions);
      } else {
        const angle = 0;
        const chipX = Math.cos(angle) * radius + offsetX;
        const chipY = Math.sin(angle) * radius + offsetY;

        setChipPositions([{ x: chipX, y: chipY, value: bets }]);
      }
    };

    calculateChips();

    const totalAmount = Array.isArray(bets)
      ? bets.reduce((acc, curr) => acc + curr.amount, 0)
      : bets;

    const timer = setTimeout(() => {
      setChipPositions((chips) =>
        chips.map((chip) => ({
          ...chip,
          x: x,
          y: y,
          value: totalAmount,
        }))
      );
    }, 200);

    return () => clearTimeout(timer);
  }, [bets, betType, radius, offsetX, offsetY, x, y]);

  return <div className="chips-container">{children(chipPositions)}</div>;
};
