import { PlayerBaseBet, UserBet } from '@roulette/models';

export const calculateTotalAmount = (bets: UserBet[], betType: string) => {
    return bets.reduce((acc, curr) => {
        if (
            (betType === 'voisins' &&
                (curr.type === PlayerBaseBet.VOISINS_BET_SPLITS ||
                    curr.type === PlayerBaseBet.VOISINS_BET_CORNER ||
                    curr.type === PlayerBaseBet.VOISINS_BET_TRIO)) ||
            (betType === 'orphelins' &&
                (curr.type === PlayerBaseBet.ORPHELINS_BET_SPLITS ||
                    curr.type === PlayerBaseBet.ORPHELINS_BET_STRAIGHT)) ||
            (betType === 'tiers' &&
                curr.type === PlayerBaseBet.TIERS_BET_SPLIT) ||
            (betType === 'jeuZero' &&
                curr.type === PlayerBaseBet.JEU_ZERO_SPLIT) ||
            (betType === 'jeuZero' &&
                curr.type === PlayerBaseBet.JEU_ZERO_STRAIGHT)
        ) {
            return acc + curr.amount;
        }
        return acc;
    }, 0);
};
