import styled from '@emotion/styled';

import { UserMessageObject } from './chat';
import { ChatBubble } from './chat-bubble';
import clsx from 'clsx';
import { useTime } from '@shared/ui/hooks';

const Container = styled.div<{ isExpanded: boolean; isMobile?: boolean }>`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;

    width: ${({ isExpanded, isMobile }) =>
        isExpanded ? (isMobile ? '20.8rem' : '36.4rem') : '20.8rem'};

    margin-bottom: 1rem;
    padding-right: 1rem;

    border-radius: 0.9rem;

    .chat-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        width: ${({ isExpanded, isMobile }) =>
            isExpanded ? (isMobile ? '20.8rem' : '36.4rem') : '22.7rem'};

        color: var(--chat-bubble-info);

        font-size: 1.2rem;

        padding-left: 0.8rem;
    }

    .messages {
        display: flex;
        justify-content: start;
        flex-direction: column-reverse;
        width: ${({ isExpanded, isMobile }) =>
            isExpanded ? (isMobile ? '20.8rem' : '36.4rem') : '22.7rem'};

        padding-bottom: 1rem;
        overflow-y: auto;
    }

    &.notUsersMessage {
        background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.44) 0%,
            rgba(0, 0, 0, 0) 68.06%
        );
        .chat-info {
            .name {
                padding-left: ${({ isExpanded }) =>
                    isExpanded ? '0.5rem' : '1.1rem'};
                padding-top: 1rem;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: var(--primary-game-ui-color);
            }
        }

        .message-time {
            padding-top: 1rem;
            margin-right: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 1.2rem;
            color: #fff;
        }
    }

    &.isUsersMessage {
        background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0.44) 31.94%,
            rgba(0, 0, 0, 0) 100%
        );

        .message-time {
            padding-top: 1rem;
            margin-left: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: 1.2rem;
            color: #fff;
        }

        .chat-info {
            flex-direction: row-reverse;

            .name {
                padding-right: 0.5rem;
                padding-top: 1rem;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                color: var(--primary-game-ui-color);
            }
        }
    }
`;

export const ChatUser = ({
    userMessageObject,
    isUsersMessage,
    isExpanded,
    isMobile,
    timestamp,
}: {
    userMessageObject: UserMessageObject;
    isUsersMessage: boolean;
    isExpanded: boolean;
    isMobile?: boolean;
    timestamp: number;
}) => {
    const [hours, minutes] = useTime(timestamp);

    return (
        <Container
            className={clsx(
                isUsersMessage ? 'isUsersMessage' : 'notUsersMessage'
            )}
            isExpanded={isExpanded}
            isMobile={isMobile}
        >
            <div className="chat-info">
                <span className="name">{userMessageObject.name}</span>
                <span className="message-time">{`${hours}:${minutes}`}</span>
            </div>
            <div className="messages">
                {userMessageObject.messages.map((msg) => {
                    return (
                        <ChatBubble
                            key={msg.timestamp}
                            userMessage={msg}
                            isUsersMessage={isUsersMessage}
                            isExpanded={isExpanded}
                            isMobile={isMobile}
                        />
                    );
                })}
            </div>
        </Container>
    );
};
