import { Point } from '@roulette/models';

export const parseSvgPathData = (pathData: string): Point[] => {
    const points: Point[] = [];
    const pathSegments = pathData.match(
        /[a-df-zA-DF-Z]|[-+]?(?:\d*\.\d+|\d+)(?:[eE][-+]?\d+)?/g
    );

    if (!pathSegments) {
        return points;
    }

    let currentX = 0;
    let currentY = 0;
    let subpathStartX = 0;
    let subpathStartY = 0;
    // let lastControlX = 0;
    // let lastControlY = 0;
    let x1 = 0;
    let x2 = 0;
    let y1 = 0;
    let y2 = 0;
    let x = 0;
    let y = 0;
    const numSegments = 20; // Increase for smoother approximation

    for (let i = 0; i < pathSegments.length; ) {
        const segment = pathSegments[i++];
        const isRelative = segment.toLowerCase() === segment;

        switch (segment) {
            case 'M': // Move to
            case 'm':
                currentX = +pathSegments[i++];
                currentY = +pathSegments[i++];
                subpathStartX = currentX;
                subpathStartY = currentY;
                points.push({ x: currentX, y: currentY });
                break;

            case 'L': // Line to
            case 'l':
                currentX = isRelative
                    ? currentX + +pathSegments[i++]
                    : +pathSegments[i++];
                currentY = isRelative
                    ? currentY + +pathSegments[i++]
                    : +pathSegments[i++];
                points.push({ x: currentX, y: currentY });
                break;

            case 'H': // Horizontal line to
            case 'h':
                currentX = isRelative
                    ? currentX + +pathSegments[i++]
                    : +pathSegments[i++];
                points.push({ x: currentX, y: currentY });
                break;

            case 'V': // Vertical line to
            case 'v':
                currentY = isRelative
                    ? currentY + +pathSegments[i++]
                    : +pathSegments[i++];
                points.push({ x: currentX, y: currentY });
                break;

            case 'C': // Cubic B∩┐╜zier curve
            case 'c':
                x1 = isRelative
                    ? currentX + +pathSegments[i++]
                    : +pathSegments[i++];
                y1 = isRelative
                    ? currentY + +pathSegments[i++]
                    : +pathSegments[i++];
                x2 = isRelative
                    ? currentX + +pathSegments[i++]
                    : +pathSegments[i++];
                y2 = isRelative
                    ? currentY + +pathSegments[i++]
                    : +pathSegments[i++];
                x = isRelative
                    ? currentX + +pathSegments[i++]
                    : +pathSegments[i++];
                y = isRelative
                    ? currentY + +pathSegments[i++]
                    : +pathSegments[i++];

                // Approximate cubic B∩┐╜zier curve with line segments
                for (let t = 0; t <= 1; t += 1 / numSegments) {
                    const tx =
                        Math.pow(1 - t, 3) * currentX +
                        3 * Math.pow(1 - t, 2) * t * x1 +
                        3 * (1 - t) * Math.pow(t, 2) * x2 +
                        Math.pow(t, 3) * x;
                    const ty =
                        Math.pow(1 - t, 3) * currentY +
                        3 * Math.pow(1 - t, 2) * t * y1 +
                        3 * (1 - t) * Math.pow(t, 2) * y2 +
                        Math.pow(t, 3) * y;
                    points.push({ x: tx, y: ty });
                }

                currentX = x;
                currentY = y;
                // lastControlX = x2;
                // lastControlY = y2;
                break;

            case 'Z': // Close path
            case 'z':
                currentX = subpathStartX;
                currentY = subpathStartY;
                points.push({ x: currentX, y: currentY });
                break;

            default:
                // Skip unrecognized segments
                i++; // Move to the next segment
                break;
        }
    }

    return points;
};
