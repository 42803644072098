import styled from '@emotion/styled';
import React from 'react';

export interface SelectorProps {
    row: number; // y
    col: number; // x
    rows: number; // y+
    cols: number; // x+
}

const calculateSelectorHeight = (row: number, rows: number) => {
    const height = `${Math.min(rows, 3)} * var(--roulette-cell-height)`;

    if (row + rows > 3) {
        return `${height} + var(--roulette-bottom-row-height)`;
    }
    return height;
};

const Container = styled.div<{
    row: number;
    col: number;
    rows: number;
    cols: number;
}>`
    position: absolute;
    left: calc(${(props) => props.col} * var(--roulette-cell-width));
    top: ${(props) =>
        props.col === 0
            ? 0
            : `calc(${props.row} * var(--roulette-cell-height))`};
    z-index: 2;

    display: block;

    transition-property: width, height, left, top, border-radius;
    transition-duration: var(--roulette-hover-duration);
    transition-timing-function: ease-in-out;

    width: calc(
        ${(props) =>
                props.col === 0
                    ? 'var(--roulette-cell-width)'
                    : `${props.cols} * var(--roulette-cell-width)`} + 0.2rem
    );
    height: calc(
        ${(props) =>
                props.col === 0
                    ? 'var(--roulette-cell-height) * 3'
                    : calculateSelectorHeight(props.row, props.rows)} + 0.2rem
    );
    box-sizing: border-box;
    border: var(--roulette-hover-border-thickness) solid var(--roulette-hover);
    border-right: ${(props) =>
        props.col === 0 && props.cols > 1
            ? '0'
            : 'var(--roulette-hover-border-thickness) solid var(--roulette-hover)'};
    border-top-left-radius: ${(props) =>
        props.col === 0 ? 'var(--roulette-border-radius)' : 0};

    border-top-right-radius: ${(props) =>
        props.col + props.cols > 13 && props.row === 0
            ? 'var(--roulette-border-radius)'
            : 0};

    border-bottom-left-radius: ${(props) =>
        props.col === 0 ? 'var(--roulette-border-radius)' : 0};

    border-bottom-right-radius: ${(props) =>
        props.col + props.cols > 13 && props.row === 2
            ? 'var(--roulette-border-radius)'
            : 0};

    background-color: rgba(15, 74, 122, 0.8);
    pointer-events: none;

    &:after {
        content: '';

        position: absolute;
        top: calc(
            ${(props) => props.row} * var(--roulette-cell-height) - 0.25rem
        );
        left: var(--roulette-cell-width);

        display: block;
        opacity: ${(props) => (props.col === 0 && props.cols > 1 ? 1 : 0)};

        transition-property: opacity;
        transition-duration: var(--roulette-hover-duration);
        transition-timing-function: ease-in-out;

        width: ${(props) =>
            props.col === 0 && props.cols > 1
                ? 'var(--roulette-cell-width)'
                : 0};
        height: calc(
            ${(props) => props.rows} * var(--roulette-cell-height) + 0.25rem
        );

        box-sizing: border-box;
        border: var(--roulette-hover-border-thickness) solid
            var(--roulette-hover);
        border-width: ${(props) =>
            props.col === 0 && props.cols > 1 ? 0.25 : 0}rem;
        border-left: none;

        background-color: rgba(15, 74, 122, 0.8);
        pointer-events: none;
    }
`;

const SelectorComponent = (props: SelectorProps) => {
    return <Container className="selector" {...props} />;
};

export const Selector = React.memo(SelectorComponent);
