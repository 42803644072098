import styled from '@emotion/styled';
import { formatCurrency } from '@shared/utils';
import { HTMLAttributes } from 'react';

const Container = styled.div`
    font-family: 'Chakra Petch';
    color: var(--color-theme-white);
`;

const Logout = styled.div`
    height: 4rem;
    width: 4rem;
    margin-right: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 1.5rem;
    color: var(--color-primary);
    /* margin-right: 0.9rem; */

    cursor: pointer;
    pointer-events: auto;

    /* TODO  check? */
    svg {
        stroke: var(--color-border);
        path {
            fill: var(--color-primary);
        }
    }
`;

const GameInfoContainer = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;

    .game-title {
        font-family: 'Chakra Petch';

        font-size: ${({ isMobile }) => (isMobile ? '1.65rem' : '2.3rem')};
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .studio-name {
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: var(--color-primary);
    }

    .min-max-bet {
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: var(--color-theme-white);
    }
`;

interface Props extends HTMLAttributes<HTMLDivElement> {
    gameName: string;
    minBet: number;
    maxBet: number;
    currency?: string;
    isMobile?: boolean;
    onClose?: () => void;
}

export const TableName = ({
    gameName,
    minBet,
    maxBet,
    currency = 'USD',
    isMobile,
    onClose,
    className,
}: Props) => {
    return (
        <Container className={className}>
            <Logout onClick={onClose}>
                <svg
                    width="42"
                    height="42"
                    viewBox="0 0 42 42"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="1"
                        y="1"
                        width="40"
                        height="40"
                        rx="20"
                        fillOpacity="0.4"
                        strokeOpacity="0.5"
                        strokeLinecap="round"
                    />
                    <path
                        d="M17.8184 14.4091L20.9505 19.5291H21.0527L24.1848 14.4091H26.4732L22.3951 20.9545L26.4988 27.5H24.1976L21.0527 22.4503H20.9505L17.8056 27.5H15.5044L19.6784 20.9545L15.53 14.4091H17.8184Z"
                        stroke="none"
                    />
                </svg>
            </Logout>

            <GameInfoContainer isMobile={isMobile}>
                <span className="game-title">{gameName} </span>
                <div>
                    <span className="studio-name">ANIMO STUDIOS </span>
                    <span className="min-max-bet">
                        {formatCurrency(minBet, currency)} -{' '}
                        {formatCurrency(maxBet, currency)}
                    </span>
                </div>
            </GameInfoContainer>
        </Container>
    );
};
