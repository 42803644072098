import React, { PropsWithChildren } from 'react';
import { ReactComponent as HamburgerIcon } from '../../assets/icons/Hamburger.svg';
import { ReactComponent as XIcon } from '../../assets/icons/X.svg';
import styled from '@emotion/styled';
import { slideMenusIn, slideMenusOut } from '../animations';

const Container = styled.div`
    color: var(--color-primary);
    height: 4rem;
    width: 4rem;

    margin-top: 1rem;

    position: absolute;
    top: 0;
    right: 8rem; /* TODO - temp solution */

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    border: 0.05rem solid rgba(var(--color-primary-opacity), 0.5);
    background: radial-gradient(
            112.5% 112.5% at 50% 15.28%,
            rgba(12, 12, 12, 0.85) 24.5%,
            rgba(0, 0, 0, 0.374) 100%
        ),
        rgba(102, 102, 102, 0.19);
    backdrop-filter: blur(2rem);
    border-radius: 0.8rem;
    z-index: 51;
`;

const SettingsContainer = styled.div<{ showMenus?: boolean }>`
    display: flex;
    position: absolute;
    top: 18rem;

    animation: ${({ showMenus }) => (showMenus ? slideMenusIn : slideMenusOut)}
        0.5s forwards;

    svg {
        transform: rotate(-90deg);
    }
`;

interface HamburgerProps extends PropsWithChildren {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Hamburger = ({ isOpen, setIsOpen, children }: HamburgerProps) => {
    return (
        <Container onClick={() => setIsOpen((prev) => !prev)}>
            {isOpen ? (
                <XIcon height="3rem" width="3rem" />
            ) : (
                <HamburgerIcon height="6rem" width="6rem" />
            )}

            {isOpen && (
                <SettingsContainer
                    showMenus={isOpen}
                    onClick={(e) => e.stopPropagation()}
                >
                    {children}
                </SettingsContainer>
            )}
        </Container>
    );
};
