import React from 'react';

export const mockSettingsInfo = [
    {
        title: 'Objective of the Game',
        content: (
            <p>
                The goal of Blackjack is to beat the dealer by having a hand
                total closer to 21 without going over. Players are dealt two
                cards and can choose to "hit" for additional cards or "stand" to
                keep their current hand.
            </p>
        ),
    },
    {
        title: 'Card Values',
        content: (
            <ul>
                <li>Number cards (2-10) are worth their face value.</li>
                <li>Face cards (King, Queen, Jack) are worth 10 points.</li>
                <li>
                    Aces can be worth 1 or 11, depending on which value helps
                    the hand more.
                </li>
            </ul>
        ),
    },
    {
        title: 'How to Play a Hand',
        content: (
            <ol>
                <li>
                    Players are dealt two cards, and the dealer receives two
                    cards (one face up and one face down).
                </li>
                <li>
                    Players can choose to:
                    <ul>
                        <li>
                            <strong>Hit:</strong> Take another card to improve
                            their hand total.
                        </li>
                        <li>
                            <strong>Stand:</strong> Keep their current hand and
                            end their turn.
                        </li>
                    </ul>
                </li>
                <li>
                    If the player's hand exceeds 21, they "bust" and lose the
                    round.
                </li>
            </ol>
        ),
    },
    {
        title: 'Dealer Rules',
        content: (
            <div>
                <p>The dealer must follow specific rules:</p>
                <ul>
                    <li>
                        The dealer must hit until their hand totals at least 17.
                    </li>
                    <li>
                        Dealers cannot choose to stand below 17 or hit above 17.
                    </li>
                </ul>
            </div>
        ),
    },
    {
        title: 'Winning and Payouts',
        content: (
            <ul>
                <li>
                    If the player's hand is closer to 21 than the dealer's
                    without busting, the player wins.
                </li>
                <li>If the dealer busts, the player automatically wins.</li>
                <li>
                    A winning hand pays out 1:1, while a Blackjack (an Ace and a
                    10-point card) pays 3:2.
                </li>
            </ul>
        ),
    },
];

export const mockSettingsHistory = [
    {
        id: '1',
        date: '2024-01-01',
        game: 'Premium Blackjack',
        bet: 0.2,
        profit: 0.4,
    },
    { id: '2', date: '2024-01-02', game: 'Roulette', bet: 1250, profit: 0 },
    { id: '3', date: '2024-01-03', game: 'Baccarat', bet: 950, profit: 500 },
    {
        id: '4',
        date: '2024-01-04',
        game: 'Premium Blackjack',
        bet: 45000,
        profit: -23000,
    },
    { id: '5', date: '2024-01-05', game: 'Roulette', bet: 1000, profit: 700 },
    {
        id: '6',
        date: '2024-01-06',
        game: 'Baccarat',
        bet: 78000,
        profit: 39000,
    },
    {
        id: '7',
        date: '2024-01-07',
        game: 'Premium Blackjack',
        bet: 43000,
        profit: -21000,
    },
    {
        id: '8',
        date: '2024-01-08',
        game: 'Roulette',
        bet: 52000,
        profit: 27000,
    },
    {
        id: '9',
        date: '2024-01-09',
        game: 'Baccarat',
        bet: 60000,
        profit: -34000,
    },
    {
        id: '10',
        date: '2024-01-10',
        game: 'Premium Blackjack',
        bet: 90000,
        profit: 50000,
    },
    {
        id: '11',
        date: '2024-01-11',
        game: 'Roulette',
        bet: 10000,
        profit: 6000,
    },
    {
        id: '12',
        date: '2024-01-12',
        game: 'Baccarat',
        bet: 75000,
        profit: -38000,
    },
    {
        id: '13',
        date: '2024-01-13',
        game: 'Premium Blackjack',
        bet: 40000,
        profit: 20000,
    },
    { id: '14', date: '2024-01-14', game: 'Roulette', bet: 1500, profit: 800 },
    {
        id: '15',
        date: '2024-01-15',
        game: 'Baccarat',
        bet: 82000,
        profit: 45000,
    },
    {
        id: '16',
        date: '2024-01-16',
        game: 'Premium Blackjack',
        bet: 34000,
        profit: 17000,
    },
    {
        id: '17',
        date: '2024-01-17',
        game: 'Roulette',
        bet: 22000,
        profit: 11000,
    },
    {
        id: '18',
        date: '2024-01-18',
        game: 'Baccarat',
        bet: 67000,
        profit: 35000,
    },
    {
        id: '19',
        date: '2024-01-19',
        game: 'Premium Blackjack',
        bet: 91000,
        profit: 46000,
    },
    { id: '20', date: '2024-01-20', game: 'Roulette', bet: 1700, profit: 850 },
    {
        id: '21',
        date: '2024-01-21',
        game: 'Baccarat',
        bet: 48000,
        profit: 24000,
    },
    {
        id: '22',
        date: '2024-01-22',
        game: 'Premium Blackjack',
        bet: 1000,
        profit: 500,
    },
    {
        id: '23',
        date: '2024-01-23',
        game: 'Roulette',
        bet: 35000,
        profit: 18000,
    },
    {
        id: '24',
        date: '2024-01-24',
        game: 'Baccarat',
        bet: 99000,
        profit: 50000,
    },
    {
        id: '25',
        date: '2024-01-25',
        game: 'Premium Blackjack',
        bet: 25000,
        profit: 13000,
    },
    {
        id: '26',
        date: '2024-01-26',
        game: 'Roulette',
        bet: 70000,
        profit: 37000,
    },
    {
        id: '27',
        date: '2024-01-27',
        game: 'Baccarat',
        bet: 89000,
        profit: 45000,
    },
    {
        id: '28',
        date: '2024-01-28',
        game: 'Premium Blackjack',
        bet: 33000,
        profit: 17000,
    },
    {
        id: '29',
        date: '2024-01-29',
        game: 'Roulette',
        bet: 56000,
        profit: 29000,
    },
    {
        id: '30',
        date: '2024-01-30',
        game: 'Baccarat',
        bet: 73000,
        profit: 37000,
    },
    {
        id: '31',
        date: '2024-01-31',
        game: 'Premium Blackjack',
        bet: 82000,
        profit: 41000,
    },
    {
        id: '32',
        date: '2024-02-01',
        game: 'Roulette',
        bet: 15000,
        profit: 7500,
    },
    {
        id: '33',
        date: '2024-02-02',
        game: 'Baccarat',
        bet: 62000,
        profit: 31000,
    },
    {
        id: '34',
        date: '2024-02-03',
        game: 'Premium Blackjack',
        bet: 45000,
        profit: 22000,
    },
    {
        id: '35',
        date: '2024-02-04',
        game: 'Roulette',
        bet: 76000,
        profit: 38000,
    },
    {
        id: '36',
        date: '2024-02-05',
        game: 'Baccarat',
        bet: 91000,
        profit: 47000,
    },
    {
        id: '37',
        date: '2024-02-06',
        game: 'Premium Blackjack',
        bet: 48000,
        profit: 24000,
    },
    { id: '38', date: '2024-02-07', game: 'Roulette', bet: 5000, profit: 2500 },
    {
        id: '39',
        date: '2024-02-08',
        game: 'Baccarat',
        bet: 68000,
        profit: 35000,
    },
    {
        id: '40',
        date: '2024-02-09',
        game: 'Premium Blackjack',
        bet: 92000,
        profit: 46000,
    },
    {
        id: '41',
        date: '2024-02-10',
        game: 'Roulette',
        bet: 18000,
        profit: 9000,
    },
    {
        id: '42',
        date: '2024-02-11',
        game: 'Baccarat',
        bet: 40000,
        profit: 20000,
    },
    {
        id: '43',
        date: '2024-02-12',
        game: 'Premium Blackjack',
        bet: 51000,
        profit: 26000,
    },
    {
        id: '44',
        date: '2024-02-13',
        game: 'Roulette',
        bet: 90000,
        profit: 45000,
    },
    {
        id: '45',
        date: '2024-02-14',
        game: 'Baccarat',
        bet: 82000,
        profit: 41000,
    },
    {
        id: '46',
        date: '2024-02-15',
        game: 'Premium Blackjack',
        bet: 33000,
        profit: 16000,
    },
    {
        id: '47',
        date: '2024-02-16',
        game: 'Roulette',
        bet: 59000,
        profit: 30000,
    },
    {
        id: '48',
        date: '2024-02-17',
        game: 'Baccarat',
        bet: 74000,
        profit: 37000,
    },
    {
        id: '49',
        date: '2024-02-18',
        game: 'Premium Blackjack',
        bet: 61000,
        profit: 31000,
    },
    {
        id: '50',
        date: '2024-02-19',
        game: 'Roulette',
        bet: 97000,
        profit: 49000,
    },
];
