import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { Chat, SettingsButtonsRow, TableName } from '@shared/ui/components';

import { LeftButtons, RightButtons } from '@shared/ui/components/game-layout';
import { AnimoUserData } from '@shared/aggregator/models';
import { useBaseContext } from '@shared/ui/contexts';

const Container = styled.div`
    position: absolute;
    inset: 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .top-row {
        display: flex;
        flex-direction: row;

        margin-left: 2rem;
        margin-top: 2rem;

        height: 4rem;
    }

    .exit {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 4rem;
        height: 4rem;

        z-index: 1;

        pointer-events: auto;
        cursor: pointer;

        margin-right: 1rem;

        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif;
        font-size: 1.4rem;
        color: var(--baccarat-gold-color);

        border: 0.5px solid var(--baccarat-border-color);
        border-radius: 1rem;
        background: var(--baccarat-opaque-background);
    }

    .table-limits {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        width: 33rem;
        height: 4rem;

        margin-right: 1rem;

        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif;
        font-size: 1.3rem;

        border: 0.5px solid var(--baccarat-border-color);
        border-radius: 1rem;
        background: var(--baccarat-opaque-background);

        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif;

        .title {
            font-size: 1.9rem;
            color: var(--baccarat-white-color);
        }
        .value {
            font-size: 1.7rem;
            color: var(--baccarat-gold-color);
        }
    }

    .chat {
        display: flex;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        margin-left: 2rem;
        margin-top: 2rem;
    }
`;

const MiddleColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: end;
    pointer-events: none;
    box-sizing: border-box;
`;

const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .settings-row {
        display: flex;
        justify-content: end;
        margin-right: 2rem;
        margin-top: 2rem;
    }
`;

const ChildWrapper = styled.div`
    overflow: hidden;
`;

const Footer = styled.div`
    margin-top: auto;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: end;

    width: 100%;
    height: 100%;
    box-sizing: border-box;
`;

export interface BaseGameLayoutProps {
    gameName: string;
    minBet: number;
    maxBet: number;
    userId: string;
    baseBetTotal: number;
    sideBetTotals: number;
    user: Pick<AnimoUserData, 'balance' | 'currency'>;
}

export const BaseGameLayoutDesktop = ({
    gameName,
    minBet,
    maxBet,
    userId,
    baseBetTotal,
    sideBetTotals,
    user,
    children,
}: PropsWithChildren<BaseGameLayoutProps>) => {
    const isVertical = false;

    const {
        baseState: {
            serverInfo: { lobbyUrl },
        },
    } = useBaseContext();

    return (
        <>
            <Container>
                <LeftColumn>
                    <TableName
                        className="top-row"
                        gameName={gameName}
                        minBet={minBet}
                        maxBet={maxBet}
                        currency={user.currency}
                    />
                    <Chat className="chat" userId={userId} />
                    <Footer>
                        <LeftButtons
                            isVertical={isVertical}
                            user={user}
                            roundInfo={{
                                sideBetTotals: sideBetTotals,
                                baseBetTotal: baseBetTotal,
                                totalWon: 0,
                            }}
                        />
                    </Footer>
                </LeftColumn>

                <MiddleColumn>{/* Such nothing */}</MiddleColumn>

                <RightColumn>
                    <div className="settings-row">
                        <SettingsButtonsRow isMobile={isVertical} />
                    </div>

                    <Footer>
                        <RightButtons
                            isVertical={isVertical}
                            enableTable={false}
                            enableLobby={lobbyUrl !== ''}
                            lobbyUrl={lobbyUrl}
                        />
                    </Footer>
                </RightColumn>
            </Container>
            <ChildWrapper>{children}</ChildWrapper>
        </>
    );
};
