import styled from '@emotion/styled';
import { useLogicStateContext } from '@roulette/contexts';
import { boardNumbers } from '@roulette/models';
import clsx from 'clsx';

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(9, 3rem);
    gap: 1rem;
    justify-content: space-evenly;
    align-content: center;

    color: var(--color-theme-white);
    font-family: 'Chakra Petch', sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .box {
        height: 3rem;
        width: 3.1rem;

        padding: 0.5rem 0.7rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        box-sizing: border-box;
        border-radius: 0.5rem;
        color: var(--color-theme-white);

        &.red-item {
            background-color: var(--roulette-red);
        }

        &.black-item {
            background-color: black;
        }

        &.green-item {
            background-color: var(--roulette-zero-green);
        }
    }
`;

export const LatestResultPopupBox = () => {
    const { range } = useLogicStateContext();
    const { resultHistory } = useLogicStateContext();

    const reversedHistory = resultHistory.slice().reverse();

    return (
        <Container>
            {Array.from({ length: range }, (_, i) => {
                const boardNumber =
                    reversedHistory.length < i
                        ? null
                        : boardNumbers[reversedHistory[i]];

                return (
                    <div
                        className={clsx(
                            'box',
                            `${boardNumber?.color || 'undefined'}-item`
                        )}
                        key={i}
                    >
                        {boardNumber ? boardNumber.value : ''}
                    </div>
                );
            })}
        </Container>
    );
};
