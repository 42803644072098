import { SocketError } from '@shared/events';
import styled from '@emotion/styled';
import { AspectRatioWrapper, useAspectRatioHandler } from '@shared/ui/hooks';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 108rem;
    width: 192rem;

    .message {
        color: 'white';
        font-family: 'Chakra Petch';

        font-size: 1.6rem;
    }
`;

export const TableLimitError = (
    error: SocketError,
    exitToLobbyCallback: () => void
) => {
    const { isVertical } = useAspectRatioHandler();

    return (
        <AspectRatioWrapper isVertical={isVertical}>
            <Container>
                <div role="alert">
                    <p className="message">{error.message}</p>
                    <button onClick={exitToLobbyCallback}>Back to Lobby</button>
                </div>
            </Container>
        </AspectRatioWrapper>
    );
};
