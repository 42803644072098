import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import {
    SettingButton as ChatSettingsButton,
    SettingButton as AudioSettingsButton,
    SettingButton as OtherSettingsButton,
    SettingButton as HistorySettingsButton,
    SettingButton as InfoSettingsButton,
    SettingButton as FullScreenSettingsButton,
} from './buttons/setting-button';
import { SettingsModal } from './settings/settings-modal';
import { ICONS } from './icons';
import { SettingMenuItems } from './settings/settings.type';
import { Hamburger } from './game-layout';
import { useAudio, useGameSettings } from '@shared/ui/contexts';

const Container = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: end;
    z-index: 51;
    margin-top: ${({ isMobile }) => (isMobile ? 0 : '2rem')};
    margin-right: 2rem;
`;

const ButtonContainer = styled.div<{ isMobile?: boolean }>`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    height: 4rem;
    z-index: 1;
    pointer-events: auto;
    color: var(--color-primary);

    & > * + *:not(.modal) {
        margin-left: 1rem;
    }
`;

export interface SettingsButtonRowProps {
    onSettingsClick?: () => void;
    onMuteClick?: () => void;
    isMobile?: boolean;
}

export const SettingsButtonsRow = ({
    onSettingsClick, // TODO delete when converting BJ to baselayout
    isMobile,
}: SettingsButtonRowProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);
    const [activeSetting, setActiveSetting] = useState<SettingMenuItems | null>(
        null
    );
    const [isFullScreen, setIsFullScreen] = useState(
        document.fullscreenElement
    );

    const {
        hideChat,
        hideAudio,
        hideSettings,
        hideHistory,
        hideInfo,
        hideFullscreen,
    } = useGameSettings();

    const { isMasterMuted } = useAudio();

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener(
                'fullscreenchange',
                handleFullscreenChange
            );
        };
    }, []);

    const handleClick = useCallback(
        (setting: SettingMenuItems) => {
            if (activeSetting === setting) return setActiveSetting(null);
            setActiveSetting(setting);
        },
        [activeSetting]
    );

    const closeModal = () => {
        setActiveSetting(null);
    };

    const buttons = useMemo(() => {
        return (
            <ButtonContainer>
                {!hideChat && (
                    <ChatSettingsButton
                        icon={ICONS.Message}
                        onClick={() => handleClick(SettingMenuItems.CHAT)}
                    />
                )}
                {!hideAudio && (
                    <AudioSettingsButton
                        icon={isMasterMuted ? ICONS.Mute : ICONS.Unmute}
                        onClick={() => handleClick(SettingMenuItems.AUDIO)}
                    />
                )}
                {!hideSettings && (
                    <OtherSettingsButton
                        icon={ICONS.Setting}
                        onClick={() => handleClick(SettingMenuItems.OTHER)}
                    />
                )}
                {!hideHistory && (
                    <HistorySettingsButton
                        icon={ICONS.BackArrow}
                        onClick={() => handleClick(SettingMenuItems.HISTORY)}
                    />
                )}
                {!hideInfo && (
                    <InfoSettingsButton
                        icon={ICONS.Info}
                        onClick={() => handleClick(SettingMenuItems.INFO)}
                    />
                )}
                {!hideFullscreen && (
                    <FullScreenSettingsButton
                        icon={isFullScreen ? ICONS.Minimize : ICONS.Fullscreen}
                        onClick={toggleFullScreen}
                    />
                )}
                <SettingsModal
                    ref={modalRef}
                    activeSetting={activeSetting}
                    isMobile={isMobile ?? false}
                />
            </ButtonContainer>
        );
    }, [
        modalRef,
        activeSetting,
        hideHistory,
        hideFullscreen,
        hideSettings,
        hideChat,
        hideInfo,
        hideAudio,
        isFullScreen,
        isMasterMuted,
        isMobile,
        handleClick,
    ]);

    // Desktop
    return (
        <>
            {isMobile ? (
                <Hamburger isOpen={isOpen} setIsOpen={setIsOpen}>
                    <Container ref={containerRef} isMobile={isMobile}>
                        {buttons}
                    </Container>
                </Hamburger>
            ) : (
                <Container ref={containerRef} isMobile={isMobile}>
                    {buttons}
                </Container>
            )}
            <ClickAwayOverlay
                onClickAway={closeModal}
                containerRef={containerRef}
                modalRef={modalRef}
                activeSetting={activeSetting}
            />
        </>
    );
};

const ClickAway = styled.div<{ activeSetting: string | null }>`
    position: fixed;
    inset: 0;
    background-color: transparent;
    pointer-events: ${({ activeSetting }) =>
        activeSetting !== null
            ? ''
            : 'none'}; // only make clickable when modal isOpen
    z-index: 50;
`;

const ClickAwayOverlay = ({
    activeSetting,
    onClickAway,
    containerRef,
    modalRef,
}: {
    activeSetting: SettingMenuItems | null;
    onClickAway: () => void;
    containerRef: React.RefObject<HTMLDivElement>;
    modalRef: React.RefObject<HTMLDivElement>;
}) => {
    const handleClick = (event: React.MouseEvent) => {
        const target = event.target as Node;
        if (
            containerRef.current &&
            modalRef.current &&
            !containerRef.current.contains(target) &&
            !modalRef.current.contains(target)
        ) {
            onClickAway();
        }
    };

    return <ClickAway activeSetting={activeSetting} onClick={handleClick} />;
};
