import {
    createContext,
    PropsWithChildren,
    ReactElement,
    useContext,
    useMemo,
} from 'react';

interface GameInfo {
    title: string;
    content: ReactElement;
}

type HiddenSettings = {
    hideChat?: boolean;
    hideAudio?: boolean;
    hideSettings?: boolean;
    hideHistory?: boolean;
    hideInfo?: boolean;
    hideFullscreen?: boolean;
};

type GameSettingsValues = HiddenSettings & {
    gameInfo: GameInfo[];
};

type GameSettingsContextProviderProps = PropsWithChildren &
    HiddenSettings & {
        gameInfo?: GameInfo[];
    };

const defaultProps = {
    gameInfo: [
        {
            title: 'Objective of the Game',
            content: <p>Testing game info</p>,
        },
    ],
};

const GameSettingsContext = createContext<GameSettingsValues>(defaultProps);

export const GameSettingsContextProvider = (
    props: GameSettingsContextProviderProps = defaultProps
) => {
    const {
        children,
        hideChat,
        hideAudio,
        hideSettings,
        hideHistory,
        hideInfo,
        hideFullscreen,
        gameInfo,
    } = props;

    const values = useMemo(() => {
        return {
            gameInfo: gameInfo ?? defaultProps.gameInfo,
            hideChat,
            hideAudio,
            hideSettings,
            hideHistory,
            hideInfo,
            hideFullscreen,
        };
    }, [
        gameInfo,
        hideChat,
        hideAudio,
        hideSettings,
        hideHistory,
        hideInfo,
        hideFullscreen,
    ]);

    return (
        <GameSettingsContext.Provider value={values}>
            {children}
        </GameSettingsContext.Provider>
    );
};

export const useGameSettings = () => {
    const context = useContext(GameSettingsContext);
    return context;
};
