import styled from '@emotion/styled';
import { useLogicStateContext } from '@roulette/contexts';
import clsx from 'clsx';

const Container = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 11.4rem);
    grid-template-rows: repeat(1, 7.4rem);
    gap: 1rem;
    color: #d9d9d9;
    align-content: center;

    font-family: 'Chakra Petch', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.56rem;

    margin-left: 2rem;

    .box {
        display: flex;
        flex-direction: column;
        justify-content: center;

        font-family: 'Chakra Petch', sans-serif;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.56rem;

        width: 11.4rem;
        height: 6.4rem;
        box-sizing: border-box;
        background: radial-gradient(
                112.5% 112.5% at 50% 15.28%,
                rgba(12, 12, 12, 0.85) 24.5%,
                rgba(0, 0, 0, 0.374) 100%
            ),
            rgba(102, 102, 102, 0.19);
        border: 0.5px solid #7695b2;
        border-radius: 8px;
        text-align: center;
        margin: 3px;

        &.red-item {
            background-color: black;
            color: #f24822;
        }

        &.black-item {
            background-color: black;
            color: #ffffff;
        }
    }

    .last-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        font-family: 'Chakra Petch', sans-serif;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.56rem;

        height: 13.5rem;
        width: 7.4rem;
        margin-left: -4rem;
        grid-column: 3;
        grid-row: 1 / span 2;
        align-self: center;
        justify-self: center;

        position: relative;
        top: 2px;

        background: radial-gradient(
                112.5% 112.5% at 50% 15.28%,
                rgba(12, 12, 12, 0.85) 24.5%,
                rgba(0, 0, 0, 0.374) 100%
            ),
            rgba(102, 102, 102, 0.19);
        border: 0.5px solid #7695b2;
        border-radius: 8px;
    }
`;

export const ChartPopupBox = () => {
    const { gameStats } = useLogicStateContext();

    const {
        evenPercentage,
        oddPercentage,
        oneToEighteenPercentage,
        eighteenToThirtySixPercentage,
    } = gameStats;

    return (
        <Container>
            <div className={clsx('box')}>
                <div>EVEN</div>
                <div>{evenPercentage}%</div>
            </div>
            <div className={clsx('box')}>
                <div>ODD</div>
                <div>{oddPercentage}%</div>{' '}
            </div>
            <div className={clsx('box')}>
                <div>1-18</div>
                <div>{oneToEighteenPercentage}%</div>
            </div>
            <div className={clsx('box')}>
                <div>19-36</div>
                <div>{eighteenToThirtySixPercentage}%</div>
            </div>
            <div className={clsx('last-box')}>
                <div></div>
            </div>
        </Container>
    );
};
