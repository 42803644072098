import styled from '@emotion/styled';

import { IoRepeat } from 'react-icons/io5';
import { ImUndo2 } from 'react-icons/im';

import {
    useGameContext,
    useLogicActionContext,
    useLogicStateContext,
} from '@roulette/contexts';
import { RouletteStates } from '@roulette/models';

import { ChipRowButton } from '@shared/ui/components/chips';

import { ChipSelector } from './chip-selector';
import { useAudio, useGameSettings } from '@shared/ui/contexts';

const Container = styled.div<{ isMobile: boolean | undefined }>`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    width: 67.6rem;
    height: 5.5rem;

    margin-bottom: 2rem;

    z-index: 1;

    /* border: 0.2rem solid hotpink; */
    box-sizing: border-box;

    .bet-button {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        transform: ${({ isMobile }) => (isMobile ? 'rotate(90deg)' : '')};
    }
`;

interface Props {
    isMobile?: boolean;
}

export const ChipRow = ({ isMobile }: Props) => {
    const {
        totalCredits,
        activeChip,
        baseBetTotal,
        // outsideBetTotals,
        canRepeat,
    } = useLogicStateContext();

    const { undoBet, doubleBets, repeatLast, setActiveChip } =
        useLogicActionContext();

    const { gameState } = useGameContext();

    const { isEffectsMuted, effectsVolume } = useAudio();

    const betAmounts = gameState.gameInfo?.chipAmounts || [];

    const undoButtonDisabled =
        gameState.currentState.next_state !== RouletteStates.WaitingForBets ||
        baseBetTotal === 0;

    const repeatButtonDisabled =
        gameState.currentState.next_state !== RouletteStates.WaitingForBets ||
        !canRepeat;

    const canDouble =
        (gameState.user?.balance || 0) >= baseBetTotal * 2 &&
        baseBetTotal * 2 <= (gameState.gameInfo?.maxBet || 0);

    return (
        <Container isMobile={isMobile}>
            <div className="bet-button">
                <ChipRowButton
                    onClickHandler={undoBet}
                    disabled={undoButtonDisabled}
                    text={!isMobile ? 'UNDO' : ''}
                    svg={isMobile ? <ImUndo2 /> : undefined}
                    muted={isEffectsMuted}
                    volume={effectsVolume}
                />
            </div>
            <ChipSelector
                betAmounts={betAmounts}
                activeChip={activeChip}
                totalCredits={totalCredits}
                setActiveChip={setActiveChip}
                isMobile={isMobile}
            />
            {baseBetTotal > 0 && !isMobile ? (
                <div className="bet-button">
                    <ChipRowButton
                        onClickHandler={doubleBets}
                        disabled={undoButtonDisabled || !canDouble}
                        text={'DOUBLE'}
                        muted={isEffectsMuted}
                        volume={effectsVolume}
                    />
                </div>
            ) : isMobile ? (
                <div className="bet-button">
                    <ChipRowButton
                        onClickHandler={doubleBets}
                        disabled={undoButtonDisabled || !canDouble}
                        text={'X2'}
                        muted={isEffectsMuted}
                        volume={effectsVolume}
                    />
                </div>
            ) : (
                <div className="bet-button">
                    <ChipRowButton
                        onClickHandler={repeatLast}
                        disabled={repeatButtonDisabled}
                        svg={<IoRepeat />}
                        muted={isEffectsMuted}
                        volume={effectsVolume}
                    />
                </div>
            )}
        </Container>
    );
};
