import { PropsWithChildren, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import {
    AudioContextProvider,
    BaseContextProvider,
    ChatContextProvider,
    ChipContextProvider,
    WebSocketProvider,
} from '@shared/ui/contexts';
import { DemoCasino } from '@shared/ui/demo-casino';

// App default styles
import '@shared/assets/styles/style.css';

import { BaseApp } from './base-app';
import { Themes, ThemeWrapper } from './theme-wrapper';
import { ErrorFallbackHandler } from './components/error-fallback-handler';

export interface GameLayoutProps extends PropsWithChildren {
    name: string;
    theme?: Themes;
}

const params = new URLSearchParams(window.location.search);

const isRoot = window.top === window.self;

const renderCasino =
    process.env.NODE_ENV !== 'production' && !params.has('session') && isRoot;

export const BaseAppLayout = (props: GameLayoutProps) => {
    const { name, theme, children } = props;

    if (renderCasino) {
        return <DemoCasino app={name} />;
    }

    return (
        <StrictMode>
            <BrowserRouter>
                <ErrorBoundary FallbackComponent={ErrorFallbackHandler}>
                    <WebSocketProvider
                        sessionId={params.get('session') || undefined}
                    >
                        <BaseContextProvider>
                            <ChatContextProvider>
                                <AudioContextProvider>
                                    <ChipContextProvider>
                                        <ThemeWrapper theme={theme}>
                                            <BaseApp>{children}</BaseApp>
                                        </ThemeWrapper>
                                    </ChipContextProvider>
                                </AudioContextProvider>
                            </ChatContextProvider>
                        </BaseContextProvider>
                    </WebSocketProvider>
                </ErrorBoundary>
            </BrowserRouter>
        </StrictMode>
    );
};
