import styled from '@emotion/styled';

import { popupBoxComponents } from './popup-box.constant';
import { ReactComponent as NextArrow } from '../../../assets/icons/close-arrow.svg';
import { ReactComponent as Dots } from '../../../assets/icons/dots.svg';

interface Props {
    title: string;
    setActivePopupBox: (string: string) => void;
}

const Container = styled.div`
    height: 4rem;

    width: 95%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    pointer-events: auto;

    position: relative;
    z-index: 2;

    .dots {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        margin-right: 0.5rem;

        .dot {
            display: flex;
            justify-content: center;
            cursor: pointer;

            svg {
                height: 1rem;
                width: 1rem;
            }
        }

        .active svg {
            fill: var(--color-secondary);
        }

        svg {
            fill: rgba(100, 184, 255, 0.5);
        }
    }

    .next-arrow {
        display: flex;
        justify-content: center;
        cursor: pointer;
        svg {
            height: 1rem;
            width: 1rem;
            fill: var(--color-theme-white);
        }
    }
`;

export const PopupFooter = ({ title, setActivePopupBox }: Props) => {
    const nextIndex =
        (popupBoxComponents.findIndex((t) => t.name === title) + 1) %
        popupBoxComponents.length;

    const handleDotClick = (index: number) => {
        setActivePopupBox(popupBoxComponents[index].name);
    };

    return (
        <Container>
            <div className="dots">
                {popupBoxComponents.map((_, index) => (
                    <div
                        className={`dot ${_.name === title && 'active'}`}
                        onClick={() => setActivePopupBox(_.name)}
                    >
                        <Dots />
                    </div>
                ))}
            </div>
            <div className="next-arrow">
                <NextArrow
                    onClick={() => {
                        handleDotClick(nextIndex);
                    }}
                />
            </div>
        </Container>
    );
};
