import { v4 as uuidv4 } from 'uuid';

import { ICard } from '@shared/models';
import { AnimoUserData, UserGameSettings } from '@shared/aggregator/models';

export class SocketError extends Error {
    data? = { code: 0 };
    constructor(code: number, message: string) {
        super(message);
        if (this.data) {
            this.data.code = code;
        }
    }
}

export enum InternalEvents {
    InternalJoin = 'InternalJoin',
    UserJoin = 'UserJoin',
    UserDisconnect = 'UserDisconnect',
    Ready = 'Ready',
    DeleteChatMessage = 'DeleteChatMessage',
}

export enum FromServerEvents {
    ChatMessage = 'ChatMessage',
    ChatMessageDeleted = 'ChatMessageDeleted',
    DuplicateSession = 'DuplicateSession',
    Error = 'Error',
    Response = 'Response',
    User = 'User',
    ServerInfo = 'ServerInfo',
    WaitingForCard = 'WaitingForCard',
    WaitingForWheel = 'WaitingForWheel',
}

export enum OtherEvents {
    AuthToken = 'AuthToken',
}

export enum ToServerEvents {
    CardUpdate = 'CardUpdate', // Single Card
    CameraChange = 'CameraChange',
    WheelUpdate = 'WheelUpdate',
}

export enum FromUserEvents {
    SendChatMessage = 'SendChatMessage',
    UserSettings = 'UserSettings',
}

export const BaseEvents = {
    ...InternalEvents,
    ...FromServerEvents,
    ...ToServerEvents,
    ...FromUserEvents,
    ...OtherEvents,
};

export type BaseEvents = keyof typeof BaseEvents;

export type IBaseEvent<T, D = unknown> = [
    T,
    { event: T; message_id: string; payload: D; timestamp: number }
];

export const createEvent = <T, D = unknown>(
    type: T,
    data: D
): IBaseEvent<T, D> => {
    const uuid = uuidv4();
    return [
        type,
        { event: type, message_id: uuid, payload: data, timestamp: Date.now() },
    ];
};

export const createResourceEvent = <K extends string, T = undefined>(
    eventName: K,
    resourceUuid: string,
    data: T
) => {
    return {
        event: eventName,
        resourceUuid,
        timestamp: Date.now(),
        data,
    };
};

export type BaseEvent<T = string, D = unknown> = {
    message_id: string;
    type: T;
    data: D;
};

export type ResponseMessage = {
    status: number;
    message: string;
};

export type ResponseEvent = IBaseEvent<
    'response',
    {
        status: number;
        message: string;
    }
>[1];

export const createResponseEvent = (
    messageId: string,
    response: ResponseMessage
) => {
    const ev = createEvent(BaseEvents.Response, response);
    ev[1].message_id = messageId;

    return ev;
};

export type _ResponseEvent = ReturnType<typeof createResponseEvent>[1];

export const createUserEvent = (user: AnimoUserData) => {
    return createEvent(BaseEvents.User, { user });
};

export type UserEvent = ReturnType<typeof createUserEvent>[1];

export const createSendChatMessageEvent = (message: string) => {
    return createEvent(BaseEvents.SendChatMessage, {
        message,
        timestamp: Date.now(),
    });
};

export type SendChatMessageEvent = ReturnType<
    typeof createSendChatMessageEvent
>[1];

export const createChatMessageEvent = (
    user_id: string,
    name: string,
    message: string,
    timestamp: number
) => {
    return createEvent(BaseEvents.ChatMessage, {
        user_id,
        timestamp,
        name,
        message,
    });
};

export type ChatMessageEvent = ReturnType<typeof createChatMessageEvent>[1];

export const createErrorEvent = (code: number, message: string) => {
    return createEvent(BaseEvents.Error, { code, message });
};

export type ErrorEvent = ReturnType<typeof createErrorEvent>[1];

export const createCardUpdateEvent = (card: ICard) => {
    return createEvent(BaseEvents.CardUpdate, { card });
};

export type CardUpdateEvent = ReturnType<typeof createCardUpdateEvent>[1];

export const createCameraChangeEvent = (camera: number) => {
    return createEvent(BaseEvents.CameraChange, { camera });
};

export type CameraChangeEvent = ReturnType<typeof createCameraChangeEvent>[1];

export const createWaitingForCardEvent = () => {
    return createEvent(BaseEvents.WaitingForCard, {});
};

export type WaitingForCardEvent = ReturnType<
    typeof createWaitingForCardEvent
>[1];

export const createWheelUpdateEvent = (wheel: number) => {
    return createEvent(BaseEvents.WheelUpdate, { wheel });
};

export type WheelUpdateEvent = ReturnType<typeof createWheelUpdateEvent>[1];

export const createWaitingForWheelEvent = () => {
    return createEvent(BaseEvents.WaitingForWheel, {});
};

export type WaitingForWheelEvent = ReturnType<
    typeof createWaitingForWheelEvent
>[1];

export const createDuplicateSessionEvent = (userId: string) => {
    return createEvent(BaseEvents.DuplicateSession, { userId });
};

export type DuplicateSessionEvent = ReturnType<
    typeof createDuplicateSessionEvent
>[1];

export const createAuthTokenEvent = (token: string) => {
    return createEvent(BaseEvents.AuthToken, { token });
};

export type AuthTokenEvent = ReturnType<typeof createAuthTokenEvent>[1];

export const createUpdateUserSettingsEvent = (
    gameSettings: Partial<UserGameSettings>
) => {
    return createEvent(BaseEvents.UserSettings, { gameSettings });
};

export type UpdateUserSettingsEvent = ReturnType<
    typeof createUpdateUserSettingsEvent
>[1];

export const createReadyEvent = () => {
    return createEvent(BaseEvents.Ready, {});
};

export type ReadyEvent = ReturnType<typeof createReadyEvent>[1];
export const createChatMessageDeletedEvent = (messageId: string) => {
    return createEvent(BaseEvents.ChatMessageDeleted, { messageId });
};

export type ChatMessageDeletedEvent = ReturnType<
    typeof createChatMessageDeletedEvent
>[1];

export const createDeleteChatMessageEvent = (messageId: string) => {
    return createEvent(BaseEvents.DeleteChatMessage, { messageId });
};

export type DeleteChatMessageEvent = ReturnType<
    typeof createDeleteChatMessageEvent
>[1];

export const createServerInfoEvent = (args: { lobbyUrl: string }) => {
    return createEvent(BaseEvents.ServerInfo, { ...args });
};

export type ServerInfoEvent = ReturnType<typeof createServerInfoEvent>[1];
