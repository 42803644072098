import styled from '@emotion/styled';
import { PopupBoxMobile, WinComponent } from '@roulette/components';
import MobileMenu from '../components/mobile/mobile-menu';
import { Board } from '../components/new-board/board';
import { useEffect, useRef, useState } from 'react';
import { Racetrack } from '../components/racetrack';
import { Timer } from '@roulette/components';
import { useGameContext, useLogicStateContext } from '@roulette/contexts';
import { boardNumbers, RouletteStates } from '@roulette/models';
import { FaMinus, FaPlus } from 'react-icons/fa6';

const PERSPECTIVE_TIMER = 1000;

interface winNumber {
    value: number | null;
    color: string;
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    svg .hover {
        opacity: 0;
    }
`;

const MobileTop = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 1rem;
`;

const MobileMidSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;

    box-sizing: border-box;
`;

const GameBoard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 85%;

    transform: rotate(90deg);

    .timer {
        transform: rotate(-90deg);

        height: 3.6rem;
        width: 3.6rem;
        position: absolute;

        top: 55rem;
        left: -18rem;
    }
`;

const Menus = styled.div`
    height: calc(100% - 8rem); // height of the base-layout footer
    width: 15%;
`;

const BoardContainer = styled.div<{ changePerspective: boolean }>`
    transform: ${({ changePerspective }) =>
        changePerspective
            ? 'scale(0.7)  translateX(15rem)'
            : 'scale(0.9) translateX(0rem)'};
    transform-style: preserve-3d;
    transition: transform ${PERSPECTIVE_TIMER}ms ease-in-out;
    pointer-events: auto;
`;

const RacetrackContainer = styled.div<{ changePerspective: boolean }>`
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;

    transform: ${({ changePerspective }) =>
        changePerspective
            ? 'scale(1) translateX(10rem)'
            : 'scale(1.4) translateX(1rem)'};
    transform-style: preserve-3d;
    transition: transform ${PERSPECTIVE_TIMER}ms ease-in-out;

    .racetrack-counter {
        min-width: 5rem;
        transform: rotate(270deg);

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .counter-sign {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0.5rem;
        border-radius: 100%;
        border: 0.1rem solid var(--roulette-hover);

        padding: 0.2rem;
    }

    .counter {
        font-size: 1.5rem;
        font-weight: 400;
        font-family: 'Chakra Petch', sans-serif;
    }
`;

const Popup = styled.div`
    position: absolute;
    z-index: 1;
    top: 61%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const WinContainer = styled.div`
    height: 15.1rem;
    width: 20.1rem;
    position: absolute;
    z-index: 1;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: none;
`;

export const MobileAppLayout = () => {
    const {
        gameState,
        racetrackAdjacentCounter,
        handleRacetrackAdjacentCounter,
    } = useGameContext();

    const [showRacetrack, setShowRacetrack] = useState(false);

    const [changePerspective, setChangePerspective] = useState(false);

    const [activePopupBox, setActivePopupBox] = useState('');

    const [winNumber, setWinNumber] = useState<winNumber>({
        value: null,
        color: '',
    });

    const endTime = gameState.endTime;

    const popupRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (gameState.ballPosition || gameState.ballPosition === 0) {
            boardNumbers.forEach((bet) => {
                if (bet.value === gameState.ballPosition) {
                    setWinNumber({ value: bet.value, color: bet.color });
                }
            });
        }
    }, [gameState.ballPosition]);

    useEffect(() => {
        if (
            gameState.currentState.previous_state ===
                RouletteStates.FinishedBetting ||
            gameState.currentState.next_state ===
                RouletteStates.WaitingForWheelSpin
        ) {
            setChangePerspective(true);
        }

        if (gameState.currentState.next_state === RouletteStates.GameEnd) {
            setChangePerspective(false);
        }
    }, [gameState]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                popupRef.current &&
                !popupRef.current.contains(event.target as Node)
            ) {
                setActivePopupBox('');
            }
        };

        if (activePopupBox) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [activePopupBox]);

    return (
        <Container>
            <MobileTop></MobileTop>

            <MobileMidSection>
                <div className="video"></div>
                {activePopupBox && (
                    <Popup ref={popupRef}>
                        <PopupBoxMobile
                            type={activePopupBox}
                            setActivePopupBox={setActivePopupBox}
                        />
                    </Popup>
                )}

                {(gameState.ballPosition || gameState.ballPosition === 0) &&
                    (winNumber.value || winNumber.value === 0) &&
                    winNumber.color && (
                        <WinContainer>
                            <WinComponent
                                totalWon={gameState.roundInfo?.totalWon || 0}
                                winNumber={winNumber.value}
                            />
                        </WinContainer>
                    )}

                <GameBoard>
                    <div className="timer">
                        {' '}
                        {gameState.roundInfo?.roundId && (
                            <Timer
                                endTime={endTime}
                                duration={gameState.gameInfo?.playerBetDuration}
                                isMobile
                            />
                        )}
                    </div>

                    {showRacetrack ? (
                        <RacetrackContainer
                            changePerspective={changePerspective}
                        >
                            <div className="racetrack-counter">
                                <FaMinus
                                    className="counter-sign"
                                    onClick={() =>
                                        handleRacetrackAdjacentCounter(
                                            'decrement'
                                        )
                                    }
                                    color="var(--roulette-hover)"
                                />
                                <span className="counter">
                                    {racetrackAdjacentCounter}
                                </span>
                                <FaPlus
                                    className="counter-sign"
                                    onClick={() =>
                                        handleRacetrackAdjacentCounter(
                                            'increment'
                                        )
                                    }
                                    color="var(--roulette-hover)"
                                />
                            </div>
                            <Racetrack
                                showRacetrack={showRacetrack}
                                isMobile={true}
                            />
                        </RacetrackContainer>
                    ) : (
                        <BoardContainer changePerspective={changePerspective}>
                            <Board isMobile={true} />
                        </BoardContainer>
                    )}
                </GameBoard>
                <Menus>
                    <MobileMenu
                        showRacetrack={showRacetrack}
                        setShowRacetrack={setShowRacetrack}
                        changePerspective={changePerspective}
                        activePopupBox={activePopupBox}
                        setActivePopupBox={setActivePopupBox}
                    />
                </Menus>
            </MobileMidSection>
        </Container>
    );
};
