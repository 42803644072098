import styled from '@emotion/styled';
import { Button2 } from '../buttons/button2';

const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    width: 100%;
    /* background-color: fuchsia; */

    & > *:first-of-type {
        margin-left: 0.5rem;
    }
    & > *:last-of-type {
        margin-right: ${({ isMobile }) => (isMobile ? '1.6rem' : '3.2rem')};
    }
`;

interface RightButtonProps {
    isVertical: boolean;
    lobbyUrl: string;
    enableLobby?: boolean;
    enableTable?: boolean;
}

export const RightButtons = ({
    isVertical,
    lobbyUrl,
    enableLobby,
    enableTable,
}: RightButtonProps) => {
    return (
        <Container isMobile={isVertical}>
            {enableTable && (
                <Button2
                    title="+Table"
                    primary="var(--color-primary)"
                    onClick={() => {
                        console.log('Clicked the Table+ Button!');
                    }}
                    isVertical={isVertical}
                />
            )}

            {enableLobby && (
                <Button2
                    title="+LOBBY"
                    primary="var(--color-primary)"
                    onClick={() => {
                        window.parent.location.replace(lobbyUrl);
                    }}
                    isVertical={isVertical}
                />
            )}
        </Container>
    );
};
