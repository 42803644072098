import { useAspectRatioHandler } from '@shared/ui/hooks';

import rouletteDesktopImage from '@shared/assets/images/UI_DEMO_ROULETTE_DESKTOP_CAMERA_0.png';
import rouletteMobileImage from '@shared/assets/images/UI_DEMO_ROULETTE_MOBILE.png';

import { DesktopAppLayout } from './app-layout-desktop';
import { MobileAppLayout } from './app-layout-mobile';

import { useGameContext, useLogicStateContext } from '@roulette/contexts';
import { BaseGameLayout } from '@shared/ui/layouts';
import { useCameraView } from '@roulette/contexts';
import { createCurrencyConverter } from '@shared/currency-convertor';

import ambienceSound from '@shared/assets/audio/ambience-2.mp3';

// Roulette Specific Styles
import '../../main.css';
import { useEffect } from 'react';
import { useChipContext } from '@shared/ui/contexts';

const currencyConverter = createCurrencyConverter();

export const AppLayout = () => {
    const { isVertical } = useAspectRatioHandler();

    const { gameState } = useGameContext();

    const { totalCredits, baseBetTotal } = useLogicStateContext();
    const totalBets = baseBetTotal || 0;
    const baseBet = gameState.roundInfo?.totalWon
        ? gameState.roundInfo.totalWon
        : totalBets;

    const { image } = useCameraView();

    const { setConversionRate } = useChipContext();

    useEffect(() => {
        if (gameState.user?.currency) {
            // TODO: Change base rate to use games base currency
            setConversionRate(
                currencyConverter.convert('USD', gameState.user?.currency, 1)
            );
        }
    }, [setConversionRate, gameState.user]);

    return (
        gameState &&
        gameState.gameInfo && (
            <BaseGameLayout
                isVertical={isVertical}
                backgroundImage={
                    isVertical
                        ? image || rouletteMobileImage
                        : image || rouletteDesktopImage
                }
                gameName={gameState?.gameInfo.name}
                minBet={gameState.gameInfo.minBet}
                maxBet={gameState.gameInfo.maxBet}
                userId={gameState.user?.id}
                baseBetTotal={baseBet} //{baseBetTotal} // TODO this
                sideBetTotals={0} //{sideBetTotals} // and this add together to make totalWon // sidebet only comes from baccarrat? should be optional?
                user={{
                    currency: gameState.user?.currency || 'USD',
                    balance: totalCredits ?? 0,
                }}
                sound={ambienceSound}
                videoStream={gameState.gameInfo?.streamData}
            >
                {isVertical ? <MobileAppLayout /> : <DesktopAppLayout />}
            </BaseGameLayout>
        )
    );
};
