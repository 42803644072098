import { ReactElement, ReactNode } from 'react';

import styled from '@emotion/styled';

const Container = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 4rem;
    width: 4rem;

    color: var(--color-primary);

    /* background: rgba( var(--color-theme-black-opacity), var(--color-theme-opacity) ); */
    background: rgba(
        var(--color-black-100-opacity),
        var(--color-theme-opacity)
    );

    border: 0.1rem solid
        rgba(var(--color-primary-opacity), var(--color-theme-opacity));
    border-radius: 1rem;

    user-select: none;
    cursor: pointer;

    svg {
        height: 50%;
    }

    &.clicked {
        svg {
            fill: var(--color-grey-20);
            stroke: var(--color-grey-20);
            height: 2rem;
        }
    }

    &.disabled {
        cursor: default;
        svg {
            fill: rgba(var(--color-white-100-opacity), 0.74);
            stroke: rgba(var(--color-black-100-opacity), 0.4);
        }
    }
`;

interface SettingsButtonProps {
    icon: ReactElement | null;
    disabled?: boolean;
    state?: boolean;
    onBlur?: React.FocusEventHandler<HTMLButtonElement>;
    onClick?: () => void;
}

export const SettingButton = ({
    icon = null,
    disabled = false,
    state = false,
    onBlur,
    onClick,
}: SettingsButtonProps) => {
    return (
        <Container
            className={disabled ? 'disabled' : state ? 'clicked' : ''}
            onClick={disabled ? undefined : onClick}
            onBlur={onBlur}
        >
            {icon && (icon as unknown as ReactNode)}
        </Container>
    );
};
