import styled from '@emotion/styled';
import { formatCurrency } from '@shared/utils';

import { useEffect, useState } from 'react';

const Container = styled.div<{
    borderColour: string;
    fontColour: string;
    width: number;
    height: number;
}>`
    display: flex;
    flex-direction: column;
    width: ${({ width }) => `${width}rem`};
    height: ${({ height }) => `${height}rem`};

    margin-left: 2rem;
    margin-bottom: 2rem;

    overflow: hidden;

    .inner-container {
        width: ${({ width }) => `${width}rem`};
        height: ${({ height }) => `${height}rem`};

        &.hidden {
            visibility: hidden;
        }
    }

    .scroll {
        animation: scroll-up 10s linear 1;
    }

    @keyframes scroll-up {
        from {
            transform: translateY(${({ height }) => `${height + 2}rem`});
        }
        to {
            transform: translateY(-100%);
        }
    }

    .winner-title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 4rem;

        font-family: 'Chakra Petch', sans-serif;
        font-size: 2rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var(--chat-text-color);

        box-sizing: border-box;

        border-bottom: var(--border-width) solid transparent;
        border-bottom-color: ${({ borderColour }) => borderColour};

        .winners-number {
            text-transform: uppercase;
            margin-right: 1rem;
        }
        .amount {
            color: var(--baccarat-gold-color);
        }
    }
    .winning-names {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 90%;

        box-sizing: border-box;
        margin-top: 1rem;

        .winner {
            display: flex;
            flex-direction: row;

            justify-content: start;
            align-items: center;

            margin-left: 2rem;
            margin-bottom: 0.8rem;

            font-size: 1.8rem;

            .name {
                text-transform: uppercase;
            }

            .amount {
                color: var(--baccarat-gold-color);
                margin-right: 1rem;
            }
        }
    }
`;

interface Payouts {
    name: string;
    totalPayout: number;
}

export const RoundWinners = ({
    borderColour,
    fontColour,
    width,
    height,
    data,
    totalPayout,
    currency,
}: {
    borderColour: string;
    fontColour: string;
    width: number;
    height: number;
    data?: Payouts[];
    totalPayout?: number;
    currency: string;
}) => {
    const [roundWinners, setRoundWinners] = useState<Payouts[]>([]);

    useEffect(() => {
        if (data && data[0]?.totalPayout) {
            setRoundWinners(data);
        }
    }, [data]);

    const listElement = document.getElementById('scrolling-container');

    if (roundWinners.length >= 1) {
        listElement?.classList.remove('hidden');
        listElement?.classList.add('scroll');
    }

    if (listElement) {
        listElement.onanimationend = () => {
            listElement?.classList.add('hidden');
            setRoundWinners([]);
            listElement?.classList.remove('scroll');
        };
    }

    return (
        <Container
            borderColour={borderColour}
            fontColour={fontColour}
            width={width}
            height={height}
        >
            <div className="inner-container" id="scrolling-container">
                {roundWinners.length > 0 && (
                    <>
                        <div className="winner-title">
                            <span className="winners-number">{`${
                                roundWinners.length
                            } Winner${
                                roundWinners.length > 1 ? 's' : ''
                            }: `}</span>
                            <span className="amount">{`${formatCurrency(
                                roundWinners.reduce(
                                    (acc, curr) => acc + curr.totalPayout,
                                    0
                                ),
                                currency
                            )}`}</span>
                        </div>
                        <div className="winning-names">
                            {roundWinners.map(
                                ({ totalPayout, name }, index) => {
                                    return (
                                        <div
                                            className="winner"
                                            key={index + '-' + name}
                                        >
                                            <span className="amount">{`${formatCurrency(
                                                totalPayout,
                                                currency
                                            )}`}</span>
                                            <span className="name">{name}</span>{' '}
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    </>
                )}
            </div>
        </Container>
    );
};
