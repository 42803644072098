import { sql } from 'drizzle-orm';
import {
    boolean,
    pgTable,
    text,
    timestamp,
    uuid,
    varchar,
} from 'drizzle-orm/pg-core';
import { createInsertSchema, createSelectSchema } from 'drizzle-zod';

export const services = pgTable('services', {
    id: uuid('id').defaultRandom().primaryKey(),
    internalId: varchar('internal_id', { length: 256 })
        .unique()
        .default(sql`gen_random_uuid()`),
    name: varchar('name', { length: 256 }).unique().notNull(),
    type: text('type').notNull(),
    online: boolean('online').default(false),
    linked: boolean('linked').default(false),
    linkedId: uuid('linked_id'),
    registered: boolean('registered').default(false),
    createdAt: timestamp('created_at').defaultNow().notNull(),
    updatedAt: timestamp('updated_at')
        .defaultNow()
        .$onUpdate(() => new Date())
        .notNull(),
});

export const insertServiceSchema = createInsertSchema(services);
export const selectServiceSchema = createSelectSchema(services);

export type NewService = typeof services.$inferInsert;
export type Service = typeof services.$inferSelect;
