import React from 'react';
import styled from '@emotion/styled';

import { Chip } from '@shared/ui/components/chips';

interface ChipAnimationProps {
    position: { x: number; y: number };
    value: number;
    isMobile: boolean;
}

const ChipContainer = styled.div<{ isMobile: boolean }>`
    position: absolute;
    transition: top 0.5s ease, left 0.5s ease, opacity 0.5s ease;

    transform: ${({ isMobile }) => (isMobile ? 'rotate(90deg)' : '')};
`;

export const ChipAnimation: React.FC<ChipAnimationProps> = ({
    position,
    value,
    isMobile,
}) => {
    return (
        <ChipContainer
            style={{
                left: `${position.x}rem`,
                top: `${position.y}rem`,
                opacity: 1,
            }}
            isMobile={isMobile}
        >
            <Chip value={value} size={`x-small`} />
        </ChipContainer>
    );
};
