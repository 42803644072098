import {
    useContext,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
    ReactNode,
} from 'react';

import { useWebsocketContext } from '@shared/ui/contexts';

import {
    RouletteStates,
    PlayerBaseBet,
    ColumnBet,
    UserBet,
    PlayerPayout,
    RouletteStats,
} from '@roulette/models';
import { useBetHook } from '@roulette/hooks';

import { useGameContext } from './game-context';

interface LogicStateContextProps {
    totalCredits: number;
    canRepeat: boolean;
    activeChip: number | null;
    isReady: boolean;
    audioRef: React.RefObject<HTMLAudioElement> | null;
    reducedBets: UserBet[];
    betHistory: UserBet[];
    baseBetTotal: number;
    resultHistory: number[];
    payoutData: PlayerPayout[];
    range: number;
    lastBet: UserBet[];
    gameStats: ReturnType<typeof RouletteStats>;
}

interface LogicActionContextProps {
    handlePlayerBet: (
        type: PlayerBaseBet | ColumnBet,
        chosenNumber: number[],
        neighbourValues?: number[]
    ) => void;
    handleVideoReady: () => void;
    setActiveChip: React.Dispatch<React.SetStateAction<number | null>>;
    undoBet: () => void;
    repeatLast: () => void;
    doubleBets: () => void;
    handleRange: (value: number) => void;
}

/**
 * Separate contexts for state and action handlers
 */
const LogicStateContext = createContext<LogicStateContextProps | undefined>(
    undefined
);
const LogicActionContext = createContext<LogicActionContextProps | undefined>(
    undefined
);

interface LogicProviderProps {
    children: ReactNode;
}

const LogicContextProvider = (props: LogicProviderProps) => {
    const { children } = props;

    const { gameState, handlePlayerBets, racetrackAdjacentCounter } =
        useGameContext();

    const { connected } = useWebsocketContext();

    const {
        increaseBet,
        handleRacetrackBet,
        clearBetHistory,
        undoBet,
        storeLastBet,
        repeatLast,
        doubleBets,
        betHistory,
        reducedBets,
        baseBetTotal,
        lastBet,
    } = useBetHook();

    const [activeChip, setActiveChip] = useState<number | null>(null);

    const [videoReady, setVideoReady] = useState(false);

    const [isReady, setIsReady] = useState(false);

    const audioRef = useRef<HTMLAudioElement>(null);

    const [payoutData, setPayoutData] = useState<PlayerPayout[]>([]);

    const [range, setRange] = useState(500);

    const handleRange = (value: number) => {
        setRange(value);
    };

    useEffect(() => {
        if (gameState.roundInfo && gameState.roundInfo.payouts?.length) {
            const validPayouts = gameState.roundInfo.payouts.filter(
                (payout) => payout.totalPayout > 0
            );

            setPayoutData(validPayouts);
        }
    }, [gameState.roundInfo]);

    useEffect(() => {
        if (gameState.currentState.next_state === RouletteStates.GameEnd) {
            storeLastBet(true);
        }
    }, [gameState.currentState.next_state, storeLastBet]);

    useEffect(() => {
        if (
            gameState.currentState.next_state ===
                RouletteStates.WaitingForBets ||
            gameState.currentState.next_state ===
                RouletteStates.WaitingForPlayers
        ) {
            handlePlayerBets(reducedBets);
        }
    }, [reducedBets, handlePlayerBets, gameState.currentState.next_state]);

    useEffect(() => {
        if (audioRef.current) audioRef.current.volume = 0.6;
    }, []);

    useEffect(() => {
        if (connected && gameState.gameInfo) {
            setIsReady(gameState.gameInfo.streamData ? videoReady : true);
        }
    }, [connected, gameState.gameInfo, videoReady, gameState.roundInfo]);

    useEffect(() => {
        if (gameState.gameInfo?.minBet)
            setActiveChip(gameState.gameInfo.minBet);
    }, [gameState.gameInfo?.minBet]);

    useEffect(() => {
        const clickListener = () => {
            if (audioRef.current) audioRef.current.play();
        };

        window.addEventListener('click', clickListener);
        return () => {
            window.removeEventListener('click', clickListener);
        };
    }, []);

    useEffect(() => {
        if (
            gameState.currentState.previous_state === RouletteStates.GameEnd &&
            gameState.currentState.next_state === RouletteStates.WaitingForBets
        ) {
            clearBetHistory();
        }
    }, [
        gameState.currentState.previous_state,
        gameState.currentState.next_state,
        clearBetHistory,
    ]);

    /**
     *
     */
    const totalCredits = useMemo(() => {
        if (
            gameState.currentState.next_state === RouletteStates.WaitingForBets
        ) {
            if (baseBetTotal) {
                return (gameState.user?.balance || 0) - baseBetTotal;
            }
        }
        return gameState.user?.balance || 0;
    }, [gameState.user, gameState.currentState, baseBetTotal]);

    const handlePlayerBet = useCallback(
        (
            type: PlayerBaseBet | ColumnBet,
            chosenNumber: number[],
            neighbourValues?: number[]
        ) => {
            if (activeChip && activeChip > 0) {
                if (activeChip > totalCredits) {
                    return;
                }
                if (
                    type === PlayerBaseBet.STRAIGHT_UP ||
                    type === PlayerBaseBet.RACETRACK_STRAIGHT_UP ||
                    type === PlayerBaseBet.SPLIT ||
                    type === PlayerBaseBet.CORNER ||
                    type === PlayerBaseBet.FIRST_TWELVE ||
                    type === PlayerBaseBet.SECOND_TWELVE ||
                    type === PlayerBaseBet.THIRD_TWELVE ||
                    type === PlayerBaseBet.FIRST_COLUMN ||
                    type === PlayerBaseBet.SECOND_COLUMN ||
                    type === PlayerBaseBet.THIRD_COLUMN ||
                    type === PlayerBaseBet.ZERO ||
                    type === PlayerBaseBet.EVEN ||
                    type === PlayerBaseBet.ODD ||
                    type === PlayerBaseBet.RED ||
                    type === PlayerBaseBet.BLACK ||
                    type === PlayerBaseBet.LOW ||
                    type === PlayerBaseBet.HIGH ||
                    type === PlayerBaseBet.STREET ||
                    type === PlayerBaseBet.DOUBLE_STREET
                ) {
                    // Handle Bets
                    if (
                        baseBetTotal + activeChip >
                        (gameState.gameInfo?.maxBet || 0)
                    ) {
                        return;
                    }
                    if (
                        baseBetTotal + activeChip <
                        (gameState.gameInfo?.minBet || 0)
                    ) {
                        return;
                    }
                    if (
                        type.includes(PlayerBaseBet.RACETRACK_STRAIGHT_UP) &&
                        baseBetTotal +
                            activeChip * (racetrackAdjacentCounter * 3) >
                            (gameState.gameInfo?.maxBet || 0)
                    ) {
                        return;
                    }
                    increaseBet(
                        type,
                        activeChip,
                        chosenNumber,
                        neighbourValues
                    );
                } else if (
                    type === PlayerBaseBet.VOISINS_BET_SPLITS ||
                    type === PlayerBaseBet.VOISINS_BET_TRIO ||
                    type === PlayerBaseBet.VOISINS_BET_CORNER ||
                    type === PlayerBaseBet.ORPHELINS_BET_STRAIGHT ||
                    type === PlayerBaseBet.ORPHELINS_BET_SPLITS ||
                    type === PlayerBaseBet.TIERS_BET_SPLIT ||
                    type === PlayerBaseBet.JEU_ZERO_SPLIT ||
                    type === PlayerBaseBet.JEU_ZERO_STRAIGHT
                ) {
                    const multipliers = {
                        voisins: 7,
                        orphelins: 5,
                        tiers: 6,
                        jeu_zero: 4,
                    };

                    const maxBet = gameState.gameInfo?.maxBet || 0;
                    const minBet = gameState.gameInfo?.minBet || 0;
                    let multiplier = 1;

                    if (type.includes('voisins')) {
                        multiplier = multipliers.voisins;
                    } else if (type.includes('orphelins')) {
                        multiplier = multipliers.orphelins;
                    } else if (type.includes('tiers')) {
                        multiplier = multipliers.tiers;
                    } else if (type.includes('jeu_zero')) {
                        multiplier = multipliers.jeu_zero;
                    }

                    const totalBet = baseBetTotal + activeChip * multiplier;

                    if (totalBet > maxBet || totalBet < minBet) {
                        return;
                    }
                    handleRacetrackBet(type, activeChip, chosenNumber);
                }
            }
        },
        [
            baseBetTotal,
            activeChip,
            increaseBet,
            handleRacetrackBet,
            totalCredits,
            gameState.gameInfo?.maxBet,
            gameState.gameInfo?.minBet,
            racetrackAdjacentCounter,
        ]
    );

    /**
     *
     */
    const canRepeat = useMemo(() => {
        if (lastBet && lastBet.length === 0) return false;
        const totalCredits = gameState.user?.balance || 0;
        if (totalCredits <= 0) return false;

        const totalLast =
            lastBet &&
            lastBet.reduce((acc, cur) => {
                return cur && cur.amount ? acc + cur.amount : acc;
            }, 0);

        if (totalLast && totalLast > totalCredits) return false;

        return true;
    }, [lastBet, gameState.user]);

    const handleVideoReady = useCallback(() => {
        setVideoReady(true);
    }, []);

    const gameStats = useMemo(
        () => RouletteStats(gameState.resultHistory),
        [gameState.resultHistory]
    );

    /**
     * These values change and are memoized for optimisation
     */
    const stateValues = useMemo(() => {
        return {
            totalCredits,
            canRepeat,
            activeChip:
                gameState.currentState.next_state ===
                RouletteStates.WaitingForBets
                    ? activeChip
                    : null,
            isReady,
            audioRef,
            reducedBets,
            betHistory,
            baseBetTotal,
            resultHistory: gameState.resultHistory,
            payoutData,
            range,
            lastBet,
            gameStats,
        };
    }, [
        totalCredits,
        canRepeat,
        activeChip,
        isReady,
        audioRef,
        reducedBets,
        betHistory,
        baseBetTotal,
        gameState.resultHistory,
        gameState.currentState.next_state,
        payoutData,
        range,
        lastBet,
        gameStats,
    ]);

    /**
     * These values don't change
     */
    const actionHandlers = {
        handlePlayerBet,
        handleVideoReady,
        setActiveChip,
        undoBet,
        repeatLast,
        doubleBets,
        handleRange,
    };

    return (
        <LogicActionContext.Provider value={actionHandlers}>
            <LogicStateContext.Provider value={stateValues}>
                {children}
            </LogicStateContext.Provider>
        </LogicActionContext.Provider>
    );
};

const useLogicStateContext = (): LogicStateContextProps => {
    const context = useContext(LogicStateContext);
    if (!context) {
        throw new Error('LogicStateContext error');
    }
    return context;
};

const useLogicActionContext = (): LogicActionContextProps => {
    const context = useContext(LogicActionContext);
    if (!context) {
        throw new Error('LogicActionContext error');
    }
    return context;
};

export { LogicContextProvider, useLogicStateContext, useLogicActionContext };
