import { useCallback, useMemo, useState } from 'react';
import {
    PlayerBaseBet,
    UserBet,
    orphelinsBetTypes,
    tiersBetTypes,
    voisinsBetTypes,
} from '@roulette/models';

const uuid = () => {
    const letters = 'abcdefghijklmnopqrstuvwxyz0123456789-';
    const word = [];
    for (let i = 0; i < 30; i += 1) {
        word.push(letters[Math.floor(Math.random() * letters.length)]);
    }

    return word.join('');
};

export interface BetHook {
    increaseBet: (
        type: PlayerBaseBet,
        amount: number,
        betValues: number[],
        neighbourValues?: number[]
    ) => void;
    handleRacetrackBet: (
        type: PlayerBaseBet,
        amount: number,
        chosenNumber: number[]
    ) => void;
    undoBet: () => void;
    clearBetHistory: () => void;
    storeLastBet: (clearHistory: boolean) => void;
    repeatLast: () => void;
    doubleBets: () => void;
    betHistory: UserBet[];
    baseBetTotal: number;
    lastBet: UserBet[];
    // Bets grouped by type
    reducedBets: UserBet[];
}

const reduceBets = (bets: UserBet[]) => {
    return bets.reduce((acc: UserBet[], cur) => {
        const previous = acc.find(
            (p) => p.betValues === cur.betValues && p.type === cur.type
        );

        if (!previous) {
            acc.push({ ...cur, uuid: uuid() });
        } else {
            previous.amount += cur.amount;
        }

        return acc;
    }, []);
};

const findExistingBet = (compare: UserBet) => (bet: UserBet) => {
    if (bet.type === compare.type) {
        if (Array.isArray(bet.betValues) && Array.isArray(compare.betValues)) {
            if (
                [...bet.betValues].sort().toString() ===
                [...compare.betValues].sort().toString()
            ) {
                return true;
            }
        }
    }
    return false;
};

const groupBets = (bets: UserBet[]) => {
    return bets.reduce((acc: UserBet[], cur) => {
        const existing = acc.find(findExistingBet(cur));

        if (existing) {
            existing.amount += cur.amount;
            return acc;
        }

        return [...acc, { ...cur }];
    }, []);
};

export const useBetHook = (): BetHook => {
    const [betHistory, setBetHistory] = useState<UserBet[]>([]);

    const [lastBet, setLastBet] = useState<UserBet[]>([]);

    const increaseBet = useCallback(
        (
            type: PlayerBaseBet,
            amount: number,
            betValues: number[],
            neighbourValues?: number[]
        ) => {
            setBetHistory((bets) => {
                return [
                    ...bets,
                    { uuid: uuid(), type, amount, betValues, neighbourValues },
                ];
            });
        },
        []
    );

    const handleRacetrackBet = useCallback(
        (type: PlayerBaseBet, amount: number, betValues: number[]) => {
            setBetHistory((bets) => {
                return [
                    ...bets,
                    {
                        uuid: uuid(),
                        type,
                        amount:
                            (betValues && betValues.length === 3) ||
                            (betValues && betValues.length === 4)
                                ? amount * 2
                                : amount,
                        betValues,
                    },
                ];
            });
        },
        []
    );

    const clearBetHistory = useCallback(() => {
        setBetHistory([]);
    }, []);

    const baseBetTotal = useMemo(
        () =>
            betHistory.reduce((acc, bet) => {
                return bet && bet.amount ? acc + bet.amount : acc;
            }, 0),
        [betHistory]
    );

    const undoBet = useCallback(() => {
        setBetHistory((bets) => {
            if (bets.length === 0) return [];

            const updatedBets = [...bets];

            const lastBet = updatedBets[updatedBets.length - 1];

            const count = lastBet?.neighbourValues?.length;

            const last = bets[bets.length - 1];

            if (last.doubleId) {
                return bets.filter((b) => b.doubleId !== last.doubleId);
            }

            const voisinsBet =
                lastBet.type === PlayerBaseBet.VOISINS_BET_SPLITS ||
                lastBet.type === PlayerBaseBet.VOISINS_BET_CORNER ||
                (lastBet.type === PlayerBaseBet.VOISINS_BET_TRIO &&
                    updatedBets.length);

            const orphelinsBet =
                lastBet.type === PlayerBaseBet.ORPHELINS_BET_SPLITS ||
                (lastBet.type === PlayerBaseBet.ORPHELINS_BET_STRAIGHT &&
                    updatedBets.length);

            const tiersBet =
                lastBet.type === PlayerBaseBet.TIERS_BET_SPLIT &&
                updatedBets.length;

            if (voisinsBet) {
                return updatedBets.slice(0, -voisinsBetTypes.length);
            }

            if (orphelinsBet) {
                return updatedBets.slice(0, -orphelinsBetTypes.length);
            }

            if (tiersBet) {
                return updatedBets.slice(0, -tiersBetTypes.length);
            }

            if (count) {
                return updatedBets.slice(0, -count);
            }

            return updatedBets.slice(0, -1);
        });
    }, []);

    const storeLastBet = useCallback(
        (clearHistory: boolean) => {
            if (betHistory.length) {
                setBetHistory((prev) => {
                    const history = reduceBets(prev);
                    setLastBet([...history]);
                    return clearHistory ? [] : prev;
                });
            }
        },
        [betHistory.length]
    );

    const repeatLast = useCallback(() => {
        const doubleId = uuid();
        setBetHistory(lastBet.map((b) => ({ ...b, doubleId })));
        setLastBet([...lastBet]);
    }, [lastBet]);

    const doubleBets = useCallback(() => {
        setBetHistory((prev) => {
            const doubleId = uuid();
            const reduced = reduceBets(prev).map((m) => ({ ...m, doubleId }));
            return [...prev, ...reduced];
        });
    }, []);

    const reducedBets = useMemo<UserBet[]>(
        () => groupBets(betHistory),
        [betHistory]
    );

    return {
        increaseBet,
        handleRacetrackBet,
        clearBetHistory,
        undoBet,
        storeLastBet,
        repeatLast,
        doubleBets,
        betHistory,
        baseBetTotal,
        lastBet,
        reducedBets,
    };
};
