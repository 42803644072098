import styled from '@emotion/styled';

import { PopupHeading } from './popup-box/popup-heading';
import { SpecialPopupBox } from './popup-box/specials-popup-box';
import { LatestResultPopupBox } from './popup-box/latest-results-popup-box';
import { ChartPopupBox } from './popup-box/chart-popup-box';
import { HotNColdPopupBox } from './popup-box/hot-n-cold-popup-box';
import { useMemo } from 'react';
import { PopupFooter } from './popup-box/popup-footer';

const Container = styled.div`
    height: 27.1rem;
    width: 40rem;

    border-radius: 1.9rem 0px 0px 1.9rem;
    border-bottom: 0.1rem solid var(--color-primary);
    border-left: 0.1rem solid var(--color-primary);
    background: rgba(0, 0, 0, 0.7);

    .render-content {
        width: 34.2rem;
        height: 17.8rem;

        overflow-y: auto;
        overflow-x: hidden;

        pointer-events: auto;

        margin-left: 1.6rem;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        padding-right: 2rem;
    }

    .latest-results::after {
        content: '';
        position: absolute;
        bottom: 20rem;
        left: 17rem;
        right: 0;
        height: 4rem;
        background: linear-gradient(to top, #595959, transparent);
        filter: blur(0.8rem);
        pointer-events: none;
    }

    /* Custom scrollbar styles */
    .scrollbar::-webkit-scrollbar {
        width: 0.3rem;
        background: #0d0d0d;
        -webkit-border-radius: 1rem;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #e6e6e6;
        -webkit-border-radius: 1rem;
        cursor: pointer;
    }
`;

interface Props {
    type: string;
    setActivePopupBox: (string: string) => void;
}

export const PopupBox = ({ type, setActivePopupBox }: Props) => {
    const renderContent = useMemo(() => {
        switch (type) {
            case 'specials':
                return <SpecialPopupBox />;
            case 'latest-results':
                return <LatestResultPopupBox />;
            case 'charts':
                return <ChartPopupBox />;
            case 'hot-&-cold':
                return <HotNColdPopupBox />;
            default:
                return null;
        }
    }, [type]);

    return (
        <Container>
            <PopupHeading title={type} setActivePopupBox={setActivePopupBox} />
            <div className={`render-content scrollbar ${type}`}>
                {renderContent}
            </div>
            <PopupFooter title={type} setActivePopupBox={setActivePopupBox} />
        </Container>
    );
};
