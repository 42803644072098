import styled from '@emotion/styled';
import clsx from 'clsx';
import { ReactElement, useCallback } from 'react';

import ChipReverse from '@shared/assets/audio/single-chip-reverse.mp3';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    height: 3.5rem;
    width: 7.8rem;

    user-select: none;
    pointer-events: auto;
    cursor: pointer;

    background: var(--side-button-background);

    backdrop-filter: blur(20px);
    border-top: 0.08rem solid var(--stroke-side, #555);
    border-radius: 0.5rem;
    align-content: center;

    :hover {
        border-color: var(--primary-border-color);
    }

    .text {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.69;
        color: var(--chat-text-color);

        text-transform: uppercase;
        font-family: 'Chakra Petch', sans-serif;
    }

    svg {
        height: 3rem;
        width: 5rem;

        color: var(--chat-text-color);
    }

    &.disabled {
        pointer-events: none;
        cursor: none;

        border-color: var(--button-disabled-bg-color);

        svg {
            color: var(--border-disabled-color);
        }

        .text {
            color: var(--border-disabled-color);
        }
    }
`;

const CHIP_SOUNDS = [ChipReverse];

export const ChipRowButton = ({
    onClickHandler,
    disabled,
    text,
    svg,
    muted = true,
    volume = 0,
}: {
    onClickHandler: () => void;
    disabled: boolean;
    text?: string;
    svg?: ReactElement;
    muted?: boolean;
    volume?: number;
}) => {
    const handleClick = useCallback(() => {
        onClickHandler();

        if (!muted) {
            const sound = new Audio(CHIP_SOUNDS[0]);
            sound.volume = 0.4 * volume;
            sound.play();
        }
    }, [volume, muted, onClickHandler]);

    return (
        <Container
            onClick={handleClick}
            className={clsx(disabled && 'disabled')}
        >
            {text && <div className="text">{text}</div>}
            {svg && svg}
        </Container>
    );
};
