import { SocketError } from '@shared/events';
import { TableLimitError } from './table-limit-error';
import { ErrorFallback } from './error-fallback';
import { useSendGlobalMessage, GlobalMessage } from '@shared/ui/contexts';

export const ErrorFallbackHandler = ({ error }: { error: SocketError }) => {
    const postMessage = useSendGlobalMessage();

    const resetErrorBoundary = () => {
        console.log('TODO: Reset Error Boundary');
    };

    const handleCloseGame = () => {
        postMessage({
            message: GlobalMessage.CLOSE_IFRAME,
        });
    };

    if (error.data?.code === 403) {
        return TableLimitError(error, handleCloseGame);
    }

    return ErrorFallback({ error, resetErrorBoundary });
};
