import { keyframes } from '@emotion/react';

export const slideMenusIn = keyframes`
  from {
    transform: translateY(-100%) rotate(90deg);
  }
  to {
    transform: translateY(0) rotate(90deg);
  }
`;

export const slideMenusOut = keyframes`
  from {
    transform: translateY(0) rotate(90deg);
  }
  to {
    transform: translateY(-100%) rotate(90deg);
  }
`;
