import styled from '@emotion/styled';
import { Accordion } from '../accordion';
import { useGameSettings } from '@shared/ui/contexts';

const Container = styled.div`
    height: 85%;
    overflow-y: auto;

    p {
        padding-bottom: 1.4rem;
    }
`;

export const InfoSettings = () => {
    const { gameInfo } = useGameSettings();

    return (
        <Container className="custom-scrollbar">
            {gameInfo.map((item, index) => (
                <Accordion key={index} title={item.title}>
                    {item.content}
                </Accordion>
            ))}
        </Container>
    );
};
