import styled from '@emotion/styled';

export interface ErrorFallbackProps {
    error: Error;
    resetErrorBoundary: () => void;
}

const Error = styled.div`
    background-color: #f24822;
    color: white;

    padding: 2rem;

    border-radius: 0.6rem;

    box-sizing: border-box;

    margin: 4rem;

    h2 {
        font-size: 2.4rem;
    }

    pre {
        border: 0.1rem solid white;
        border-radius: 0.6rem;

        padding: 0.6rem;
        margin-top: 1rem;
    }

    button {
        border: 0.1rem solid white;
        background: none;
        border-radius: 0.4rem;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

export const ErrorFallback = ({
    error,
    resetErrorBoundary,
}: ErrorFallbackProps) => {
    return (
        <Error>
            <div className="header">
                <button onClick={resetErrorBoundary}>x</button>
            </div>
            <h2>Uncaught {error.constructor.name}</h2>
            <div>
                <p>{error.message}</p>
            </div>
            <pre>
                <code>{error.stack}</code>
            </pre>
        </Error>
    );
};
