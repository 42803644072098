// Define the enum for the currency codes
export enum CURRENCY {
    EUR = 'EUR',
    USD = 'USD',
    VND = 'VND',
    TRY = 'TRY',
    RUB = 'RUB',
    PLN = 'PLN',
    PHP = 'PHP',
    PEN = 'PEN',
    NGN = 'NGN',
    MXN = 'MXN',
    KRW = 'KRW',
    JPY = 'JPY',
    INR = 'INR',
    IDR = 'IDR',
    DKK = 'DKK',
    CNY = 'CNY',
    CLP = 'CLP',
    CAD = 'CAD',
    BRL = 'BRL',
    ARS = 'ARS',
}

// Define an object to map the currency enum to their values
export const currencyValues: Record<CURRENCY, number> = {
    [CURRENCY.EUR]: 1,
    [CURRENCY.USD]: 1,
    [CURRENCY.VND]: 20000,
    [CURRENCY.TRY]: 20,
    [CURRENCY.RUB]: 100,
    [CURRENCY.PLN]: 5,
    [CURRENCY.PHP]: 50,
    [CURRENCY.PEN]: 5,
    [CURRENCY.NGN]: 2000,
    [CURRENCY.MXN]: 20,
    [CURRENCY.KRW]: 1000,
    [CURRENCY.JPY]: 200,
    [CURRENCY.INR]: 100,
    [CURRENCY.IDR]: 20000,
    [CURRENCY.DKK]: 10,
    [CURRENCY.CNY]: 10,
    [CURRENCY.CLP]: 1000,
    [CURRENCY.CAD]: 1,
    [CURRENCY.BRL]: 5,
    [CURRENCY.ARS]: 500,
};
