import clsx from 'clsx';
import styled from '@emotion/styled';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 6rem);
  gap: 1rem;
  color: #d9d9d9;
  justify-content: space-netw;
  align-content: center;

  font-family: 'Chakra Petch', sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.56rem;

  .box {
    height: 6rem;
    width: 6rem;
    box-sizing: border-box;
    background: radial-gradient(
        112.5% 112.5% at 50% 15.28%,
        rgba(12, 12, 12, 0.85) 24.5%,
        rgba(0, 0, 0, 0.374) 100%
      ),
      rgba(102, 102, 102, 0.19);
    border: 0.5px solid #7695b2;
    border-radius: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;

    &.red-item {
      background-color: black;
      color: #f24822;
    }

    &.black-item {
      background-color: black;
      color: #ffffff;
    }
  }
`;

const specials = Array.from({ length: 10 }, (_, i) => (
  <div className={clsx('box')}></div>
));

export const SpecialPopupBox = () => {
  return <Container>{specials}</Container>;
};
