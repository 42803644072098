import { COLORS } from './logging';

const LOG_CATEGORIES = {
    config: '📋',
    error: '💥',
    info: 'ℹ️',
    input: '🔠',
    success: '✅',
    warn: '❗️',
    socket: '🔌',
};

type LogCategory = keyof typeof LOG_CATEGORIES;

type LogFunction = {
    [key in LogCategory]: (message: string, data?: unknown) => void;
};

type Logger = LogFunction & {
    setAppName: (name: string) => void;
    setColor: (color: string) => void;
};

const isBrowser = () => ![typeof window, typeof document].includes('undefined');

export const isObject = (value: unknown) => {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

const formattedData = (value: unknown) => {
    if (isObject(value)) {
        return `\n${JSON.stringify(value, null, 2)}`;
    }

    return `${value}`;
};

/**
 * Performs logging to Sentry breadcrumbs and to the console in
 * development mode.
 */
const Log = (): Logger => {
    const ctx = {
        appName: '',
        color: COLORS.FgWhite,
    };

    const writeLog = (
        category: LogCategory,
        message: string,
        data?: unknown
    ) => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            /**
             * If in development mode stringify any object data and console.log
             */

            const logEmoji =
                LOG_CATEGORIES[category] ||
                LOG_CATEGORIES['info' as LogCategory];

            const params = [];

            if (ctx.appName !== '') {
                params.push(`[${ctx.color}${ctx.appName}${COLORS.Reset}]`);
            }

            params.push(`${logEmoji} ${message}`);

            if (data) {
                params.push(formattedData(data));
            }
            console.log(...params);
        } else {
            /**
             * If in production add Sentry breadcrumb and an exception if it
             * is an 'error' category
             */
            //Sentry.addBreadcrumb({ category, message, level, data });
        }
    };

    return {
        config: (message, data) => writeLog('config', message, data),
        error: (message, data) => writeLog('error', message, data),
        info: (message, data) => writeLog('info', message, data),
        input: (message, data) => writeLog('input', message, data),
        success: (message, data) => writeLog('success', message, data),
        warn: (message, data) => writeLog('warn', message, data),
        socket: (message, data) => writeLog('socket', message, data),
        setAppName: (name) => {
            ctx.appName = name;
        },
        setColor: (color) => {
            ctx.color = color;
        },
    };
};

export const log = Log();
