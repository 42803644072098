import styled from '@emotion/styled';
import { popupBoxComponents } from './popup-box.constant';
import { ReactComponent as CloseArrow } from '../../../assets/icons/close-arrow.svg';
import { Range } from './range';
interface Props {
    title: string;
    setActivePopupBox: (string: string) => void;
}

const Container = styled.div`
    width: 98%;
    height: 5rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    box-sizing: border-box;
    position: relative;
    z-index: 2;

    pointer-events: auto;

    .title {
        box-sizing: border-box;

        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;

        margin-left: 1.6rem;

        color: var(--color-theme-white);
        font-family: 'Chakra Petch';
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .right-box {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        .box {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0.3rem;

            pointer-events: auto;
            cursor: pointer;

            &.hidden {
                display: none;
            }

            svg {
                height: 1.3rem;
                width: 1.3rem;
                fill: var(--color-theme-white);
            }
        }

        .active {
            svg {
                fill: var(--color-primary);
            }
        }

        .close-arrow svg {
            height: 1rem;
            fill: var(--color-theme-white);
        }
    }
`;

const formatTitle = (title: string) => {
    return title
        .split('-')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const PopupHeading = ({ title, setActivePopupBox }: Props) => {
    return (
        <Container>
            <div className="title">{formatTitle(title)}</div>
            <div className="right-box">
                {['latest-results', 'hot-&-cold'].includes(title) && <Range />}

                {popupBoxComponents.map((stat, index) => (
                    <div
                        key={index}
                        className={`box ${title === stat.name ? 'active' : ''}`}
                        onClick={() => setActivePopupBox(stat.name)}
                    >
                        <stat.icon />
                    </div>
                ))}
                <div
                    className="box close-arrow"
                    onClick={() => setActivePopupBox('')}
                >
                    <CloseArrow />
                </div>
            </div>
        </Container>
    );
};
