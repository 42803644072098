import { CurrencyContext, CurrencyConverter } from './types';
import { convertCurrency, getCurrencyRate } from './currency-conversion-utils';
import { currencyValues } from './static-currencies';

/**
 * Create a currency converter object
 */
export const createCurrencyConverter = (): CurrencyConverter => {
    // Create the context
    const context: CurrencyContext = {
        rates: currencyValues,
    };

    // Return the currency converter object with functions
    return {
        /**
         * Convert the amount from base currency to target currency
         * @param base
         * @param target
         * @param amount
         */
        convert: (base, target, amount) =>
            convertCurrency(context, base, target, amount),

        /**
         * Get the exchange rate between two currencies
         * @param base
         * @param target
         */
        getRate: (base, target) => getCurrencyRate(context, base, target),
    };
};
