import styled from '@emotion/styled';
import { AnimoUserData } from '@shared/aggregator/models';
import { Chat, SettingsButtonsRow, TableName } from '@shared/ui/components';

import { LeftButtons, RightButtons } from '@shared/ui/components/game-layout';
import { useBaseContext } from '@shared/ui/contexts';

import { PropsWithChildren, useState } from 'react';

const Container = styled.div`
    position: absolute;
    inset: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const InnerContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
`;

const MobileTop = styled.div`
    display: flex;
`;

const LeftContainer = styled.div`
    width: 100%;

    .chat {
        position: absolute;
    }

    .game-info {
        box-sizing: border-box;

        display: flex;
        align-items: center;
        text-align: left;

        padding: 1rem 1.4rem 0.6rem 1.3rem;

        border-radius: 0.8rem;

        font-size: 1.3rem;
        font-weight: 400;
        line-height: 1.6rem;
        text-transform: uppercase;

        .value {
            font-size: 1.7rem;
            font-weight: 500;
            line-height: 2.4rem;
            color: var(--baccarat-gold-color);
        }
    }
`;
const RightContainer = styled.div`
    display: flex;
    width: 10rem;

    .timer {
        margin: 1rem;
        height: 4rem;
        width: 4rem;

        position: relative;
        top: 4rem;
        right: -4rem;

        display: flex;
        justify-content: space-around;
        align-items: center;
    }
`;

const ChildWrapper = styled.div`
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: auto;
    pointer-events: none; /* This is important */
    overflow: hidden;
`;
const ChildCenter = styled.div`
    margin-top: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

const MobileMiddle = styled.div``;

const MobileFooter = styled.div`
    display: flex;
    align-items: flex-end;
    padding-bottom: 2rem;
`;

export interface BaseGameLayoutProps {
    gameName: string;
    minBet: number;
    maxBet: number;
    userId: string;
    baseBetTotal: number;
    sideBetTotals: number;
    user: Pick<AnimoUserData, 'currency' | 'balance'>;
}

export const BaseGameLayoutMobile = ({
    gameName,
    minBet,
    maxBet,
    userId,
    user,
    children,
}: PropsWithChildren<BaseGameLayoutProps>) => {
    const [showChat, setShowChat] = useState(false);
    const isVertical = true;

    const {
        baseState: {
            serverInfo: { lobbyUrl },
        },
    } = useBaseContext();

    return (
        <Container>
            <ChildWrapper>
                <ChildCenter>{children}</ChildCenter>
            </ChildWrapper>

            <InnerContainer>
                <MobileTop>
                    <LeftContainer>
                        <TableName
                            className="game-info"
                            gameName={gameName}
                            minBet={minBet}
                            maxBet={maxBet}
                        />
                        <Chat
                            className="chat"
                            userId={userId}
                            isMobile
                            showChat={showChat}
                        />
                        {/* TODO <button>Toggle Show Chat</button> */}
                    </LeftContainer>
                    <RightContainer>
                        <SettingsButtonsRow isMobile={isVertical} />

                        {/* How come we dont have one of these in the desktop??? */}
                        {/* Should this come from the game or baselayout ??  */}
                        {/* <div className="timer">
                          <span>
                              {gameState.roundInfo?.start_time &&
                              gameState.roundInfo?.duration &&
                              gameState.roundInfo?.start_time +
                                  gameState.roundInfo?.duration >
                                  Date.now() ? (
                                  <MobileTimer
                                      endTime={
                                          gameState.roundInfo?.start_time +
                                          gameState.roundInfo?.duration
                                      }
                                  />
                              ) : (
                                  ''
                              )}
                          </span>
                      </div> */}
                    </RightContainer>
                </MobileTop>

                <MobileMiddle>{/*Nothing to see here*/}</MobileMiddle>

                <MobileFooter>
                    <LeftButtons
                        isVertical={isVertical}
                        user={user}
                        roundInfo={{
                            sideBetTotals: 0,
                            baseBetTotal: 0,
                            totalWon: 0,
                        }}
                    />

                    <RightButtons
                        isVertical={isVertical}
                        enableTable={false}
                        enableLobby={lobbyUrl !== ''}
                        lobbyUrl={lobbyUrl}
                    />
                </MobileFooter>
            </InnerContainer>
        </Container>
    );
};
