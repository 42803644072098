import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import clsx from 'clsx';

import {
    useLogicActionContext,
    useLogicStateContext,
} from '@roulette/contexts';

import { SpecialPopupBox } from './popup-box/specials-popup-box';
import { AdvancedPopupBoxMobile } from './popup-box/advanced-popup-box-mobile';
import { LastFiveHundredPopupBoxMobile } from './popup-box/last-five-hundred-popup-box';
import { HotNColdPopupBox } from './popup-box/hot-n-cold-popup-box';

import { RiCloseLine } from 'react-icons/ri';
import { useState } from 'react';
import { BottomRowChips } from '../bottom-row-chips';

const fadeInLeft = keyframes`
    0% {
        opacity: 0;
        transform: translateX(2rem);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

const slideUp = keyframes`
    0% {
        opacity: 0;
        transform: translateY(2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const slideDown = keyframes`
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(0rem);
    }
`;

const Container = styled.div<{ isClosing: boolean }>`
    height: 78.1rem;
    width: 43rem;

    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 8rem;

    left: 26.82%;
    right: 26.94%;
    top: 29.3%;
    bottom: 52.26%;

    pointer-events: auto;
    cursor: pointer;

    background: radial-gradient(
            112.5% 112.5% at 50% 15.28%,
            rgba(12, 12, 12, 0.85) 24.5%,
            rgba(0, 0, 0, 0.374) 100%
        ),
        rgba(102, 102, 102, 0.19);
    border: 0.5px solid #63839e;
    backdrop-filter: blur(2px);
    border-radius: 8px;

    font-family: 'Chakra Petch', sans-serif;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.56rem;
    color: #d9d9d9;

    animation: ${({ isClosing }) => (isClosing ? slideDown : slideUp)} 0.5s
        ease-in;

    .dialogue-container {
        top: -2rem;
        right: 5rem;
        animation: ${fadeInLeft} 0.5s ease-out; /* Apply animation */
    }

    .popup-heading {
        position: relative;
        z-index: 1;
        text-align: center;
        color: #d9d9d9;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 2.5rem;
    }

    .headings {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        height: 1rem;
        width: 50%;

        font-size: 1rem;
        margin-top: 1rem;
        margin-bottom: 3rem;
    }

    .bottom-line {
        height: 0.3rem;
        width: 100%;
        background: #767676;
    }

    .render-content {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;

        z-index: 2;
    }

    .close-button {
        height: 3.2rem;
        width: 7rem;
        border: 0.5rem;

        z-index: 2;
        position: absolute;
        right: 40%;
        top: 85%;
        margin: 0 auto;

        pointer-events: auto;
        cursor: pointer;

        background: radial-gradient(
                112.5% 112.5% at 50% 15.28%,
                rgba(12, 12, 12, 0.85) 24.5%,
                rgba(0, 0, 0, 0.374) 100%
            ),
            rgba(102, 102, 102, 0.19);
        border: 0.5px solid #7695b2;
        border-radius: 50%/100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .selected {
        color: var(--roulette-hover);
    }

    .bottom-line-selected {
        background: var(--roulette-hover);
    }

    .slider-component {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.1rem;
        color: #93c2e8;
        height: 2.3rem;
        width: 28.8rem;
        margin-top: 2rem;
    }

    .slider {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .slide {
        width: 90%;
        background: var(--roulette-hover);
    }

    .range-value {
        height: 2.5rem;
        width: 10%;
        margin-left: 0.5rem;
        border: 0.1rem solid #63839e;
        border-radius: 60%/100%;
        padding: 0.1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;

const History = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
`;

interface Props {
    type: string;
    setActivePopupBox: (string: string) => void;
}

export const PopupBoxMobile = ({ type, setActivePopupBox }: Props) => {
    const renderContent = () => {
        switch (type) {
            case 'specials':
                return <SpecialPopupBox />;
            case 'last-five-hundred':
                return <LastFiveHundredPopupBoxMobile />;
            case 'advanced':
                return <AdvancedPopupBoxMobile />;
            case 'hot-n-cold':
                return <HotNColdPopupBox isMobile />;
            default:
                return null;
        }
    };

    const { range } = useLogicStateContext();

    const { handleRange } = useLogicActionContext();

    const [isClosing, setIsClosing] = useState(false);

    return (
        <Container isClosing={isClosing}>
            <div className="popup-heading">
                <span>STATISTICS</span>
                <div
                    className="box"
                    onClick={() => {
                        setIsClosing(true);
                        setActivePopupBox('');
                    }}
                >
                    <RiCloseLine className="icon" />
                </div>
            </div>

            <div className="headings">
                <div>
                    <div
                        className={clsx(type === 'hot-n-cold' && 'selected')}
                        onClick={() => setActivePopupBox('hot-n-cold')}
                    >
                        HOT & COLD
                    </div>
                    <div
                        className={clsx(
                            'bottom-line',
                            type === 'hot-n-cold' && 'bottom-line-selected'
                        )}
                    ></div>
                </div>
                <div>
                    <div
                        className={clsx(type === 'advanced' && 'selected')}
                        onClick={() => setActivePopupBox('advanced')}
                    >
                        ADVANCED
                    </div>
                    <div
                        className={clsx(
                            'bottom-line',
                            type === 'advanced' && 'bottom-line-selected'
                        )}
                    ></div>
                </div>
                <div>
                    <div
                        className={clsx(
                            type === 'last-five-hundred' && 'selected'
                        )}
                        onClick={() => setActivePopupBox('last-five-hundred')}
                    >
                        LAST {range}
                    </div>
                    <div
                        className={clsx(
                            'bottom-line',
                            type === 'last-five-hundred' &&
                                'bottom-line-selected'
                        )}
                    ></div>
                </div>
            </div>
            <History>
                <BottomRowChips />
            </History>

            <div className="render-content">{renderContent()}</div>
            <div className="slider-component">
                LAST {range} ROUNDS
                <div className="slider">
                    <input
                        className="slide"
                        type="range"
                        min="0"
                        max="1000"
                        step="50"
                        value={range}
                        onChange={(e) => handleRange(+e.target.value)}
                    />
                    <span className="range-value">{range}</span>
                </div>
            </div>
        </Container>
    );
};
