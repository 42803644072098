import { ReactElement } from 'react';
import { ReactComponent as Fullscreen } from '../assets/icons/Fullscreen_icon.svg';
import { ReactComponent as Minimize } from '../assets/icons/Minimize_icon.svg';
import { ReactComponent as Setting } from '../assets/icons/Settings_icon.svg';
import { ReactComponent as Show } from '../assets/icons/Show_icon.svg';
import { ReactComponent as Hide } from '../assets/icons/Hide_icon.svg';
import { ReactComponent as Mute } from '../assets/icons/Mute_icon.svg';
import { ReactComponent as Unmute } from '../assets/icons/Unmute_3_icon.svg';
import { ReactComponent as VideoStream } from '../assets/icons/Video_stream_icon.svg';
import { ReactComponent as History } from '../assets/icons/History.svg';
import { ReactComponent as Headset } from '../assets/icons/Headset_icon.svg';
import { ReactComponent as Message } from '../assets/icons/Message.svg';
import { ReactComponent as Info } from '../assets/icons/Info.svg';
import { ReactComponent as Chevron } from '../assets/icons/Chevron.svg';

export const ICONS: Record<string, ReactElement> = {
    Fullscreen: <Fullscreen />,
    Minimize: <Minimize />,
    Setting: <Setting />,
    Show: <Show />,
    Hide: <Hide />,
    Mute: <Mute />,
    Unmute: <Unmute />,
    VideoStream: <VideoStream />,
    BackArrow: <History />,
    Headset: <Headset />,
    Message: <Message />,
    Info: <Info />,
    Chevron: <Chevron />,
};
