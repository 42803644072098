import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

type AudioValues = {
    dealerVolume: number;
    effectsVolume: number;
    ambientVolume: number;
    masterVolume: number;

    rawDealerVolume: number;
    rawEffectsVolume: number;
    rawAmbientVolume: number;
    rawMasterVolume: number;

    toggleMuteDealer: () => void;
    toggleMuteEffects: () => void;
    toggleMuteAmbient: () => void;
    toggleMuteMaster: () => void;

    isDealerMuted: boolean;
    isEffectsMuted: boolean;
    isAmbientMuted: boolean;
    isMasterMuted: boolean;

    setDealerVolume: (v: number) => void;
    setEffectsVolume: (v: number) => void;
    setAmbientVolume: (v: number) => void;
    setMasterVolume: (v: number) => void;
};

type AudioContextProviderProps = PropsWithChildren;

const contextValues = {
    dealerVolume: 1.0,
    effectsVolume: 0.4,
    ambientVolume: 0.2,
    masterVolume: 1.0,
    rawDealerVolume: 1.0,
    rawEffectsVolume: 0.4,
    rawAmbientVolume: 0.2,
    rawMasterVolume: 1.0,

    isDealerMuted: false,
    isEffectsMuted: false,
    isAmbientMuted: false,
    isMasterMuted: false,

    setDealerVolume: () => {
        /* */
    },
    setEffectsVolume: () => {
        /* */
    },
    setAmbientVolume: () => {
        /* */
    },
    setMasterVolume: () => {
        /* */
    },

    toggleMuteDealer: () => {
        /* */
    },
    toggleMuteEffects: () => {
        /* */
    },
    toggleMuteAmbient: () => {
        /* */
    },
    toggleMuteMaster: () => {
        /* */
    },
};

const AudioContext = createContext<AudioValues>(contextValues);

const useVolume = (
    name: string,
    defaultValue = 1,
    masterVolume = 1,
    masterMuted = false
): [number, number, boolean, (v: number) => void, () => void] => {
    const [_volume, setVolume] = useState<number>(
        +(localStorage.getItem(`audio_volume_${name}`) || defaultValue)
    );

    const [_isMuted, setIsMuted] = useState(
        localStorage.getItem(`audio_muted_${name}`) === 'true' ? true : false
    );

    const isMuted = useMemo(
        () => _isMuted || _volume === 0,
        [_volume, _isMuted]
    );

    const volume = useMemo(() => {
        if (masterMuted) return 0;

        return masterVolume * _volume;
    }, [masterVolume, masterMuted, _volume]);

    const toggleMute = useCallback(() => {
        setIsMuted((m) => !m);
    }, []);

    // Store volume
    useEffect(() => {
        localStorage.setItem(`audio_volume_${name}`, _volume.toString());
    }, [_volume, name]);

    // Store muted
    useEffect(() => {
        localStorage.setItem(
            `audio_muted_${name}`,
            _isMuted ? 'true' : 'false'
        );
    }, [_isMuted, name]);

    return [_volume, volume, isMuted, setVolume, toggleMute];
};

export const AudioContextProvider = (props: AudioContextProviderProps) => {
    const { children } = props;

    const [
        rawMasterVolume,
        masterVolume,
        isMasterMuted,
        setMasterVolume,
        toggleMuteMaster,
    ] = useVolume('master');
    const [
        rawDealerVolume,
        dealerVolume,
        isDealerMuted,
        setDealerVolume,
        toggleMuteDealer,
    ] = useVolume('dealer', 1.0, masterVolume, isMasterMuted);
    const [
        rawEffectsVolume,
        effectsVolume,
        isEffectsMuted,
        setEffectsVolume,
        toggleMuteEffects,
    ] = useVolume('effects', 0.6, masterVolume, isMasterMuted);
    const [
        rawAmbientVolume,
        ambientVolume,
        isAmbientMuted,
        setAmbientVolume,
        toggleMuteAmbient,
    ] = useVolume('ambient', 0.2, masterVolume, isMasterMuted);

    const values = useMemo(() => {
        return {
            dealerVolume,
            effectsVolume,
            ambientVolume,
            masterVolume,

            rawDealerVolume,
            rawEffectsVolume,
            rawAmbientVolume,
            rawMasterVolume,

            setDealerVolume,
            setEffectsVolume,
            setAmbientVolume,
            setMasterVolume,

            toggleMuteDealer,
            toggleMuteEffects,
            toggleMuteAmbient,
            toggleMuteMaster,

            isDealerMuted,
            isEffectsMuted,
            isAmbientMuted,
            isMasterMuted,
        };
    }, [
        dealerVolume,
        effectsVolume,
        ambientVolume,
        masterVolume,

        rawDealerVolume,
        rawEffectsVolume,
        rawAmbientVolume,
        rawMasterVolume,

        setDealerVolume,
        setEffectsVolume,
        setAmbientVolume,
        setMasterVolume,

        toggleMuteDealer,
        toggleMuteEffects,
        toggleMuteAmbient,
        toggleMuteMaster,

        isDealerMuted,
        isEffectsMuted,
        isAmbientMuted,
        isMasterMuted,
    ]);

    return (
        <AudioContext.Provider value={values}>{children}</AudioContext.Provider>
    );
};

export const useAudio = () => {
    const context = useContext(AudioContext);
    return context;
};
