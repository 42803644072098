export const rouletteGameInfo = [
    {
        title: 'Game Rules',
        content: (
            <>
                <p>
                    The objective in Roulette is to predict the number on which
                    the ball will land by placing one or more bets that cover
                    that particular number. The wheel in Roulette includes the
                    numbers 1-36 plus a single 0 (zero).
                </p>
                <p>
                    After betting time has expired, the ball is spun within the
                    Roulette wheel. The ball will eventually come to rest in one
                    of the numbered pockets within the wheel. You win if you
                    have placed a bet that covers that particular number.
                </p>
            </>
        ),
    },
    {
        title: 'Bet Types',
        content: <p></p>,
    },
    {
        title: 'Neighbour Bets',
        content: <p></p>,
    },
    {
        title: 'Favourite & Special Bets',
        content: <p></p>,
    },
    {
        title: 'Winning Numbers',
        content: <p></p>,
    },
    {
        title: 'Your Recent Results',
        content: <p></p>,
    },
    {
        title: 'Payouts',
        content: <p></p>,
    },
];
