import { createEvent } from '@shared/events';

import { RoulettePlayer } from './game.type';
import { PlayerBaseBet, PlayerPayout } from './player.type';

export * from '@shared/events';

export enum RouletteStates {
    Unknown = 'Unknown',
    WaitingForDealer = 'WaitingForDealer',
    WaitingForPlayers = 'WaitingForPlayers',
    WaitingForBets = 'WaitingForBets',
    FinishedBetting = 'FinishedBetting',
    WaitingForWheelSpin = 'WaitingForWheelSpin',
    PlayerEndTurn = 'PlayerEndTurn',
    GameEnd = 'GameEnd',
}

export enum Events {
    // CardsUpdate = 'CardsUpdate', // All cards
    GameInfo = 'GameInfo',
    GameState = 'GameState',
    PlayerBet = 'PlayerBet',
    Player = 'Player',
    RoundStart = 'RoundStart',
    RoundEnd = 'RoundEnd',
    UserJoin = 'UserJoin',
    BallPosition = 'BallPosition',
    // State from 1 - 5 to signify how many times the ball has spun around the wheel
    WheelState = 'WheelState',
    // Internal state to signify to roulette emulator to spin virtual wheel
    StartWheel = 'StartWheel',
    ResultHistory = 'ResultHistory',
}

export const createGameStateEvent = (
    previousState: RouletteStates,
    nextState: RouletteStates
) => {
    return createEvent(Events.GameState, {
        previous_state: previousState,
        next_state: nextState,
    });
};

export type GameStateEvent = ReturnType<typeof createGameStateEvent>[1];

// RoundStartEvent
export const createRoundStartEvent = (
    roundId: string,
    duration: number | null,
    remainingTime: number | null
) => {
    return createEvent(Events.RoundStart, {
        roundId,
        duration,
        remainingTime,
    });
};

export type RoundStartEvent = ReturnType<typeof createRoundStartEvent>[1];

// RoundEndEvent
export const createRoundEndEvent = (
    roundId: string,
    totalWon: number,
    payouts: PlayerPayout[]
) => {
    return createEvent(Events.RoundEnd, {
        roundId,
        totalWon,
        payouts,
    });
};

export type RoundEndEvent = ReturnType<typeof createRoundEndEvent>[1];

export type VideoStreamData = {
    type?: 'millicast';
    account?: string;
    name?: string;
};

export const createGameInfoEvent = (
    name: string,
    minBet: number,
    maxBet: number,
    minSideBet: number,
    maxSideBet: number,
    chipAmounts: number[],
    playerBetDuration: number,
    streamData?: VideoStreamData
) => {
    return createEvent(Events.GameInfo, {
        minBet: minBet,
        maxBet: maxBet,
        minSideBet: minSideBet,
        maxSideBet: maxSideBet,
        chipAmounts: chipAmounts,
        name,
        playerBetDuration,
        streamData,
    });
};
export type GameInfoEvent = ReturnType<typeof createGameInfoEvent>[1];
export type GameInfoData = GameInfoEvent['payload'];

// Frontend Events
// PlayerActionEvent

export interface IPlayerBet {
    uuid: string;
    type: PlayerBaseBet;
    amount: number;
    betValues: number[];
}

export const createPlayerBetEvent = (bets: IPlayerBet[]) => {
    return createEvent(Events.PlayerBet, { bets });
};

export type PlayerBetEvent = ReturnType<typeof createPlayerBetEvent>[1];

// updating players with the game data ie cards and their bets
export const createPlayersEvent = (
    wheel: number,
    players: RoulettePlayer[]
) => {
    return createEvent(Events.Player, {
        wheel,
        players,
    });
};

export type PlayerEvent = ReturnType<typeof createPlayersEvent>[1];

export const createBallEvent = (value: number) => {
    return createEvent(Events.BallPosition, { value });
};

export type BallEvent = ReturnType<typeof createBallEvent>[1];

export const createWheelStateEvent = (state: number) => {
    return createEvent(Events.WheelState, { state });
};

export type WheelStateEvent = ReturnType<typeof createWheelStateEvent>[1];

export const createStartWheelEvent = () => {
    return createEvent(Events.StartWheel, {});
};

export type StartWheelEvent = ReturnType<typeof createStartWheelEvent>[1];

export const createResultedHistoryEvent = (resultedNumbers: number[]) => {
    return createEvent(Events.ResultHistory, { resultedNumbers });
};

export type ResultedHistoryEvent = ReturnType<
    typeof createResultedHistoryEvent
>[1];
