import styled from '@emotion/styled';
import { boardNumbers } from '@roulette/models';

interface Props {
    winNumber: number;
}

const Svg = styled.svg`
    .red .background {
        fill: url(#paint0_linear_2588_188);
    }

    .black .background {
        fill: url(#paint1_linear_2588_188);
    }
    .green .background {
        fill: #14ae5c;
    }
`;

export const WinMessage: React.FC<Props> = ({ winNumber }) => {
    const numbers = [
        4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33,
        1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19,
    ];

    const index = numbers.findIndex((n) => n === +winNumber);

    const leftValue =
        index === 0 ? numbers[numbers.length - 1] : numbers[index - 1];
    const rightValue =
        index === numbers.length - 1 ? numbers[0] : numbers[index + 1];

    const winValue = numbers[index];

    const left = boardNumbers[leftValue];
    const right = boardNumbers[rightValue];
    const value = boardNumbers[winValue];

    return (
        <Svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 196 93"
            fill="none"
        >
            <g id="WinningNumber">
                <g id="CenterNumber" className={value.color}>
                    <g id="Vector" filter="url(#filter0_d_2588_188)">
                        <path
                            className="background"
                            d="M71.3406 66C88.6847 63.4981 106.657 63.358 124.849 65.9199L132 15.3919C109.111 12.1695 86.271 12.2295 64 15.4119L71.3406 66Z"
                            fill="url(#paint0_linear_2588_188)"
                        />
                        {value.color === 'black' ? (
                            <path
                                d="M71.3406 66C88.6847 63.4981 106.657 63.358 124.849 65.9199L132 15.3919C109.111 12.1695 86.271 12.2295 64 15.4119L71.3406 66Z"
                                fill="url(#paint1_linear_2588_188)"
                                fill-opacity="0.6"
                            />
                        ) : null}
                    </g>
                    <text
                        id="19"
                        fill="white"
                        className="text"
                        style={{ whiteSpace: 'pre' }}
                        font-family="Chakra Petch"
                        font-size="25"
                        font-weight="500"
                        letter-spacing="0em"
                        textAnchor={'middle'}
                    >
                        <tspan x="98" y="44.5">
                            {value.value}
                        </tspan>
                    </text>
                </g>
                <g id="LeftNumber" className={left.color}>
                    <g id="Vector_2">
                        <path
                            className="background"
                            d="M72 66.1069C53.9232 68.7164 36.8077 73.8164 20.9509 81L0 34.8521C20.376 25.6543 42.1594 19.2644 64.8942 16L72 66.0969V66.1069Z"
                            fill="black"
                        />
                        {left.color === 'red' ? null : (
                            <path
                                d="M72 66.1069C53.9232 68.7164 36.8077 73.8164 20.9509 81L0 34.8521C20.376 25.6543 42.1594 19.2644 64.8942 16L72 66.0969V66.1069Z"
                                fill="url(#paint1_linear_2588_188)"
                                fill-opacity="0.6"
                            />
                        )}
                    </g>
                    <text
                        id="15"
                        transform="translate(21.9779 42.7681) rotate(-18.1057)"
                        fill="white"
                        style={{ whiteSpace: 'pre' }}
                        font-family="Chakra Petch"
                        font-size="25"
                        font-weight="500"
                        letter-spacing="0em"
                        textAnchor="middle"
                    >
                        <tspan x="15" y="17.5">
                            {left.value}
                        </tspan>
                    </text>
                </g>
                <g id="RightNumber" className={right.color}>
                    <g id="Vector_3">
                        <path
                            className="background"
                            d="M124 66.1069C142.077 68.7164 159.192 73.8164 175.049 81L196 34.8521C175.624 25.6543 153.841 19.2644 131.106 16L124 66.0969V66.1069Z"
                            fill="black"
                        />
                        {right.color === 'red' ? null : (
                            <path
                                d="M124 66.1069C142.077 68.7164 159.192 73.8164 175.049 81L196 34.8521C175.624 25.6543 153.841 19.2644 131.106 16L124 66.0969V66.1069Z"
                                fill="url(#paint2_linear_2588_188)"
                                fill-opacity="0.6"
                            />
                        )}
                    </g>
                    <text
                        className="text"
                        id="4"
                        transform="translate(147.503 33.5298) rotate(16.0259)"
                        fill="white"
                        style={{ whiteSpace: 'pre' }}
                        font-family="Chakra Petch"
                        font-size="25"
                        font-weight="500"
                        letter-spacing="0em"
                        textAnchor="middle"
                    >
                        <tspan x="14.46016" y="17.5">
                            {right.value}
                        </tspan>
                    </text>
                </g>
                <g id="Ball" filter="url(#filter1_ddiiii_2588_188)">
                    <ellipse
                        cx="98"
                        cy="66"
                        rx="15"
                        ry="15"
                        transform="rotate(-90 98 66)"
                        fill="#D9D9D9"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_2588_188"
                    x="50"
                    y="0"
                    width="96"
                    height="81"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="7" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.94902 0 0 0 0 0.282353 0 0 0 0 0.133333 0 0 0 0.3 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2588_188"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2588_188"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_ddiiii_2588_188"
                    x="77"
                    y="44"
                    width="41"
                    height="49"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="8" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2588_188"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-3" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_2588_188"
                        result="effect2_dropShadow_2588_188"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_2588_188"
                        result="shape"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-5" dy="-3" />
                    <feGaussianBlur stdDeviation="4.5" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.9 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="shape"
                        result="effect3_innerShadow_2588_188"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="7"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect4_innerShadow_2588_188"
                    />
                    <feOffset dx="19" dy="13" />
                    <feGaussianBlur stdDeviation="5" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect3_innerShadow_2588_188"
                        result="effect4_innerShadow_2588_188"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="5" dy="-15" />
                    <feGaussianBlur stdDeviation="3.5" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect4_innerShadow_2588_188"
                        result="effect5_innerShadow_2588_188"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="-6" dy="12" />
                    <feGaussianBlur stdDeviation="3" />
                    <feComposite
                        in2="hardAlpha"
                        operator="arithmetic"
                        k2="-1"
                        k3="1"
                    />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.48 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect5_innerShadow_2588_188"
                        result="effect6_innerShadow_2588_188"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_2588_188"
                    x1="98"
                    y1="-6.52459"
                    x2="98"
                    y2="66.03"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#8C2A14" />
                    <stop offset="1" stop-color="#F24822" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_2588_188"
                    x1="55.0431"
                    y1="15.5138"
                    x2="3.4063"
                    y2="135.528"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.18268" stop-color="#232323" />
                    <stop offset="0.981031" stop-color="#898989" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_2588_188"
                    x1="140.957"
                    y1="15.5138"
                    x2="192.594"
                    y2="135.528"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.18268" stop-color="#232323" />
                    <stop offset="0.981031" stop-color="#898989" />
                </linearGradient>
            </defs>
        </Svg>
    );
};
