import React, { useCallback, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';

import { PlayerBaseBet, RouletteStates } from '@roulette/models';
import { useGameContext, useLogicActionContext } from '@roulette/contexts';
import { RacetrackComponent } from '@roulette/components';

const Component = styled.div<{ isMobile: boolean | undefined }>`
    transform: ${({ isMobile }) => isMobile && 'rotate(180deg)'};
`;

interface Props {
    showRacetrack: boolean;
    isMobile?: boolean;
}

export const Racetrack = ({ showRacetrack, isMobile }: Props) => {
    const { gameState } = useGameContext();

    const { handlePlayerBet } = useLogicActionContext();

    const [selectedNumber, setSelectedNumber] = React.useState<
        number[] | undefined
    >(undefined);

    const canBet = useMemo(
        () =>
            [
                RouletteStates.WaitingForPlayers,
                RouletteStates.WaitingForBets,
            ].includes(gameState.currentState.next_state),
        [gameState.currentState.next_state]
    );

    const handleBet = useCallback(
        (
            type: PlayerBaseBet,
            chosenNumber: number[],
            neighbourValues: number[]
        ) => {
            if (canBet) {
                handlePlayerBet(type, chosenNumber, neighbourValues);
            }
        },
        [handlePlayerBet, canBet]
    );

    useEffect(() => {
        if (selectedNumber?.length) {
            selectedNumber.forEach((number) => {
                handleBet(
                    PlayerBaseBet.RACETRACK_STRAIGHT_UP,
                    [number],
                    selectedNumber
                );
            });
        }
    }, [selectedNumber]);

    return (
        <Component isMobile={isMobile}>
            <RacetrackComponent
                setSelectedNumber={setSelectedNumber}
                showRacetrack={showRacetrack}
                isMobile={isMobile ?? false}
            />
        </Component>
    );
};
