import { VideoStreamMillicast } from './video-stream.millicast';

export interface VideoStreamProps {
    type: 'millicast';
    accountId: string;
    name: string;
    muted?: boolean;
    volume: number;
    onReady?: () => void;
}

export const VideoStream = (props: VideoStreamProps) => {
    const { type, accountId, muted, volume, name, onReady } = props;

    if (type === 'millicast' && accountId) {
        return (
            <VideoStreamMillicast
                accountId={accountId}
                name={name}
                muted={muted}
                volume={volume}
                onReady={onReady}
            />
        );
    }
    return null;
};
