import {
    useLogicActionContext,
    useLogicStateContext,
} from '@roulette/contexts';

import styled from '@emotion/styled';
import { ReactComponent as Chart } from '../../../assets/icons/chart.svg';

const Container = styled.div`
    height: 1.8rem;
    width: 6.7rem;

    border-radius: 0.6rem;
    border-top: 0.1rem solid var(--color-primary);
    background: rgba(0, 0, 0, 0.7);
    margin-right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .range-value {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: end;
        width: 1.5rem;

        color: var(--color-theme-white);
        text-align: center;
        font-family: 'Chakra Petch';
        font-size: 0.8rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .slide {
        width: 2.2rem;
        -webkit-appearance: none;
        appearance: none;
        height: 0.2rem;
        background: var(--color-primary);
        border-radius: 0.3rem;
        outline: none;

        position: relative;
        z-index: 1;
    }

    .slide::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 0.6rem;
        height: 0.6rem;
        position: relative;
        background-color: var(--color-theme-white);
        border-radius: 50%;
        cursor: pointer;
    }

    .datalists {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        position: absolute;
        gap: 0.5rem;
        z-index: 0;
    }

    .sliderLabels {
        position: relative;
        height: 0.6rem;
        width: 0.1rem;
        background: #595959;
    }

    svg {
        fill: var(--color-primary);
        height: 0.8rem;
        width: 0.8rem;
    }
`;

export const Range = () => {
    const { range } = useLogicStateContext();
    const { handleRange } = useLogicActionContext();

    return (
        <Container>
            <Chart />
            <input
                className="slide"
                type="range"
                min="0"
                max="300"
                step="50"
                value={range}
                list="valueLists"
                onChange={(e) => handleRange(+e.target.value)}
            />
            <div className="datalists">
                <span className="sliderLabels"></span>
                <span className="sliderLabels"></span>
                <span className="sliderLabels"></span>
            </div>
            <span className="range-value">{range}</span>
        </Container>
    );
};
