interface Props {
    showRacetrack: boolean;
    setShowRacetrack: (boolean: boolean) => void;
}

export const ExpandRacetrackButton = ({
    showRacetrack,
    setShowRacetrack,
}: Props) => {
    return (
        <svg
            width="70"
            height="32"
            viewBox="0 0 70 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => setShowRacetrack(!showRacetrack)}
        >
            <path
                d="M0.25 16C0.25 7.30152 7.30152 0.25 16 0.25H54C62.6985 0.25 69.75 7.30152 69.75 16C69.75 24.6985 62.6985 31.75 54 31.75H16C7.30151 31.75 0.25 24.6985 0.25 16Z"
                fill="#666666"
                fillOpacity="0.19"
            />
            <path
                d="M0.25 16C0.25 7.30152 7.30152 0.25 16 0.25H54C62.6985 0.25 69.75 7.30152 69.75 16C69.75 24.6985 62.6985 31.75 54 31.75H16C7.30151 31.75 0.25 24.6985 0.25 16Z"
                fill="url(#paint0_radial_2_315)"
                fillOpacity="0.85"
            />
            <path
                d="M0.25 16C0.25 7.30152 7.30152 0.25 16 0.25H54C62.6985 0.25 69.75 7.30152 69.75 16C69.75 24.6985 62.6985 31.75 54 31.75H16C7.30151 31.75 0.25 24.6985 0.25 16Z"
                stroke="#63839E"
                strokeWidth="0.5"
            />
            {!showRacetrack ? (
                <path
                    d="M35.9048 22L35.3469 21.4576L40.5034 16.3884H28V15.6116H40.5034L35.3469 10.5424L35.9048 10L42 16L35.9048 22Z"
                    fill="white"
                />
            ) : (
                <path
                    d="M35.9048 22L35.3469 21.4576L40.5034 16.3884H28V15.6116H40.5034L35.3469 10.5424L35.9048 10L42 16L35.9048 22Z"
                    fill="white"
                    transform="rotate(180, 35, 16)"
                />
            )}
            <defs>
                <radialGradient
                    id="paint0_radial_2_315"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(35 4.88889) rotate(90) scale(36 78.75)"
                >
                    <stop offset="0.245" stopColor="#0C0C0C" />
                    <stop offset="1" stopOpacity="0.44" />
                </radialGradient>
            </defs>
        </svg>
    );
};
