import {
    createContext,
    useContext,
    ReactNode,
    useState,
    useEffect,
    useRef,
} from 'react';
import { io, Socket } from 'socket.io-client';
import { jwtDecode } from 'jwt-decode';

import { serviceToken } from '@rgs/schemas/game-services';
import { log } from '@shared/utils';

import {
    AuthTokenEvent,
    BaseEvents,
    ErrorEvent,
    IBaseEvent,
    ResponseEvent,
    SocketError,
} from '@shared/events';

type GameSocket = Socket & {
    emitWithResponse: (
        event: IBaseEvent<string, unknown>
    ) => Promise<ResponseEvent>;
};

interface WebSocketContextProps {
    socket: GameSocket;
    connected: boolean;
    duplicateSession: boolean;
}

const WebsocketContext = createContext<WebSocketContextProps | undefined>(
    undefined
);

interface WebSocketProviderProps {
    children: ReactNode;
    token?: string;
    sessionId?: string;
    // Use url embedded into token
    useTokenHost?: boolean;
    asService?: boolean;
}

const createSocket = (
    url = '',
    session: string | undefined = undefined,
    token: string | undefined = undefined,
    asService = false
) => {
    const socket = io(url, {
        autoConnect: false,
        extraHeaders: {
            [asService ? 'x-animo-session' : 'session']: session ?? '',
            'x-animo-auth-token': token ?? '',
        },
        withCredentials: true,
    }) as GameSocket;

    socket.emitWithResponse = function (
        event: IBaseEvent<string, unknown>,
        timeout = 5000
    ) {
        const eventName = event[0];
        const data = event[1];

        return new Promise((resolve, reject) => {
            let timer: ReturnType<typeof setTimeout> | null = null;

            this.emit(eventName, data);

            const onResponse = (response: ResponseEvent) => {
                if (response.message_id === data.message_id) {
                    this.off(BaseEvents.Response, onResponse);
                    if (timer) clearTimeout(timer);
                    resolve(response);
                }
            };

            timer = setTimeout(() => {
                this.off(BaseEvents.Response, onResponse);
                reject('TIMEOUT_ERROR');
            }, timeout);

            this.on(BaseEvents.Response, onResponse);
        });
    };
    return socket;
};

const getHostURL = (
    token?: string,
    useTokenHost = false,
    asService = false
) => {
    if (useTokenHost && token && token.length) {
        if (useTokenHost) {
            const decoded = jwtDecode(token);

            const { success, data } = serviceToken.safeParse(decoded);

            if (success) {
                if (data.host) {
                    return data.host;
                }
            }
        }
    }

    const lastDigit = +window.location.port % 10;
    const devPort = 3200 + lastDigit;
    const url =
        process.env.NODE_ENV === 'development' ? `localhost:${devPort}` : '';
    return `${url}${asService ? '/service' : ''}`;
};

export const WebSocketProvider = (props: WebSocketProviderProps) => {
    const { children, token, sessionId, useTokenHost, asService } = props;
    const [connected, setConnected] = useState(false);
    const [duplicateSession, setDuplicateSession] = useState(false);
    const [error, setError] = useState<SocketError>();

    const socket = useRef<GameSocket>(
        createSocket(
            getHostURL(token, useTokenHost, asService),
            sessionId,
            token,
            asService
        )
    ).current;

    useEffect(() => {
        socket.on('connect', () => {
            log.socket('Connected');
            setConnected(true);
            setDuplicateSession(false);
        });

        socket.on('disconnect', () => {
            log.socket('disconnected');
            setConnected(false);
        });

        socket.on('connect_error', (err) => {
            const error = err as SocketError;

            if (error.data?.code === 401) {
                log.socket('Error:', err.message);
                window.localStorage.removeItem('auth_token');
            }

            setError(error);
            setConnected(false);
        });

        socket.on(BaseEvents.Error, (ev: ErrorEvent) => {
            if (ev.payload.code === 401) {
                // Do this better
                window.localStorage.removeItem('auth_token');
                setError(new SocketError(401, ev.payload.message));
            }
        });

        socket.on(BaseEvents.Error, (ev: ErrorEvent) => {
            if (ev.payload.code === 401) {
                // Do this better
                window.localStorage.removeItem('auth_token');
                setError(new SocketError(401, ev.payload.message));
            }

            if (ev.payload.code === 403) {
                window.localStorage.removeItem('auth_token');
                setError(new SocketError(ev.payload.code, ev.payload.message));
                setConnected(false);
            }
        });

        //Handle duplicate session event trigger
        socket.on(BaseEvents.DuplicateSession, () => {
            log.socket('DuplicateSession');
            setConnected(false);
            setDuplicateSession(true);
        });

        socket.on(BaseEvents.AuthToken, (event: AuthTokenEvent) => {
            const {
                payload: { token },
            } = event;

            window.localStorage.setItem('auth_token', token);
        });

        log.socket('Connecting...');
        socket.connect();

        return () => {
            socket.off('connect');
            socket.off('disconnect');
            socket.off('connect_error');
            socket.off(BaseEvents.Error);
            socket.off(BaseEvents.DuplicateSession);
            socket.off(BaseEvents.AuthToken);
            socket.close();

            setConnected(false);
            setDuplicateSession(false);
        };
    }, [useTokenHost, socket]);

    if (error) throw error;

    return (
        <WebsocketContext.Provider
            value={{
                connected,
                socket,
                duplicateSession,
            }}
        >
            {children}
        </WebsocketContext.Provider>
    );
};

export const useWebsocketContext = (): WebSocketContextProps => {
    const context = useContext(WebsocketContext);
    if (!context) {
        throw new Error('useSocket must be used within a WebSocketProvider');
    }
    return context;
};
