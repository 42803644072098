import { BaseEvents, CameraChangeEvent } from '@roulette/models';
import { log } from '@shared/utils';
import {
    createContext,
    PropsWithChildren,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useWebsocketContext } from '@shared/ui/contexts';

interface CameraViewContextProps {
    camera: number;
    image?: string;
}

interface CameraViewProviderProps extends PropsWithChildren {
    enabled?: boolean;
    cameraViews: string[];
}

const CameraViewContext = createContext<CameraViewContextProps | undefined>(
    undefined
);

export const CameraViewContextProvider = (props: CameraViewProviderProps) => {
    const { children, enabled, cameraViews } = props;

    const { socket } = useWebsocketContext();

    const [camera, setCamera] = useState(0);
    const image = useMemo(() => {
        if (!enabled) return;
        if (camera >= cameraViews.length) return;
        return cameraViews[camera];
    }, [camera, cameraViews, enabled]);

    useEffect(() => {
        if (!enabled) return;

        socket.on(BaseEvents.CameraChange, (event: CameraChangeEvent) => {
            if (event.payload.camera >= cameraViews.length) {
                log.info('Camera out of range');
                return;
            }

            setCamera(event.payload.camera);
        });

        return () => {
            socket.off(BaseEvents.CameraChange);
        };
    }, [enabled, cameraViews, socket]);

    return (
        <CameraViewContext.Provider value={{ image, camera }}>
            {children}
        </CameraViewContext.Provider>
    );
};

export const useCameraView = (): CameraViewContextProps => {
    const context = useContext(CameraViewContext);
    if (!context) {
        throw new Error(
            'useCameraView must be used within a CameraViewProvider'
        );
    }
    return context;
};
