import styled from '@emotion/styled';
import clsx from 'clsx';
import {
    useLogicActionContext,
    useLogicStateContext,
} from '@roulette/contexts';
import {
    BoardColor,
    BoardNumber,
    boardNumbers,
    PlayerBaseBet,
} from '@roulette/models';

import { calculateBoxesFitCount } from '@roulette/utils';
import { useEffect, useState } from 'react';

const Container = styled.div<{ activeChip: number | null }>`
    display: flex;
    align-items: center;
    flex-direction: row;
    /* height: 4rem;
    width: 192rem; */
    color: #ffffff;

    pointer-events: ${({ activeChip }) => (activeChip ? 'auto' : 'none')};
    cursor: ${({ activeChip }) => (activeChip ? 'pointer' : 'none')};
`;

const GameResult = styled.div`
    margin-left: 1.1rem;

    pointer-events: auto;
    cursor: pointer;

    .result {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        height: 2.1rem;
        width: 2.1rem;

        font-family: 'Chakra Petch', sans-serif;
        font-size: 1.3rem;
        font-weight: 400;

        background: #14ae5c;
        border-radius: 0.8rem;
        border: 0.1rem solid #63839e;

        &.red-item {
            background-color: black;
            color: #f24822;
        }

        &.black-item {
            background-color: black;
            color: #ffffff;
        }
    }

    @keyframes slidein {
        from {
            margin-left: 192rem;
            width: 192rem;
        }

        to {
            margin-left: 1.1rem;
            width: 192rem;
        }
    }
`;

export const BottomRowChips = () => {
    const { handlePlayerBet } = useLogicActionContext();

    const { activeChip, resultHistory } = useLogicStateContext();

    const [bottomRowNumbers, setBottomRowNumbers] = useState<number[]>([]);

    const handleBet = (bottomrowChosenNumber: number) => {
        handlePlayerBet(PlayerBaseBet.STRAIGHT_UP, [bottomrowChosenNumber]);
    };

    useEffect(() => {
        if (resultHistory.length) {
            const boxLength = calculateBoxesFitCount();
            setBottomRowNumbers(resultHistory.slice(-boxLength));
        }
    }, [resultHistory]);

    return (
        <Container activeChip={activeChip}>
            {bottomRowNumbers.map((number, index) => {
                const value = boardNumbers[number];
                return (
                    <GameResult key={index} onClick={() => handleBet(number)}>
                        <div
                            className={clsx(
                                'result',
                                value.color === BoardColor.RED
                                    ? 'red-item'
                                    : 'black-item'
                            )}
                            key={value.value}
                        >
                            {value.value}
                        </div>
                    </GameResult>
                );
            })}
        </Container>
    );
};
