import { Point } from '@roulette/models';
import { parseSvgPathData } from './parse-svg-path-data';
import { calculatePolygonCentroid } from './calculate-polygon-centroid';

export const findSvgPathCentroid = (
    pathElement: SVGPathElement
): Point | null => {
    const pathData = pathElement.getAttribute('d');

    if (!pathData) {
        console.error('SVG path element does not have a valid "d" attribute.');
        return null;
    }

    const points = parseSvgPathData(pathData);

    if (points.length < 3) {
        console.error(
            'SVG path must have at least three valid points to compute a centroid.'
        );
        return null;
    }

    const centroid = calculatePolygonCentroid(points);
    return centroid;
};
