import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
    useGameContext,
    useLogicActionContext,
    useLogicStateContext,
} from '@roulette/contexts';

import {
    boardNumbers,
    PlayerBaseBet,
    RouletteStates,
    UserBet,
} from '@roulette/models';

import { Chip as SvgChip } from '@shared/ui/components/chips';

import { Selector } from './selector';
import { BoardOverlay as BO } from './board-overlay';

import ChipForward from '@shared/assets/audio/single-chip.mp3';
import ChipReverse from '@shared/assets/audio/single-chip-reverse.mp3';
import { useAudio } from '@shared/ui/contexts';

const CELL_WIDTH = 5.6;
const CELL_HEIGHT = 7;
const EDGE_SIZE = 2;

const TOTAL_ROWS = 3;
const TOTAL_COLUMNS = 14;

const BOTTOM_ROW_HEIGHT = 4.5;

const dealerWinAnimation = (isMobile?: boolean) => keyframes`
    100% {
        transform: ${
            isMobile
                ? `translate(
                    calc(((var(--TOTAL_ROWS) - var(--column) - 10) * var(--cell-height))),
                    calc(((var(--TOTAL_COLUMNS) / 2) - var(--column) + 0.5) * var(--cell-width))
                );`
                : `translate(
                    calc(((var(--TOTAL_COLUMNS) / 2) - var(--column) + 0.5) * var(--cell-width)),
                    calc(((var(--TOTAL_ROWS) - var(--column) - 10) * var(--cell-height)))
    );`
        };
        opacity: 1;
    }
`;

const placeBetAnimation = (isMobile?: boolean) => keyframes`
    0% {
        transform: ${
            isMobile
                ? `translate(
                    calc(((var(--TOTAL_ROWS) - var(--row) + 15) * var(--cell-height))),
                    calc(((var(--TOTAL_COLUMNS) / 2) - var(--column) + 0.5) * var(--cell-width))
                );`
                : `translate(
                    calc(((var(--TOTAL_COLUMNS) / 2) - var(--column) + 0.5) * var(--cell-width)),
                    calc(((var(--TOTAL_ROWS) - var(--column) + 15) * var(--cell-height)))
                );`
        };
        opacity: 1;
    }
`;

const Container = css`
    z-index: 1;
    position: relative;

    .resulted {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        border: 0.3rem solid #00ff73;
        border-radius: 100%;
        box-sizing: border-box;

        height: 4.4rem;
        width: 4.4rem;

        background: rgba(20, 174, 92, 0.15);
        box-shadow: inset 0px 4px 15px rgba(20, 174, 92, 0.3);
    }
`;

const Numbers = css`
    display: flex;
    border-radius: var(--roulette-border-radius);
    box-sizing: border-box;

    &.overlay {
        z-index: 10;
    }

    &.background {
        background: var(--roulette-opaque-background);
        border: 0.05rem solid var(--roulette-border-color);
        backdrop-filter: blur(0.4rem);

        .number-0 {
            display: flex;
            align-items: center;
            justify-content: center;
            width: ${CELL_WIDTH}rem;
            // background-color: var(--roulette-zero-green);

            border-top-left-radius: var(--roulette-border-radius);
            border-bottom-left-radius: var(--roulette-border-radius);
            box-sizing: border-box;
            border: 0.05rem solid var(--roulette-border-color);
        }
    }
`;

const Columns = styled.div<{ isMobile?: boolean }>`
    display: flex;
    box-sizing: border-box;

    flex-direction: column;

    .col {
        box-sizing: border-box;
    }
    .col-1 {
        border-top-right-radius: var(--roulette-border-radius);
    }
    .col-3 {
        border-bottom-right-radius: var(--roulette-border-radius);
    }

    .cell {
        display: flex;
        flex: 1;
        box-sizing: border-box;

        width: ${CELL_WIDTH}rem;

        align-items: center;
        justify-content: center;
    }

    .column-text {
        transform: ${({ isMobile }) =>
            isMobile ? 'rotate(270deg)' : 'rotate(0deg)'};
        display: inline-block;
    }
`;

const BottomSection = css`
    display: flex;
    flex-direction: column;
    position: relative;

    box-sizing: border-box;

    width: ${CELL_WIDTH * 12 + 0.1}rem;
    height: ${BOTTOM_ROW_HEIGHT * 2}rem;

    margin-left: ${CELL_WIDTH + 0.05}rem;

    border-bottom-right-radius: var(--roulette-border-radius);
    border-bottom-left-radius: var(--roulette-border-radius);

    &.background {
        background: var(--roulette-opaque-background);
        backdrop-filter: blur(0.4rem);
        border: 0.05rem solid var(--roulette-border-color);
        border-top: none;
    }

    &.overlay {
        z-index: 10;

        height: ${BOTTOM_ROW_HEIGHT * 2 - EDGE_SIZE / 2}rem;
    }
`;

const Thirds = css`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    flex: 1;

    .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        box-sizing: border-box;
    }
    &.overlay {
        .cell {
            height: ${BOTTOM_ROW_HEIGHT - EDGE_SIZE / 2}rem;
            padding-bottom: ${EDGE_SIZE / 4}rem;
        }
    }

    &.background {
        .cell {
            border: 0.05rem solid var(--roulette-border-color);
            border-top: none;
        }
    }
`;

const Others = css`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;

    flex: 1;

    .cell {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        box-sizing: border-box;
    }

    .red {
        color: var(--roulette-red);
    }

    .chip-container {
        color: #fff;
    }

    &.background {
        .cell {
            border: 0.05rem solid var(--roulette-border-color);

            &.red {
                // background-color: var(--roulette-red);
            }

            &:first-of-type {
                border-bottom-left-radius: var(--roulette-border-radius);
            }

            &:last-of-type {
                border-bottom-right-radius: var(--roulette-border-radius);
            }
        }
    }
`;

const BoardContainer = css`
    display: flex;
    flex-direction: column;
    position: relative;

    font-family: 'Chakra Petch';
    font-size: 1.8rem;
    color: white;

    box-sizing: border-box;

    &.odd-select {
        .odd {
            border-color: var(--roulette-hover);

            &.black {
                background-color: var(--roulette-hover-black);
            }

            &.red {
                background-color: var(--roulette-hover-black);
            }

            &.other {
                background-color: var(--roulette-hover-black);
            }
        }
    }

    &.even-select {
        .even {
            border-color: var(--roulette-hover);
            &.black {
                background-color: var(--roulette-hover-black);
            }
            &.red {
                background-color: var(--roulette-hover-black);
            }

            &.other {
                background-color: var(--roulette-hover-black);
            }
        }
    }

    &.red-select {
        .red,
        .other.red {
            background-color: var(--roulette-hover-black);
            border-color: var(--roulette-hover);
        }
    }

    &.black-select {
        .black {
            background-color: var(--roulette-hover-black);
            border-color: var(--roulette-hover);
        }
    }

    &.lower-half-select {
        .lower-half {
            border-color: var(--roulette-hover);
            background-color: var(--roulette-hover-black);
        }
    }

    &.upper-half-select {
        .upper-half {
            border-color: var(--roulette-hover);
            background-color: var(--roulette-hover-black);
        }
    }

    &.edge-1 {
        .number-2,
        .number-3 {
            border-left: 0.4rem solid var(--roulette-hover);
        }
    }

    &.edge-2 {
        .number-1,
        .number-3 {
            border-left: 0.4rem solid var(--roulette-hover);
        }
    }
    &.edge-3 {
        .number-2,
        .number-1 {
            border-left: 0.4rem solid var(--roulette-hover);
        }
    }
    &.corner-3 {
        .number-1 {
            border-left: 0.4rem solid var(--roulette-hover);
        }
    }
    &.corner-2 {
        .number-3 {
            border-left: 0.4rem solid var(--roulette-hover);
        }
    }
`;

const BoardOverlay = styled.div<{ isMobile: boolean | undefined }>`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    box-sizing: border-box;

    font-family: 'Chakra Petch';
    font-size: 1.8rem;
    color: white;

    .chip-container {
        transform: ${({ isMobile }) => (isMobile ? 'rotate(270deg)' : '')};
    }

    .chip-container-transform {
        transform: rotate(-360deg);
    }
`;

const Row = css`
    display: flex;
    flex-direction: column-reverse;
`;

const Cell = styled.div<{ isMobile?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    width: ${CELL_WIDTH}rem;
    height: ${CELL_HEIGHT}rem;

    border: 0.05rem solid var(--roulette-border-color);

    .number {
        display: flex;
        align-items: center;
        justify-content: center;

        width: ${CELL_WIDTH}rem;
        height: ${CELL_HEIGHT}rem;

        top: 0rem;
        left: -${EDGE_SIZE / 2}rem;

        position: absolute;

        box-sizing: border-box;

        color: #fff;
        font-family: 'Chakra Petch';
        font-weight: medium;
        font-size: 1.8rem;

        pointer-events: none;

        transform: ${({ isMobile }) => (isMobile ? 'rotate(270deg)' : '')};
    }

    .red {
        color: var(--roulette-red);
    }

    &.y-0,
    &.y-1 {
        .number {
            top: -${EDGE_SIZE / 2}rem;
        }
    }

    &.y-0 {
        .chip-container {
            margin-top: -${EDGE_SIZE / 1.5}rem;
        }
    }
    &.y-2 {
        .chip-container {
            margin-top: ${EDGE_SIZE / 1.5}rem;
        }
    }

    &.selected {
        .number {
            color: #75deff;
        }
    }

    &.red {
        // background-color: var(--roulette-red);
    }

    &.overlay {
        width: ${CELL_WIDTH - EDGE_SIZE}rem;
        height: ${CELL_HEIGHT - EDGE_SIZE}rem;

        position: relative;
        border: none;

        .chip-container {
            display: flex;
            position: absolute;

            align-items: center;
            justify-content: center;

            width: ${CELL_WIDTH}rem;
            height: ${CELL_HEIGHT}rem;

            margin-left: 0.25rem;

            pointer-events: none;

            .chip {
                display: block;

                z-index: 11;
            }
        }

        &.y-0 {
            .chip-container {
                margin-top: -${EDGE_SIZE / 2 - 0.05}rem;
            }
        }

        &.end-cols {
            width: ${CELL_WIDTH - EDGE_SIZE / 2}rem;

            .chip-container {
                margin-right: ${EDGE_SIZE / 2}rem;
            }
        }

        &.end-rows {
            height: ${CELL_HEIGHT - EDGE_SIZE / 2}rem;
        }

        &.number-0 {
            height: ${CELL_HEIGHT * 3 + EDGE_SIZE / 2}rem;
            .number {
                height: ${CELL_HEIGHT * 3}rem;
                padding-left: ${EDGE_SIZE}rem;
                color: var(--roulette-zero-green);
            }

            .chip-container {
                margin-left: ${EDGE_SIZE / 2}rem;
                margin-right: 0rem;
            }
        }

        &.edge-y-0 {
            .chip-container {
                margin-top: -${EDGE_SIZE / 2}rem;
            }
        }
        &.edge-y-4 {
            .chip-container {
                margin-top: ${EDGE_SIZE / 2}rem;
            }
        }
    }

    &.edge-row {
        height: ${EDGE_SIZE}rem;
        width: 100%;
    }

    &.edge-column {
        width: ${EDGE_SIZE}rem;
    }

    &.corner,
    &.double-street {
        width: ${EDGE_SIZE}rem;
        height: ${EDGE_SIZE}rem;
    }
`;

interface ChipContainerProps {
    value?: number;
    centerX?: number;
    centerY?: number;
    column?: number;
    row?: number;
    cellWidth?: number;
    cellHeight?: number;
    isMobile?: boolean;
    animate?: boolean;
    resulted?: number | null;
}

const chipContainerStyle = css`
    position: absolute;
    width: ${CELL_WIDTH * 0.75}rem;
    height: ${CELL_WIDTH * 0.75}rem;
`;

const getAnimationStyles = (props: ChipContainerProps) => {
    if (props.resulted || props.resulted === 0) {
        return props.animate
            ? css`
                  animation: ${placeBetAnimation(props.isMobile)} 1s ease-in
                      reverse;
              `
            : css`
                  animation: ${dealerWinAnimation(props.isMobile)} 500ms ease-in;
              `;
    }
    return css`
        animation: ${placeBetAnimation(props.isMobile)} 300ms ease-in forwards;
    `;
};

const textDynamicStyles = (isMobile?: boolean, applyTransform?: boolean) => css`
    transform: ${applyTransform
        ? 'rotate(-360deg)'
        : isMobile
        ? ''
        : 'none'}; // No rotation otherwise
`;

const ChipContainer = styled.div<ChipContainerProps>`
    --centerX: ${(props) => props.centerX}rem;
    --centerY: ${(props) => props.centerY}rem;
    --column: ${(props) => props.column};
    --row: ${(props) => props.row};
    --cell-width: ${(props) => props.cellWidth}rem;
    --cell-height: ${(props) => props.cellHeight}rem;
    --TOTAL_COLUMNS: ${TOTAL_COLUMNS};
    --TOTAL_ROWS: ${TOTAL_ROWS};
    position: absolute;

    pointer-events: auto;

    ${chipContainerStyle}
    ${(props) => getAnimationStyles(props)}
`;

const TextStyled = styled.span<ChipContainerProps>`
    ${(props) => textDynamicStyles(props.isMobile)}
`;

const Chip = (props: {
    value: number;
    isMobile?: boolean;
    animate?: boolean;
    resulted?: number | null;
    column?: number;
    row?: number;
}) => {
    const chipRef = useRef<HTMLDivElement>(null);

    const centerX =
        (props && props.row ? props.row : 0) - TOTAL_ROWS * CELL_HEIGHT;
    const centerY =
        ((props && props.column ? props.column : 0) - TOTAL_COLUMNS / 2) *
        CELL_WIDTH;

    return (
        <ChipContainer
            draggable={true}
            value={props.value}
            centerX={centerX}
            centerY={centerY}
            column={props.column}
            row={props.row}
            cellWidth={CELL_WIDTH}
            cellHeight={CELL_HEIGHT}
            isMobile={props.isMobile}
            animate={props.animate || false}
            resulted={props.resulted}
            ref={chipRef}
            className="chip-container"
        >
            <TextStyled isMobile={props.isMobile}>
                <SvgChip value={props.value} size={`small`} />
            </TextStyled>
        </ChipContainer>
    );
};

const CHIP_SOUNDS = [ChipForward, ChipReverse];

const ORDER = [
    1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 1, 0, 1, 0, 1,
    0, 1, 0, 0, 1, 0, 1, 0, 1, 0, 1,
];

const generateBoard = () => {
    const rows = [];
    for (let c = 0; c < 12; c += 1) {
        const cells = [];
        for (let r = 0; r < 3; r += 1) {
            const number = c * 3 + r;

            cells.push(
                <Cell
                    key={`number-${number}`}
                    className={clsx(
                        ORDER[number] ? 'red' : 'black',
                        number % 2 === 0 ? 'odd' : 'even',
                        `number-${number + 1}`,
                        `x-${c}`,
                        `y-${r}`
                    )}
                ></Cell>
            );
        }
        rows.push(
            <div css={Row} key={`row-${c}`}>
                {cells}
            </div>
        );
    }
    return rows;
};

const board = generateBoard();

const CELL_VALUE = [2, 2, 1, 1, 0, 0];

const getSelect = (type: string, x: number, y: number) => {
    if (type === 'street') {
        return { col: x, row: y - 2, cols: 1, rows: 3 };
    }
    if (type === 'double-street') {
        return { col: x, row: y - 2, cols: 2, rows: 3 };
    }

    if (type === 'corner') {
        return { col: x, row: y, cols: 2, rows: 2 };
    }

    if (type === 'split-horizontal') {
        return { col: x, row: y, cols: 2, rows: 1 };
    }

    if (type === 'split-vertical') {
        return { col: x, row: y, cols: 1, rows: 2 };
    }
    return { col: x, row: y, cols: 1, rows: 1 };
};

const getBetNumbers = (number: number, type: string) => {
    const values = [Math.max(0, number)];
    switch (type) {
        case 'corner':
            values.push(number + 3);
            values.push(number - 1);
            values.push(number + 2);
            break;
        case 'split-horizontal':
            values.push(number + 3);
            break;
        case 'split-vertical':
            values.push(number - 1);
            break;
        case 'street':
            values.push(number + 1);
            values.push(number + 2);
            break;
        case 'double-street':
            values.push(number + 1);
            values.push(number + 2);
            values.push(number + 3);
            values.push(number + 4);
            values.push(number + 5);
            break;
    }

    return [...new Set(values.filter((f) => f > -1))]; // Convert to array of unique values
};

const aggregateBets = (bets: UserBet[], number: number) => {
    const aggregated = bets.reduce((acc, bet) => {
        if (
            Array.isArray(bet.betValues) &&
            bet.betValues.includes(number) &&
            bet.type === PlayerBaseBet.STRAIGHT_UP
        ) {
            const key = bet.betValues.join(',');
            if (!acc[key]) {
                acc[key] = { amount: 0, betValues: bet.betValues };
            }
            acc[key].amount += bet.amount;
        }
        return acc;
    }, {} as Record<string, { amount: number; betValues: number[] }>);

    return Object.values(aggregated);
};

const calculateValue = (
    bets: UserBet[],
    condition: (bet: UserBet) => boolean
): number => {
    return bets.reduce((acc, curr) => {
        if (condition(curr)) {
            return acc + curr.amount;
        }
        return acc;
    }, 0);
};

const generateOverlay = (
    onClick: (type: PlayerBaseBet, values: number[]) => () => void,
    onHover: (
        row?: number,
        column?: number,
        rows?: number,
        columns?: number
    ) => () => void,
    selectedNumbers: number[],
    bets: { [key: string]: number },
    ballPosition: number | null,
    isMobile?: boolean,
    lastBet?: UserBet[]
) => {
    const rows = [];
    for (let c = 0; c < 13; c += 1) {
        const cells = [];

        const winAmount =
            lastBet?.length &&
            lastBet.reduce((total, bet) => {
                if (
                    bet &&
                    Array.isArray(bet.betValues) &&
                    ballPosition !== null &&
                    bet.betValues.length === 1
                ) {
                    return bet.betValues.includes(ballPosition)
                        ? total + bet.amount
                        : total;
                }
                return total;
            }, 0);

        if (c === 0) {
            const chip = bets['0'];
            cells.push(
                <Cell
                    key={`overlay-number-0`}
                    className={clsx(
                        'overlay',
                        'number-0',
                        'end-cols',
                        'end-rows',
                        {
                            selected: selectedNumbers.includes(0),
                        }
                    )}
                    onMouseOver={onHover(0, 0, 3, 1)}
                    onClick={onClick(PlayerBaseBet.STRAIGHT_UP, [0])}
                >
                    <div className="number">
                        <div
                            className={clsx(
                                ballPosition === 0 ? 'resulted' : ''
                            )}
                        >
                            0
                        </div>
                    </div>
                    {chip && (
                        <Chip
                            value={chip}
                            column={c}
                            row={0}
                            isMobile={isMobile}
                        />
                    )}
                    {(ballPosition || ballPosition === 0) &&
                        lastBet?.map((bet) => {
                            const shouldAnimate =
                                ballPosition === 0 ? true : false;

                            return bet &&
                                bet.type === PlayerBaseBet.STRAIGHT_UP &&
                                Array.isArray(bet.betValues) &&
                                bet.betValues[0] === 0 ? (
                                <Chip
                                    key={`chip-${bet.uuid}`}
                                    value={winAmount ? winAmount : bet.amount}
                                    animate={shouldAnimate}
                                    resulted={ballPosition}
                                    column={c}
                                    row={0}
                                    isMobile={isMobile}
                                />
                            ) : null;
                        })}
                </Cell>
            );
        } else {
            for (let r = 0; r < 3; r += 1) {
                const number = (c - 1) * 3 + r + 1;

                {
                    const key = getBetNumbers(
                        number,
                        r === 0 ? 'street' : 'split-vertical'
                    ).join('-');
                    const chipValue = bets[key];

                    const select = getSelect(
                        r === 0 ? 'street' : 'split-vertical',
                        c,
                        2 - r
                    );

                    cells.push(
                        <Cell
                            key={`edge-vertical-${key}`}
                            className="overlay edge-row"
                            onMouseOver={onHover(
                                select.row,
                                select.col,
                                select.rows,
                                select.cols
                            )}
                            onClick={onClick(
                                r === 0
                                    ? PlayerBaseBet.STREET
                                    : PlayerBaseBet.SPLIT,
                                getBetNumbers(
                                    number,
                                    r === 0 ? 'street' : 'split-vertical'
                                )
                            )}
                        >
                            {chipValue && (
                                <Chip
                                    value={chipValue}
                                    column={c}
                                    row={r}
                                    isMobile={isMobile}
                                />
                            )}

                            {(ballPosition || ballPosition === 0) &&
                                Array.isArray(lastBet) &&
                                lastBet.map((bet) => {
                                    if (
                                        Array.isArray(bet.betValues) &&
                                        bet.betValues.every(
                                            (number, i) =>
                                                number ===
                                                key.split('-').map(Number)[i]
                                        ) &&
                                        bet.type ===
                                            (r === 0
                                                ? PlayerBaseBet.STREET
                                                : PlayerBaseBet.SPLIT)
                                    ) {
                                        const shouldAnimate =
                                            bet.betValues.includes(
                                                ballPosition
                                            ) &&
                                            bet.betValues.every(
                                                (number, i) =>
                                                    number ===
                                                    key.split('-').map(Number)[
                                                        i
                                                    ]
                                            ) &&
                                            bet.type ===
                                                (r === 0
                                                    ? PlayerBaseBet.STREET
                                                    : PlayerBaseBet.SPLIT);

                                        return bet &&
                                            bet.type ===
                                                (r === 0
                                                    ? PlayerBaseBet.STREET
                                                    : PlayerBaseBet.SPLIT) ? (
                                            <Chip
                                                key={`chip-${bet.uuid}`}
                                                value={lastBet.reduce(
                                                    (acc, curr) => {
                                                        if (
                                                            curr.type ===
                                                                (r === 0
                                                                    ? PlayerBaseBet.STREET
                                                                    : PlayerBaseBet.SPLIT) &&
                                                            Array.isArray(
                                                                curr.betValues
                                                            ) &&
                                                            curr.betValues.every(
                                                                (number, i) =>
                                                                    number ===
                                                                    key
                                                                        .split(
                                                                            '-'
                                                                        )
                                                                        .map(
                                                                            Number
                                                                        )[i]
                                                            )
                                                        ) {
                                                            return (
                                                                acc +
                                                                curr.amount
                                                            );
                                                        }
                                                        return acc;
                                                    },
                                                    0
                                                )}
                                                animate={shouldAnimate}
                                                resulted={ballPosition}
                                                column={c}
                                                row={r}
                                                isMobile={isMobile}
                                            />
                                        ) : (
                                            <Chip
                                                value={bet.amount}
                                                animate={false}
                                                resulted={ballPosition}
                                                column={c}
                                                row={r}
                                                isMobile={isMobile}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                        </Cell>
                    );
                }

                const chipValue = bets[number.toString()];

                cells.push(
                    <Cell
                        key={`overlay-number-${number}`}
                        className={clsx('overlay', `y-${r}`, `x-${c}`, {
                            'end-cols': c === 0 || c === 12,
                            'end-rows': r === 2,
                            selected: selectedNumbers.includes(number),
                        })}
                        onMouseOver={onHover(2 - r, c, 1, 1)}
                        onClick={onClick(PlayerBaseBet.STRAIGHT_UP, [number])}
                        isMobile={isMobile}
                    >
                        <div
                            className={clsx(
                                'number',
                                ORDER[number - 1] ? 'red' : 'black'
                            )}
                        >
                            <div
                                className={clsx(
                                    ballPosition === number ? 'resulted' : ''
                                )}
                            >
                                {number}
                            </div>
                        </div>
                        {chipValue && (
                            <Chip
                                value={chipValue}
                                resulted={ballPosition}
                                column={c}
                                row={r}
                                isMobile={isMobile}
                            />
                        )}

                        {/* Display chips for all placed bets */}
                        {(ballPosition || ballPosition === 0) &&
                            lastBet &&
                            aggregateBets(lastBet, number).map(
                                (aggregatedBet, index) => (
                                    <Chip
                                        key={`chip-${index}`}
                                        value={aggregatedBet.amount}
                                        animate={ballPosition === number}
                                        resulted={ballPosition}
                                        column={c}
                                        row={r}
                                        isMobile={isMobile}
                                    />
                                )
                            )}

                        {/* Display an additional chip if this is the resulted number */}
                        {ballPosition === number && chipValue && (
                            <Chip
                                value={winAmount || 0}
                                animate={true}
                                resulted={ballPosition}
                                column={c}
                                row={r}
                                isMobile={isMobile}
                            />
                        )}
                    </Cell>
                );
            }
        }

        if (c > 0) {
            const rc = [];
            for (let i = 0; i < 6; i += 1) {
                const EDGE_TYPES = [
                    'double-street',
                    'split-horizontal',
                    'corner',
                    'split-horizontal',
                    'corner',
                    'split-horizontal',
                ];

                const BET_TYPES: {
                    [key: string]: PlayerBaseBet;
                } = {
                    'split-vertical': PlayerBaseBet.SPLIT,
                    'split-horizontal': PlayerBaseBet.SPLIT,
                    corner: PlayerBaseBet.CORNER,
                    street: PlayerBaseBet.STREET,
                    'double-street': PlayerBaseBet.DOUBLE_STREET,
                };

                const type = EDGE_TYPES[i];

                const col = c - 1; // x
                const row = CELL_VALUE[i];

                // const cols = 2;
                // const rows = type === 'corner' ? 2 : 1;

                const select = getSelect(type, col, row);

                const number = (col - 1) * 3 + (3 - row);

                const values = getBetNumbers(number, type);

                const key = values.join('-');

                const chipValue = bets[key];

                const betType =
                    number <= 0 && type === 'corner' // Handle 0,1,3 & 0,2,3
                        ? PlayerBaseBet.STREET
                        : number <= 0 && type === 'double-street' // Handle 0,1,2,3
                        ? PlayerBaseBet.CORNER
                        : BET_TYPES[type];

                rc.push(
                    <Cell
                        key={`edge-${key}`}
                        className={clsx(
                            'overlay',
                            'edge-column',
                            `edge-x-${c}`,
                            `edge-y-${i}`,
                            type,
                            {
                                'end-rows': i === 5,
                            }
                        )}
                        onMouseOver={onHover(
                            select.row,
                            select.col,
                            select.rows,
                            select.cols
                        )}
                        onClick={onClick(betType, values)}
                    >
                        {chipValue && (
                            <Chip
                                value={chipValue}
                                column={col}
                                row={row}
                                isMobile={isMobile}
                            />
                        )}

                        {(ballPosition || ballPosition === 0) &&
                            Array.isArray(lastBet) &&
                            lastBet.map((bet) => {
                                if (
                                    Array.isArray(bet.betValues) &&
                                    // bet.betValues.length === 2 &&
                                    bet.betValues.every(
                                        (number, i) => number === values[i]
                                    ) &&
                                    (bet.type === PlayerBaseBet.SPLIT ||
                                        bet.type === PlayerBaseBet.CORNER)
                                ) {
                                    const shouldAnimate =
                                        bet.betValues.includes(ballPosition) &&
                                        bet.betValues.every(
                                            (number, i) => number === values[i]
                                        ) &&
                                        bet.type === betType;

                                    return bet && bet.type === betType ? (
                                        <Chip
                                            key={`chip-${bet.uuid}`}
                                            value={lastBet.reduce(
                                                (acc, curr) => {
                                                    if (
                                                        curr.type === betType &&
                                                        Array.isArray(
                                                            curr.betValues
                                                        ) &&
                                                        curr.betValues.every(
                                                            (number, i) =>
                                                                number ===
                                                                values[i]
                                                        )
                                                    ) {
                                                        return (
                                                            acc + curr.amount
                                                        );
                                                    }
                                                    return acc;
                                                },
                                                0
                                            )}
                                            animate={shouldAnimate}
                                            resulted={ballPosition}
                                            column={col}
                                            row={row}
                                            isMobile={isMobile}
                                        />
                                    ) : (
                                        bet && bet.type === betType && (
                                            <Chip
                                                value={bet.amount}
                                                animate={false}
                                                resulted={ballPosition}
                                                column={col}
                                                row={row}
                                                isMobile={isMobile}
                                            />
                                        )
                                    );
                                }
                                return null;
                            })}
                    </Cell>
                );
            }
            rows.push(
                <div css={Row} key={`row-edge-${c}`}>
                    {rc}
                </div>
            );
        }

        rows.push(
            <div css={Row} key={`overlay-row-${c}`}>
                {cells}
            </div>
        );
    }
    return rows;
};

interface Props {
    isMobile?: boolean;
}

export const Board = (props: Props) => {
    const [selected, setSelected] = useState<{
        row: number;
        col: number;
        rows: number;
        cols: number;
    } | null>(null);

    const [hovered, setHovered] = useState<string | null>(null);

    const { activeChip } = useLogicStateContext();

    const { effectsVolume } = useAudio();

    const {
        reducedBets: betHistory,
        lastBet,
        baseBetTotal,
    } = useLogicStateContext();

    const { handlePlayerBet } = useLogicActionContext();
    const { gameState } = useGameContext();

    const [playerBetHistory, setPlayerBetHistory] = useState<UserBet[]>([]);

    const [resultedColor, setResultedColor] = useState('');

    useEffect(() => {
        if (gameState?.ballPosition || gameState?.ballPosition === 0) {
            const res = boardNumbers
                .filter((numbers) => numbers.value === gameState.ballPosition)
                .flatMap((nunmbers) => nunmbers.color);
            setResultedColor(res[0]);
        }
    }, [gameState?.ballPosition]);

    useEffect(() => {
        if (betHistory.length > 0) {
            setPlayerBetHistory(betHistory);
        } else {
            const timeoutId = setTimeout(() => setPlayerBetHistory([]), 400);
            return () => clearTimeout(timeoutId);
        }
    }, [betHistory]);

    useEffect(() => {
        if (
            playerBetHistory.length > 0 &&
            gameState.currentState.next_state === RouletteStates.GameEnd &&
            gameState.ballPosition
        ) {
            const sound = new Audio(CHIP_SOUNDS[1]);
            sound.volume = 0.4 * effectsVolume;
            sound.play();
        }
    }, [
        gameState.currentState,
        gameState.ballPosition,
        playerBetHistory.length,
        effectsVolume,
    ]);

    const onHover = useCallback(
        (
            row?: number,
            col?: number,
            rows?: number,
            cols?: number,
            className?: string
        ) => {
            return () => {
                if (activeChip === null) return;

                if (className !== undefined) {
                    setHovered(className);
                } else {
                    setHovered(null);
                }
                if (
                    row !== undefined &&
                    col !== undefined &&
                    rows !== undefined &&
                    cols !== undefined
                ) {
                    setSelected({ row, col, rows, cols });

                    return;
                }

                setSelected(null);
            };
        },
        [activeChip]
    );

    const onHoverClassName = (className: string) => {
        return onHover(undefined, undefined, undefined, undefined, className);
    };

    const selectedNumbers = useMemo(() => {
        if (!selected) return [];

        const values = [];

        if (selected.col === 0) {
            values.push(0);
        }

        for (let r = selected.row; r < selected.row + selected.rows; r += 1) {
            for (
                let c = selected.col;
                c < selected.col + selected.cols;
                c += 1
            ) {
                const value = (c - 1) * 3 + (3 - r);
                values.push(value);
            }
        }
        return values;
    }, [selected]);

    const bets = useMemo(() => {
        return betHistory.reduce((acc: { [key: string]: number }, cur) => {
            if (
                [
                    PlayerBaseBet.STRAIGHT_UP,
                    PlayerBaseBet.SPLIT,
                    PlayerBaseBet.CORNER,
                    PlayerBaseBet.STREET,
                    PlayerBaseBet.DOUBLE_STREET,
                ].includes(cur.type) &&
                cur.betValues !== null
            ) {
                const key = cur.betValues.join('-');
                return { ...acc, [key]: cur.amount };
            }
            const existing = acc[cur.type];
            acc[cur.type] = (existing || 0) + cur.amount;

            return { ...acc, [cur.type]: cur.amount };
        }, {});
    }, [betHistory]);

    const onClick = useCallback(
        (type: PlayerBaseBet, values: number[]) => {
            return () => {
                if (activeChip === null) return;
                handlePlayerBet(type, values);

                if (
                    gameState.gameInfo?.maxBet &&
                    baseBetTotal + activeChip <= gameState.gameInfo?.maxBet
                ) {
                    const sound = new Audio(CHIP_SOUNDS[0]);
                    sound.volume = 0.4 * effectsVolume;
                    sound.play();
                }
            };
        },
        [
            handlePlayerBet,
            activeChip,
            baseBetTotal,
            gameState.gameInfo,
            effectsVolume,
        ]
    );

    const selectedClassName = useMemo(() => {
        if (!selected) return null;
        if (selected.col !== 0) return;
        if (selected.row === 2 && selected.rows === 1 && selected.cols > 1)
            return 'edge-1';
        if (selected.row === 1 && selected.rows === 1 && selected.cols > 1)
            return 'edge-2';
        if (selected.row === 0 && selected.rows === 1 && selected.cols > 1)
            return 'edge-3';
        // if (selected.row === 0 && selected.rows > 1 && selected.cols > 1)
        //   return 'corner-3';
        if (selected.row === 1 && selected.rows > 1 && selected.cols > 1)
            return 'corner-2';
        return null;
    }, [selected]);

    useEffect(() => {
        if (activeChip === null) {
            setSelected(null);
            setHovered(null);
        }
    }, [activeChip]);

    return (
        <div css={Container}>
            <div
                className={clsx(hovered, selectedClassName)}
                css={BoardContainer}
            >
                {selected && <Selector {...selected} />}
                <div className="background" css={Numbers}>
                    <div className="number-0"></div>
                    {board}
                    <Columns>
                        <Cell className="col col-1"></Cell>
                        <Cell className="col col-2"></Cell>
                        <Cell className="col col-3"></Cell>
                    </Columns>
                </div>
                <div className="background" css={BottomSection}>
                    <div className="background" css={Thirds}>
                        <div className="cell"></div>
                        <div className="cell"></div>
                        <div className="cell"></div>
                    </div>
                    <div className="background" css={Others}>
                        <div className="cell lower-half other"></div>
                        <div className="cell even other"></div>
                        <div className="cell red other"></div>
                        <div className="cell black other"></div>
                        <div className="cell odd other"></div>
                        <div className="cell upper-half other"></div>
                    </div>
                </div>
            </div>
            <BoardOverlay onMouseOut={onHover()} isMobile={props.isMobile}>
                <div className="overlay" css={Numbers}>
                    {generateOverlay(
                        onClick,
                        onHover,
                        selectedNumbers,
                        bets,
                        gameState?.ballPosition,
                        props.isMobile,
                        playerBetHistory.length ? lastBet : []
                    )}
                    <Columns isMobile={props.isMobile}>
                        <div
                            className="cell"
                            onMouseOver={onHover(0, 1, 1, 13)}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.THIRD_COLUMN, [])}
                        >
                            <span className="column-text">2:1</span>
                            {bets[PlayerBaseBet.THIRD_COLUMN] && (
                                <Chip
                                    value={bets[PlayerBaseBet.THIRD_COLUMN]}
                                    column={13}
                                    row={1}
                                    isMobile={props.isMobile}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition % 3 === 0
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type ===
                                              PlayerBaseBet.THIRD_COLUMN ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.THIRD_COLUMN
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={13}
                                              row={1}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell"
                            onMouseOver={onHover(1, 1, 1, 13)}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.SECOND_COLUMN, [])}
                        >
                            <span className="column-text">2:1</span>
                            {bets[PlayerBaseBet.SECOND_COLUMN] && (
                                <Chip
                                    value={bets[PlayerBaseBet.SECOND_COLUMN]}
                                    isMobile={props.isMobile}
                                    column={13}
                                    row={1}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition % 3 === 2
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type ===
                                              PlayerBaseBet.SECOND_COLUMN ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.SECOND_COLUMN
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={13}
                                              row={1}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell"
                            onMouseOver={onHover(2, 1, 1, 13)}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.FIRST_COLUMN, [])}
                        >
                            <span className="column-text">2:1</span>
                            {bets[PlayerBaseBet.FIRST_COLUMN] && (
                                <Chip
                                    value={bets[PlayerBaseBet.FIRST_COLUMN]}
                                    isMobile={props.isMobile}
                                    column={13}
                                    row={1}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition % 3 === 1
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type ===
                                              PlayerBaseBet.FIRST_COLUMN ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.FIRST_COLUMN
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={13}
                                              row={1}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                    </Columns>
                </div>
                <div className="overlay" css={BottomSection}>
                    <div className="overlay" css={Thirds}>
                        <div
                            className="cell"
                            onMouseOver={onHover(0, 1, 4, 4)}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.FIRST_TWELVE, [])}
                        >
                            1st 12
                            {bets[PlayerBaseBet.FIRST_TWELVE] && (
                                <Chip
                                    value={bets[PlayerBaseBet.FIRST_TWELVE]}
                                    column={4}
                                    row={4}
                                    isMobile={props.isMobile}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition > 0 &&
                                          gameState?.ballPosition <= 12
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type ===
                                              PlayerBaseBet.FIRST_TWELVE ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.FIRST_TWELVE
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={4}
                                              row={4}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell"
                            onMouseOver={onHover(0, 5, 4, 4)}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.SECOND_TWELVE, [])}
                        >
                            2nd 12
                            {bets[PlayerBaseBet.SECOND_TWELVE] && (
                                <Chip
                                    value={bets[PlayerBaseBet.SECOND_TWELVE]}
                                    column={4}
                                    row={4}
                                    isMobile={props.isMobile}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition > 12 &&
                                          gameState?.ballPosition <= 24
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type ===
                                              PlayerBaseBet.SECOND_TWELVE ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.SECOND_TWELVE
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={4}
                                              row={4}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell"
                            onMouseOver={onHover(0, 9, 4, 4)}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.THIRD_TWELVE, [])}
                        >
                            3rd 12
                            {bets[PlayerBaseBet.THIRD_TWELVE] && (
                                <Chip
                                    value={bets[PlayerBaseBet.THIRD_TWELVE]}
                                    column={12}
                                    row={4}
                                    isMobile={props.isMobile}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition > 24 &&
                                          gameState?.ballPosition <= 36
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type ===
                                              PlayerBaseBet.THIRD_TWELVE ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.THIRD_TWELVE
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={4}
                                              row={4}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                    </div>
                    <div className="overlay" css={Others}>
                        <div
                            className="cell"
                            onMouseOver={onHover(
                                0,
                                1,
                                3,
                                6,
                                'lower-half-select'
                            )}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.LOW, [])}
                        >
                            1-18
                            {bets[PlayerBaseBet.LOW] && (
                                <Chip
                                    value={bets[PlayerBaseBet.LOW]}
                                    column={2}
                                    row={3}
                                    isMobile={props.isMobile}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition > 0 &&
                                          gameState?.ballPosition <= 18
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type === PlayerBaseBet.LOW ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.LOW
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={2}
                                              row={3}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell"
                            onMouseOver={onHoverClassName('even-select')}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.EVEN, [])}
                        >
                            Even
                            {bets[PlayerBaseBet.EVEN] && (
                                <Chip
                                    value={bets[PlayerBaseBet.EVEN]}
                                    column={4}
                                    row={3}
                                    isMobile={props.isMobile}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition % 2 === 0
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type === PlayerBaseBet.EVEN ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.EVEN
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={4}
                                              row={3}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell red"
                            onMouseOver={onHoverClassName('red-select')}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.RED, [])}
                        >
                            {bets[PlayerBaseBet.RED] && (
                                <Chip
                                    value={bets[PlayerBaseBet.RED]}
                                    column={6}
                                    row={3}
                                    isMobile={props.isMobile}
                                />
                            )}
                            Red
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          resultedColor === PlayerBaseBet.RED
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type === PlayerBaseBet.RED ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.RED
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={6}
                                              row={3}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell black"
                            onMouseOver={onHoverClassName('black-select')}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.BLACK, [])}
                        >
                            {bets[PlayerBaseBet.BLACK] && (
                                <Chip
                                    value={bets[PlayerBaseBet.BLACK]}
                                    column={8}
                                    row={3}
                                    isMobile={props.isMobile}
                                />
                            )}
                            Black
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          resultedColor === PlayerBaseBet.BLACK
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type === PlayerBaseBet.BLACK ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.BLACK
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={8}
                                              row={3}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell odd"
                            onMouseOver={onHoverClassName('odd-select')}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.ODD, [])}
                        >
                            Odd
                            {bets[PlayerBaseBet.ODD] && (
                                <Chip
                                    value={bets[PlayerBaseBet.ODD]}
                                    column={10}
                                    row={3}
                                    isMobile={props.isMobile}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition % 2 !== 0
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type === PlayerBaseBet.ODD ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.ODD
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={10}
                                              row={3}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                        <div
                            className="cell"
                            onMouseOver={onHover(
                                0,
                                7,
                                3,
                                6,
                                'upper-half-select'
                            )}
                            onMouseOut={onHover()}
                            onClick={onClick(PlayerBaseBet.HIGH, [])}
                        >
                            19-36
                            {bets[PlayerBaseBet.HIGH] && (
                                <Chip
                                    value={bets[PlayerBaseBet.HIGH]}
                                    column={12}
                                    row={3}
                                    isMobile={props.isMobile}
                                />
                            )}
                            {playerBetHistory.length
                                ? (gameState?.ballPosition ||
                                      gameState?.ballPosition === 0) &&
                                  lastBet?.map((bet) => {
                                      const shouldAnimate =
                                          gameState?.ballPosition &&
                                          gameState?.ballPosition > 18 &&
                                          gameState?.ballPosition <= 36
                                              ? true
                                              : false;

                                      return bet &&
                                          bet.type === PlayerBaseBet.HIGH ? (
                                          <Chip
                                              key={`chip-${bet.uuid}`}
                                              value={calculateValue(
                                                  lastBet,
                                                  (bet) =>
                                                      bet.type ===
                                                      PlayerBaseBet.HIGH
                                              )}
                                              animate={shouldAnimate}
                                              resulted={gameState?.ballPosition}
                                              column={12}
                                              row={3}
                                              isMobile={props.isMobile}
                                          />
                                      ) : null;
                                  })
                                : ''}
                        </div>
                    </div>
                </div>
            </BoardOverlay>
        </div>
    );
};

const C2 = styled.div`
    position: relative;
`;

export const BoardNew = () => {
    return (
        <C2>
            <BO />
            <BO overlay />
        </C2>
    );
};
