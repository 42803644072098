import styled from '@emotion/styled';
import { SettingMenuItems } from './settings.type';
import { ChatSettings } from './settings-modal-content-chat';
import { AudioSettings } from './settings-modal-content-audio';
import { OtherSettings } from './settings-modal-content-other-settings';
import { HistorySettings } from './settings-modal-content-history';
import { InfoSettings } from './settings-modal-content-info';

import { ReactComponent as HistoryIcon } from '../../assets/icons/History.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/Info.svg';
import { forwardRef } from 'react';

const Container = styled.div<{
    isOpen: boolean;
    isMobile: boolean;
    height: string;
    compact: boolean;
}>`
    position: ${({ compact }) => (compact ? 'flex' : 'absolute')};
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    top: ${({ isMobile, compact }) =>
        compact ? '0rem' : isMobile ? '50%' : '5.2rem'};
    left: 0;
    right: 0;
    padding: 2.6rem 1.5rem 2.6rem 3rem;
    // min-width: 30rem; relies on the width of setting-buttons-row
    min-height: 5rem;
    height: ${({ height }) => (height ? height : '30rem')};
    max-height: 30rem;
    border-radius: 8px;
    border-top: 2px solid var(--color-secondary);
    background: rgba(18, 18, 18, 0.91);
    transform: ${({ isMobile, height }) =>
        isMobile
            ? height === '4rem'
                ? 'rotate(-90deg) translate(-12.5rem, -15rem)'
                : 'rotate(-90deg) translateY(-2.5rem)'
            : ''};
    overflow: hidden;
    z-index: 51;

    & svg {
        color: var(--color-primary);
        transform: ${({ isMobile }) => (isMobile ? 'rotate(360deg)' : '')};
    }

    > div {
        padding-right: 1.5rem;
    }
`;

const Heading = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1.8rem;
    font-family: 'Chakra Petch', sans-serif;
    font-weight: 400;
    color: #fff;
    margin-bottom: 1rem;

    & svg {
        color: var(--color-primary);
    }
`;

interface SettingsModalProps {
    activeSetting: SettingMenuItems | null;
    isMobile: boolean;
    compact?: boolean;
}

export const SettingsModal = forwardRef<HTMLDivElement, SettingsModalProps>(
    ({ activeSetting, isMobile, compact = false }, ref) => {
        if (!activeSetting) return null;

        let icon;
        let heading;
        let content;
        let shouldRotate = false;
        let height = '30rem';

        switch (activeSetting) {
            case SettingMenuItems.CHAT:
                heading = 'Chat Settings';
                content = <ChatSettings />;
                shouldRotate = true;
                break;
            case SettingMenuItems.AUDIO:
                content = <AudioSettings />;
                shouldRotate = true;
                height = '21rem';
                break;
            case SettingMenuItems.OTHER:
                heading = 'Other Settings';
                content = <OtherSettings />;
                shouldRotate = true;
                break;
            case SettingMenuItems.HISTORY:
                icon = <HistoryIcon width="3.2rem" height="3.2rem" />;
                heading = 'Game History';
                content = <HistorySettings />;
                shouldRotate = true;
                break;
            case SettingMenuItems.INFO:
                icon = <InfoIcon width="3rem" height="3rem" />;
                heading = 'Information';
                content = <InfoSettings />;
                shouldRotate = true;
                break;
            default:
                content = null;
        }

        return (
            <Container
                ref={ref}
                className="modal"
                isOpen={activeSetting != null}
                isMobile={isMobile && shouldRotate}
                height={height}
                compact={compact}
            >
                {!compact && (
                    <Heading>
                        {icon && icon}
                        {heading && heading.toUpperCase()}
                    </Heading>
                )}
                {content && content}
            </Container>
        );
    }
);

SettingsModal.displayName = 'SettingsModal';
