import styled from '@emotion/styled';
import { useLogicStateContext } from '@roulette/contexts';

const Container = styled.div`
    height: 32.2rem;
    width: 30.9rem;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    font-size: 1rem;
    line-height: 1.7rem;
    font-weight: 500;
    font-family: 'Chakra Petch', sans-serif;
`;

const BoxContainer = styled.div`
    height: 3.6rem;
    width: 35.9rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const OthersContainer = styled.div`
    height: 5.4rem;
    width: 37rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Dozens = styled.div`
    height: 1.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .zero {
        height: 1.8rem;
        width: 1.8rem;
        background-color: #00cf78;

        border-top-left-radius: 0.8rem;
        border-bottom-left-radius: 0.8rem;
    }

    .zero-placeholder {
        height: 1.8rem;
        width: 1.8rem;
    }

    .first-dozen,
    .second-dozen,
    .third-dozen {
        width: 11.4rem;
    }

    .first-dozen {
        background: #898989;
    }

    .second-dozen {
        background: #555555;
    }

    .third-dozen {
        background: #898989;
        border-top-right-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
    }
`;

const Others = styled.div`
    height: 5.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .odd,
    .even,
    .first-eighteen,
    .nineteen-thirtysix {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.6rem;
        width: 5.57rem;
        background-color: #555555;
    }

    .red {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.6rem;
        width: 5.57rem;
        background-color: #f24822;
    }

    .black {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.6rem;
        width: 5.57rem;
        background-color: #0c0c0c;
    }

    .first-eighteen {
        border-top-left-radius: 0.8rem;
        border-bottom-left-radius: 0.8rem;
    }

    .nineteen-thirtysix {
        border-top-right-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
    }
`;

interface Dozen {
    className: string;
    label: string;
    percentage: number;
}

interface Other {
    className: string;
    label: string;
    percentage: number;
}

export const AdvancedPopupBoxMobile = () => {
    const { gameStats } = useLogicStateContext();

    const {
        evenPercentage,
        oddPercentage,
        redPercentage,
        blackPercentage,
        greenPrecentage,
        oneToEighteenPercentage,
        eighteenToThirtySixPercentage,
        firstDozenPercentage,
        secondDozenPercentage,
        thirdDozenPercentage,
    } = gameStats;

    const dozens: Dozen[] = [
        {
            className: 'first-dozen',
            label: '1st Dozen',
            percentage: firstDozenPercentage,
        },
        {
            className: 'second-dozen',
            label: '2nd Dozen',
            percentage: secondDozenPercentage,
        },
        {
            className: 'third-dozen',
            label: '3rd Dozen',
            percentage: thirdDozenPercentage,
        },
    ];

    const others: Other[] = [
        {
            className: 'first-eighteen',
            label: '1 - 18',
            percentage: +oneToEighteenPercentage,
        },
        {
            className: 'even',
            label: 'EVEN',
            percentage: +evenPercentage,
        },
        {
            className: 'red',
            label: '',
            percentage: redPercentage,
        },
        {
            className: 'black',
            label: '',
            percentage: blackPercentage,
        },
        {
            className: 'odd',
            label: 'ODD',
            percentage: +oddPercentage,
        },
        {
            className: 'nineteen-thirtysix',
            label: '19 - 36',
            percentage: +eighteenToThirtySixPercentage,
        },
    ];

    return (
        <Container>
            <BoxContainer>
                <Dozens>
                    <div className="zero-placeholder">{greenPrecentage}%</div>
                    <div className="zero">0</div>
                </Dozens>
                {dozens.map((dozen) => (
                    <Dozens key={dozen.className}>
                        <div>{dozen.percentage.toFixed(2)}%</div>
                        <div className={dozen.className}>{dozen.label}</div>
                    </Dozens>
                ))}
            </BoxContainer>
            <OthersContainer>
                {others.map((other) => (
                    <Others key={other.className}>
                        <div>{other.percentage.toFixed(2)}%</div>
                        <div className={other.className}>{other.label}</div>
                    </Others>
                ))}
            </OthersContainer>
        </Container>
    );
};
