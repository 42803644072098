import { useAudio } from '@shared/ui/contexts';
import { AudioController } from '../inputs/audio-controller';
import styled from '@emotion/styled';

const Container = styled.div`
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;

    gap: 2rem;

    > div {
        flex: 1;
        width: 100%;
    }
`;

export const AudioSettings = () => {
    const {
        rawDealerVolume,
        setDealerVolume,
        setEffectsVolume,
        rawEffectsVolume,
        setAmbientVolume,
        rawAmbientVolume,
        setMasterVolume,
        rawMasterVolume,
    } = useAudio();
    return (
        <Container>
            <AudioController
                title={'MASTER'}
                volume={rawMasterVolume}
                setVolume={setMasterVolume}
            />
            <AudioController
                title={'DEALER'}
                volume={rawDealerVolume}
                setVolume={setDealerVolume}
            />
            <AudioController
                title={'EFFECTS'}
                volume={rawEffectsVolume}
                setVolume={setEffectsVolume}
            />
            <AudioController
                title={'BACKGROUND'}
                volume={rawAmbientVolume}
                setVolume={setAmbientVolume}
            />
        </Container>
    );
};
