import { useState, type PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { ICONS } from './icons';

const Container = styled.div`
    margin-top: 1rem;
    overflow: hidden;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Chakra Petch';
    font-size: 1.6rem;
    font-weight: 500;
    padding: 0.6rem 1.2rem;
    border-radius: 0.9rem;
    color: #fff;
    background: var(--color-dark-black);
    cursor: pointer;
    user-select: none;
`;

const Chevron = styled.span<{ isOpen: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
        width: 2rem;
        height: 1.9rem;
        color: #fff;
        transform: ${({ isOpen }) =>
            isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
        /* transition: transform 0.3s ease-in-out; */
    }
`;

const Content = styled.div<{ isOpen: boolean }>`
    max-height: ${({ isOpen }) => (isOpen ? '500px' : '0')};
    overflow: hidden;
    transition: max-height 0.1s ease-in-out;
    background-color: transparent;
`;

const ContentInner = styled.div`
    font-family: 'Chakra Petch';
    font-size: 1.4rem;
    font-weight: 500;
    color: #fff;
    background: var(--color-dark-black);
    margin-top: 1rem;
    padding: 0.6rem 1.2rem;
    border-radius: 0.9rem;
`;

interface AccordionProps extends PropsWithChildren {
    title: string;
}

export const Accordion = ({ title, children }: AccordionProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Container>
            <Header onClick={toggleAccordion}>
                {title}
                <Chevron isOpen={isOpen}>{ICONS.Chevron}</Chevron>
            </Header>
            <Content isOpen={isOpen}>
                <ContentInner>{children}</ContentInner>
            </Content>
        </Container>
    );
};
