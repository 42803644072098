import { PropsWithChildren } from 'react';

export enum Themes {
    GOLD = 'gold',
    STEEL_BLUE = 'steel-blue',
    PINK = 'pink',
}

export interface Theme {
    theme?: Themes;
}

export const ThemeWrapper = ({
    theme = Themes.STEEL_BLUE,
    children,
}: PropsWithChildren<Theme>) => {
    return (
        <div style={{ display: 'contents' }} className={`theme-${theme}`}>
            {children}
        </div>
    );
};
