import {
    KeyboardEventHandler,
    useState,
    useMemo,
    useRef,
    useEffect,
    HTMLAttributes,
} from 'react';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

import { useChatContext } from '@shared/ui/contexts';
import { ChatUser } from './chat-user';

const EXPANDED_WIDTH = '36.4rem';
const EXPANDED_WIDTH_MOBILE = '21.9rem';

export interface UserMessage {
    message: string;
    timestamp: number;
}

export interface UserMessageObject {
    user_id: string;
    name: string;
    messages: UserMessage[];
}

export interface UpdatedMessages {
    prevMessageUserId: string;
    prevTimestamp: number | null;
    newMessages: Array<{
        user_id: string;
        name: string;
        messages: Array<{
            message: string;
            timestamp: number;
        }>;
    }>;
}

const messageIn = keyframes`
  0%, 100% {
    opacity: 0;
    transform: translate(0, -2rem);
  }
  10%,90% {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
    pointer-events: auto;
`;

const MobileChatContainer = styled.div`
    width: 21.9rem;
    height: 3.9rem;

    position: absolute;
    top: -0.3rem;
    left: 2rem;

    display: flex;
    align-items: center;

    border: 0.1rem solid rgba(var(--color-primary-opacity), 0.5);
    border-radius: 1rem;
    background: var(--opaque-background);

    transition: width 500ms ease-in-out;

    &.expanded {
        width: ${EXPANDED_WIDTH_MOBILE};

        .chat-text-input {
            width: ${EXPANDED_WIDTH_MOBILE};
        }
    }

    .chat-text-input {
        width: 100%;
        background: none;
        border: unset;
        padding-left: 1.6rem;
        padding-right: 1.2rem;

        font-family: 'Chakra Petch', sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var(--chat-text-color);

        outline: none;

        ::placeholder {
            font-size: 1.6rem;
            color: rgba(var(--color-text-accent-opacity), 0.9);
        }
    }

    .all-messages {
        width: 21.9rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        position: absolute;
        top: 5rem;

        height: auto;
        max-height: 40rem;

        overflow-y: auto;
        overflow-x: hidden;

        animation: ${fadeIn} 1s ease forwards; // Apply fadeIn animation
        z-index: 50;
        ::placeholder {
            font-size: 1.6rem;
            color: rgba(var(--color-text-accent-opacity), 0.9);
        }
    }

    .last-message {
        animation: ${messageIn} 5s forwards;
        position: absolute;
        font-size: 1.2rem;
        top: 5rem;
        // margin-left: -1.2rem;

        width: 21.9rem;
    }
`;

const DesktopChatContainer = styled.div`
    width: 22.7rem;
    height: 3.9rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    transition: width 500ms ease-in-out;

    border: 0.1rem solid rgba(var(--color-primary-opacity), 0.5);
    border-radius: 1rem;
    background: var(--opaque-background);

    &.expanded {
        width: ${EXPANDED_WIDTH};

        .chat-text-input {
            width: ${EXPANDED_WIDTH};
        }
    }

    .chat-text-input {
        width: 100%;
        background: none;
        border: unset;
        padding-left: 1.6rem;
        padding-right: 1.2rem;

        z-index: 100;

        font-family: 'Chakra Petch', sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.8rem;
        color: var(--chat-text-color);

        outline: none;

        ::placeholder {
            font-size: 1.6rem;
            color: rgba(var(--color-text-accent-opacity), 0.9);
        }
    }

    .last-message {
        animation: ${messageIn} 5s forwards;
        position: absolute;

        top: 4.4rem;
        font-size: 1.2rem;
        width: 20.6rem;
        padding-left: -1.2rem;
    }

    .all-messages {
        min-width: 36.4rem;
        position: absolute;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        position: absolute;

        height: auto;
        max-height: 50rem;
        top: 5rem;

        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 1.1rem;

        animation: ${fadeIn} 1s ease forwards; // Apply fadeIn animation
        z-index: 50;
        ::placeholder {
            font-size: 1.6rem;
            color: rgba(var(--color-text-accent-opacity), 0.9);
        }
    }
`;

interface ChatProps extends HTMLAttributes<HTMLDivElement> {
    userId: string | undefined;
    isMobile?: boolean;
    showChat?: boolean;
}

export const Chat = ({ userId, isMobile, className }: ChatProps) => {
    const { messages, sendChatMessage, hasNewMessages, setHasNewMessages } =
        useChatContext();

    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const messageContainerRef = useRef<HTMLDivElement | null>(null);

    const updatedMessages = useMemo(() => {
        return messages.reduce(
            (acc: UpdatedMessages, curr) => {
                const { user_id, name, message } = curr.payload;
                const { timestamp } = curr;

                if (acc.newMessages.length === 0) {
                    acc.newMessages[0] = {
                        user_id,
                        name,
                        messages: [
                            {
                                message,
                                timestamp,
                            },
                        ],
                    };
                    acc.prevMessageUserId = user_id;
                    acc.prevTimestamp = timestamp;
                    return acc;
                }

                if (user_id !== acc.prevMessageUserId) {
                    acc.newMessages = [
                        {
                            user_id,
                            name,
                            messages: [
                                {
                                    message,
                                    timestamp,
                                },
                            ],
                        },
                        ...acc.newMessages,
                    ];
                    acc.prevMessageUserId = user_id;
                    acc.prevTimestamp = timestamp;
                    return acc;
                }

                if (
                    user_id === acc.prevMessageUserId &&
                    timestamp !== acc.prevTimestamp
                ) {
                    acc.newMessages = [
                        {
                            user_id,
                            name,
                            messages: [
                                {
                                    message,
                                    timestamp,
                                },
                            ],
                        },
                        ...acc.newMessages,
                    ];
                    acc.prevTimestamp = timestamp;
                    return acc;
                }

                if (
                    user_id === acc.prevMessageUserId &&
                    timestamp === acc.prevTimestamp
                ) {
                    acc.newMessages[0].messages.push({
                        message,
                        timestamp,
                    });
                    return acc;
                }

                return acc;
            },
            {
                prevMessageUserId: '',
                prevTimestamp: null,
                newMessages: [],
            }
        );
    }, [messages]);

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter' && event.currentTarget.value !== '') {
            sendChatMessage(event.currentTarget.value);

            event.currentTarget.value = '';
        }
    };

    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTo({
                top: messageContainerRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [isExpanded, messages]);

    return (
        <Container className={className}>
            {isMobile ? (
                <MobileChatContainer className={isExpanded ? 'expanded ' : ''}>
                    <input
                        type="text"
                        className="chat-text-input"
                        onClick={() => {
                            setIsExpanded(true);
                            setHasNewMessages(false);
                        }}
                        onKeyDown={handleKeyDown}
                        onBlur={() => {
                            setIsExpanded(false);
                            setHasNewMessages(false);
                        }}
                        placeholder={isExpanded ? '' : 'Chat...'}
                    />

                    {hasNewMessages && !isExpanded ? (
                        <div className="last-message">
                            <ChatUser
                                key={`${
                                    updatedMessages.newMessages[
                                        updatedMessages.newMessages.length - 1
                                    ].user_id
                                }-${
                                    updatedMessages.newMessages[
                                        updatedMessages.newMessages.length - 1
                                    ].messages[0].timestamp
                                }`}
                                userMessageObject={
                                    updatedMessages.newMessages[
                                        updatedMessages.newMessages.length - 1
                                    ]
                                }
                                timestamp={
                                    updatedMessages.newMessages[0].messages[0]
                                        .timestamp
                                }
                                isUsersMessage={false}
                                isExpanded={isExpanded}
                                isMobile={isMobile}
                            />
                        </div>
                    ) : (
                        isExpanded && (
                            <div
                                className="all-messages custom-scrollbar"
                                ref={messageContainerRef}
                            >
                                <div className="chat-text">
                                    {updatedMessages.newMessages.map(
                                        (msgObj) => {
                                            const isCurrentUser =
                                                msgObj.user_id === userId;
                                            return (
                                                <ChatUser
                                                    key={`${msgObj.user_id}-${msgObj.messages[0].timestamp}`}
                                                    userMessageObject={msgObj}
                                                    isUsersMessage={
                                                        isCurrentUser
                                                    }
                                                    timestamp={
                                                        msgObj.messages[0]
                                                            .timestamp
                                                    }
                                                    isExpanded={isExpanded}
                                                    isMobile={isMobile}
                                                />
                                            );
                                        }
                                    )}
                                </div>
                            </div>
                        )
                    )}
                </MobileChatContainer>
            ) : (
                <DesktopChatContainer
                    className={isExpanded ? 'expanded custom-scrollbar' : ''}
                >
                    <input
                        type="text"
                        className="chat-text-input"
                        onClick={() => {
                            setIsExpanded(true);
                            setHasNewMessages(false);
                        }}
                        onKeyDown={handleKeyDown}
                        onBlur={() => {
                            setIsExpanded(false);
                            setHasNewMessages(false);
                        }}
                        placeholder={isExpanded ? '' : 'Chat...'}
                    />

                    {hasNewMessages && !isExpanded ? (
                        <div className="last-message">
                            <ChatUser
                                key={`${
                                    updatedMessages.newMessages[
                                        updatedMessages.newMessages.length - 1
                                    ].user_id
                                }-${
                                    updatedMessages.newMessages[
                                        updatedMessages.newMessages.length - 1
                                    ].messages[0].timestamp
                                }`}
                                userMessageObject={
                                    updatedMessages.newMessages[
                                        updatedMessages.newMessages.length - 1
                                    ]
                                }
                                timestamp={
                                    updatedMessages.newMessages[0].messages[0]
                                        .timestamp
                                }
                                isUsersMessage={false}
                                isExpanded={isExpanded}
                            />
                        </div>
                    ) : (
                        isExpanded && (
                            <div
                                className="all-messages custom-scrollbar"
                                ref={messageContainerRef}
                            >
                                {updatedMessages.newMessages.map((msgObj) => {
                                    const isCurrentUser =
                                        msgObj.user_id === userId;
                                    return (
                                        <ChatUser
                                            key={`${msgObj.user_id}-${msgObj.messages[0].timestamp}`}
                                            userMessageObject={msgObj}
                                            isUsersMessage={isCurrentUser}
                                            isExpanded={isExpanded}
                                            timestamp={
                                                msgObj.messages[0].timestamp
                                            }
                                        />
                                    );
                                })}
                            </div>
                        )
                    )}
                </DesktopChatContainer>
            )}
        </Container>
    );
};
