export enum PlayerState {
    WIN = 'win',
    LOSS = 'loss',
    PUSH = 'push',
    IDLE = 'idle',
    BETTING = 'betting',
    FINISHED = 'finished',
    WAITING = 'waiting',
}

export enum PlayerAction {
    BET = 'BET',
    JOIN = 'JOIN',
}

export enum PlayerBaseBet {
    NULL = 'null',
    BASE = 'base',
    BEHIND = 'behind',
    STRAIGHT_UP = 'straight_up',
    ZERO = 'zero',
    SPLIT = 'split',
    CORNER = 'corner',
    STREET = 'street',
    DOUBLE_STREET = 'double_street',
    FIRST_COLUMN = 'first_column',
    SECOND_COLUMN = 'second_column',
    THIRD_COLUMN = 'third_column',
    EVEN = 'even',
    ODD = 'odd',
    LOW = 'low',
    HIGH = 'high',
    RED = 'red',
    BLACK = 'black',
    FIRST_TWELVE = 'first_twelve',
    SECOND_TWELVE = 'second_twelve',
    THIRD_TWELVE = 'third_twelve',
    NO_WIN = 'no_win',
    ColumnBet = 'column_bet',
    RACETRACK_STRAIGHT_UP = 'racetrack_straight_up',
    VOISINS_BET_SPLITS = 'voisins_bet_splits',
    VOISINS_BET_CORNER = 'voisins_bet_corner',
    VOISINS_BET_TRIO = 'voisins_bet_trio',
    ORPHELINS_BET_SPLITS = 'orphelins_bet_splits',
    ORPHELINS_BET_STRAIGHT = 'orphelins_bet_straight',
    TIERS_BET_SPLIT = 'tiers_bet_split',
    JEU_ZERO_SPLIT = 'jeu_zero_split',
    JEU_ZERO_STRAIGHT = 'jeu_zero_straight',
}

export enum RedBlackOutcome {
    RED = 'red',
    BLACK = 'black',
}
// Enum defining possible outcomes for Even/Odd
export enum EvenOddOutcome {
    EVEN = 'even',
    ODD = 'odd',
}

// Enum defining possible outcomes for LOW/HIGH
export enum LowHighOutcome {
    LOW = 'low',
    HIGH = 'high',
}

// Enum defining possible outcomes for TWELVERANGE
export enum TwelveRange {
    FIRST_TWELVE = 'first_twelve',
    SECOND_TWELVE = 'second_twelve',
    THIRD_TWELVE = 'third_twelve',
}

export enum ColumnBet {
    FIRST = 'first',
    SECOND = 'second',
    THIRD = 'third',
}

export interface UserWinAmounts {
    baseBets: {
        banker: number;
        player: number;
        tie: number;
    };
    sideBets: {
        b_bonus: number;
        b_pair: number;
        p_bonus: number;
        p_pair: number;
        either_pair: number;
    };
}

export type PlayerPayout = {
    userId: string;
    name: string;
    totalPayout: number;
};
