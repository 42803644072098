import { Point } from '@roulette/models';

export const calculatePolygonCentroid = (points: Point[]): Point => {
    let area = 0;
    let cx = 0;
    let cy = 0;

    const n = points.length;

    for (let i = 0; i < n; i++) {
        const { x: xi, y: yi } = points[i];
        const { x: xj, y: yj } = points[(i + 1) % n]; // Wrap around to the first point for closed path

        const crossProduct = xi * yj - xj * yi;
        area += crossProduct;
        cx += (xi + xj) * crossProduct;
        cy += (yi + yj) * crossProduct;
    }

    area /= 2; // The actual area is half of the calculated sum
    const factor = 1 / (6 * area);

    cx *= factor;
    cy *= factor;

    return { x: cx, y: cy };
};
