import { PlayerBaseBet } from './lib/types';

export enum BoardColor {
    RED = 'red',
    BLACK = 'black',
    GREEN = 'green',
}

export interface BoardNumber {
    value: number;
    color: BoardColor;
}

export const boardNumbers: BoardNumber[] = [
    {
        value: 0,
        color: BoardColor.GREEN,
    },
    {
        value: 1,
        color: BoardColor.RED,
    },
    {
        value: 2,
        color: BoardColor.BLACK,
    },
    {
        value: 3,
        color: BoardColor.RED,
    },
    {
        value: 4,
        color: BoardColor.BLACK,
    },
    {
        value: 5,
        color: BoardColor.RED,
    },
    {
        value: 6,
        color: BoardColor.BLACK,
    },
    {
        value: 7,
        color: BoardColor.RED,
    },
    {
        value: 8,
        color: BoardColor.BLACK,
    },
    {
        value: 9,
        color: BoardColor.RED,
    },
    {
        value: 10,
        color: BoardColor.BLACK,
    },
    {
        value: 11,
        color: BoardColor.BLACK,
    },
    {
        value: 12,
        color: BoardColor.RED,
    },
    {
        value: 13,
        color: BoardColor.BLACK,
    },
    {
        value: 14,
        color: BoardColor.RED,
    },
    {
        value: 15,
        color: BoardColor.BLACK,
    },
    {
        value: 16,
        color: BoardColor.RED,
    },
    {
        value: 17,
        color: BoardColor.BLACK,
    },
    {
        value: 18,
        color: BoardColor.RED,
    },
    {
        value: 19,
        color: BoardColor.RED,
    },
    {
        value: 20,
        color: BoardColor.BLACK,
    },
    {
        value: 21,
        color: BoardColor.RED,
    },
    {
        value: 22,
        color: BoardColor.BLACK,
    },
    {
        value: 23,
        color: BoardColor.RED,
    },
    {
        value: 24,
        color: BoardColor.BLACK,
    },
    {
        value: 25,
        color: BoardColor.RED,
    },
    {
        value: 26,
        color: BoardColor.BLACK,
    },
    {
        value: 27,
        color: BoardColor.RED,
    },
    {
        value: 28,
        color: BoardColor.BLACK,
    },
    {
        value: 29,
        color: BoardColor.BLACK,
    },
    {
        value: 30,
        color: BoardColor.RED,
    },
    {
        value: 31,
        color: BoardColor.BLACK,
    },
    {
        value: 32,
        color: BoardColor.RED,
    },
    {
        value: 33,
        color: BoardColor.BLACK,
    },
    {
        value: 34,
        color: BoardColor.RED,
    },
    {
        value: 35,
        color: BoardColor.BLACK,
    },
    {
        value: 36,
        color: BoardColor.RED,
    },
];

export const voisinsBetTypes = [
    {
        type: PlayerBaseBet.VOISINS_BET_TRIO,
        number: [0, 2, 3],
    },
    {
        type: PlayerBaseBet.VOISINS_BET_SPLITS,
        number: [4, 7],
    },
    {
        type: PlayerBaseBet.VOISINS_BET_SPLITS,
        number: [12, 15],
    },
    {
        type: PlayerBaseBet.VOISINS_BET_SPLITS,
        number: [18, 21],
    },
    {
        type: PlayerBaseBet.VOISINS_BET_SPLITS,
        number: [19, 22],
    },
    {
        type: PlayerBaseBet.VOISINS_BET_CORNER,
        number: [25, 28, 26, 29],
    },
    {
        type: PlayerBaseBet.VOISINS_BET_SPLITS,
        number: [32, 35],
    },
];

export const orphelinsBetTypes = [
    {
        type: PlayerBaseBet.ORPHELINS_BET_SPLITS,
        number: [6, 9],
    },
    {
        type: PlayerBaseBet.ORPHELINS_BET_SPLITS,
        number: [14, 17],
    },
    {
        type: PlayerBaseBet.ORPHELINS_BET_SPLITS,
        number: [17, 20],
    },
    {
        type: PlayerBaseBet.ORPHELINS_BET_SPLITS,
        number: [31, 34],
    },
    {
        type: PlayerBaseBet.ORPHELINS_BET_STRAIGHT,
        number: [1],
    },
];

export const tiersBetTypes = [
    {
        type: PlayerBaseBet.TIERS_BET_SPLIT,
        number: [5, 8],
    },
    {
        type: PlayerBaseBet.TIERS_BET_SPLIT,
        number: [10, 11],
    },
    {
        type: PlayerBaseBet.TIERS_BET_SPLIT,
        number: [13, 16],
    },
    {
        type: PlayerBaseBet.TIERS_BET_SPLIT,
        number: [23, 24],
    },
    {
        type: PlayerBaseBet.TIERS_BET_SPLIT,
        number: [27, 30],
    },
    {
        type: PlayerBaseBet.TIERS_BET_SPLIT,
        number: [33, 36],
    },
];

export const jeuZeroBetTypes = [
    {
        type: PlayerBaseBet.JEU_ZERO_SPLIT,
        number: [0, 3],
    },
    {
        type: PlayerBaseBet.JEU_ZERO_SPLIT,
        number: [12, 15],
    },
    {
        type: PlayerBaseBet.JEU_ZERO_STRAIGHT,
        number: [26],
    },
    {
        type: PlayerBaseBet.JEU_ZERO_SPLIT,
        number: [32, 35],
    },
];
