import { BaseAndTarget, CurrencyContext } from './types';
import { CURRENCY } from './static-currencies';

function findBaseAndTarget(
    context: CurrencyContext,
    base: CURRENCY,
    target: CURRENCY
): BaseAndTarget {
    const baseCurrency = context.rates[base];

    if (!baseCurrency) {
        throw new Error(`Conversion rate not found for base: ${base}`);
    }

    const targetRate = context.rates[target];

    if (!targetRate) {
        throw new Error(`Conversion rate not found for target: ${targetRate}`);
    }

    return { baseRate: baseCurrency, targetRate };
}

const convertCurrency = (
    context: CurrencyContext,
    base: string,
    target: string,
    amount: number
): number => {
    if (!base) throw new Error('Base currency is not defined');
    if (!target) throw new Error('Target currency is not defined');

    const { baseRate, targetRate } = findBaseAndTarget(
        context,
        base.toUpperCase() as CURRENCY,
        target.toUpperCase() as CURRENCY
    );

    return +(amount * (targetRate / baseRate));
};

const getCurrencyRate = (
    context: CurrencyContext,
    base: string,
    target: string
): { [key: string]: number } => {
    if (!base) throw new Error('Base currency is not defined');
    if (!target) throw new Error('Target currency is not defined');

    const { baseRate, targetRate } = findBaseAndTarget(
        context,
        base.toUpperCase() as CURRENCY,
        target.toUpperCase() as CURRENCY
    );

    return { [base]: baseRate, [target]: targetRate };
};

export { convertCurrency, getCurrencyRate };
