import styled from '@emotion/styled';
import { WinMessage } from './win-message';
import { formatCurrency } from '@shared/utils';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 1rem;

    svg {
        height: 16rem;
    }
`;

const TotalWon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    padding: 0.4rem 6rem;
    box-sizing: border-box;

    background: linear-gradient(
        90deg,
        rgba(30, 30, 30, 0) 0%,
        #1e1e1e 52%,
        rgba(30, 30, 30, 0) 100%
    );

    color: #00e266;

    text-align: center;
    font-family: 'Chakra Petch';
    font-size: 4.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    .amount {
        color: #fff;
    }
`;

interface Props {
    winNumber: number;
    totalWon: number;
    currency?: string;
}

export const WinComponent: React.FC<Props> = ({
    winNumber,
    totalWon,
    currency = 'USD',
}) => {
    return (
        <Container>
            <WinMessage winNumber={winNumber} />
            {totalWon > 0 && (
                <TotalWon>
                    <div className="title">WINNER!</div>
                    <div className="amount">
                        {formatCurrency(totalWon, currency)}
                    </div>
                </TotalWon>
            )}
        </Container>
    );
};
