import styled from '@emotion/styled';
import { useLogicStateContext } from '@roulette/contexts';
import { boardNumbers } from '@roulette/models';
import clsx from 'clsx';
import { useMemo } from 'react';

const OuterContainer = styled.div`
    height: 42.3rem;
    width: 36rem;
    overflow: scroll;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    position: relative;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
`;

const Item = styled.div`
    height: 1.6rem;
    width: 3.6rem;
    border: 0.05rem solid #898989;
    display: flex;
    justify-content: center;
    align-items: center;

    &.red-item {
        background-color: black;
        color: #f24822;
    }

    &.black-item {
        background-color: black;
        color: #ffffff;
    }
`;

export const LastFiveHundredPopupBoxMobile = () => {
    const { range } = useLogicStateContext();
    const { resultHistory } = useLogicStateContext();

    const numbersArray = useMemo(() => {
        const numbersArray = new Array(range).fill(null);

        const reverseHistory = [...resultHistory].reverse();

        return numbersArray.map((_, idx) => {
            if (reverseHistory.length < idx) return null;
            return reverseHistory[idx];
        });
    }, [range, resultHistory]);

    return (
        <OuterContainer>
            <InnerContainer>
                {numbersArray.map((number) => {
                    const boardNumber =
                        number !== null ? boardNumbers[number] : null;

                    return (
                        <Item
                            key={number}
                            className={clsx(
                                `${boardNumber?.color || 'undefined'}-item`
                            )}
                        >
                            {boardNumber ? boardNumber.value : ''}
                        </Item>
                    );
                })}
            </InnerContainer>
        </OuterContainer>
    );
};
