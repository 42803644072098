import styled from '@emotion/styled';
import { ChipSizes, getChipStyle, getChipValue } from '@shared/models';
import { useChipContext } from '@shared/ui/contexts';

import { useMemo } from 'react';

const Container = styled.div<{
    color: string;
    calculatedTextSize: number;
    isMobile?: boolean;
}>`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;

    cursor: pointer;

    transition: filter 200ms ease-in;

    position: relative;

    &.disabled {
        opacity: 0.5;
    }

    svg {
        .st0 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #403e3e;

            transition: fill 1000ms ease-in;
        }
        .st1 {
            fill: #ffffff;
        }
        .st2 {
            fill-rule: evenodd;
            clip-rule: evenodd;
            fill: #f6f6f6;
        }
    }

    &.selected {
        svg {
            .st0 {
                fill: ${({ color }) => color};
            }
        }
        filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.4));
    }

    .amount {
        /* border: 1px solid red; */
        display: flex;
        position: absolute;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .chip-text {
            font-family: 'Chakra Petch', sans-serif;
            font-size: ${({ calculatedTextSize }) =>
                `${calculatedTextSize}rem`};
            color: white;
            font-weight: 400;
            user-select: none;

            transform: ${({ isMobile }) => (isMobile ? 'rotate(-270deg)' : '')};
        }
    }
`;

export interface SvgChipProps {
    value: string | number;
    onClick?: () => void;
    isBigChip?: boolean;
    color?: string;
    startColor?: string;
    stopColor?: string;
    selected?: boolean;
    disabled?: boolean;
    size?: ChipSizes;
    isMobile?: boolean;
}

export const Chip = (props: SvgChipProps) => {
    const {
        value,
        size = 'medium',
        selected = false,
        disabled = false,
        onClick,
    } = props;

    const className = `${selected ? 'selected' : ''} ${
        disabled ? 'disabled' : ''
    }`;

    const { getChipColor } = useChipContext();

    const chipValue = useMemo(() => getChipValue(+value), [value]);

    const chipColor = useMemo(() => {
        return getChipColor(+value);
    }, [value, getChipColor]);

    const chipSize = useMemo(() => {
        return getChipStyle(chipValue, size);
    }, [chipValue, size]);

    return (
        <Container
            color={chipColor.color}
            calculatedTextSize={chipSize.fontSize}
            className={className}
            onClick={disabled ? undefined : onClick}
            isMobile={props.isMobile}
        >
            <svg
                width={chipSize.width}
                height={chipSize.height}
                viewBox="0 0 78 78"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g id={`${chipValue}Coin`}>
                    <g
                        id="Ellipse 10"
                        filter={`url(#filter0_iii_${chipValue})`}
                    >
                        <circle
                            cx="39"
                            cy="39"
                            r="39"
                            fill={`url(#paint0_linear_${chipValue})`}
                        />
                    </g>
                    <g id="Mask group" filter={`url(#filter1_i_${chipValue})`}>
                        <mask
                            id="mask0_618_667"
                            style={{ maskType: 'alpha' }}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="78"
                            height="78"
                        >
                            <circle
                                id="Ellipse 11"
                                cx="39"
                                cy="39"
                                r="39"
                                fill="url(#paint1_linear_618_667)"
                            />
                        </mask>
                        <g mask="url(#mask0_618_667)">
                            <g id="Group 17">
                                <path
                                    fill="url(#paint0_linear_1062_1174)"
                                    className="hover"
                                    d="M66.5772 11.423C81.8076 26.6534 81.8076 51.3468 66.5772 66.5773C51.3468 81.8077 26.6533 81.8077 11.4229 66.5773C-3.80758 51.3468 -3.80758 26.6534 11.4229 11.423C26.6533 -3.80749 51.3468 -3.80749 66.5772 11.423Z"
                                />
                                <path
                                    id="Rectangle 9"
                                    d="M73.1317 47.0267C70.0377 46.7498 67.6666 44.157 67.6666 41.0506L67.6666 36.7525C67.6666 33.5947 70.1141 30.9774 73.2647 30.7659L82 30.1795L82 47.8205L73.1317 47.0267Z"
                                    fill="#F4F4F4"
                                />
                                <path
                                    id="Rectangle 10"
                                    d="M4.86829 30.9733C7.96234 31.2503 10.3334 33.843 10.3334 36.9494L10.3334 41.2476C10.3334 44.4053 7.88589 47.0226 4.73527 47.2341L-3.99996 47.8205L-3.99996 30.1795L4.86829 30.9733Z"
                                    fill="#F4F4F4"
                                />
                                <path
                                    id="Rectangle 11"
                                    d="M49.1146 72.5723C47.8074 69.7543 48.8673 66.4046 51.5575 64.8514L55.2798 62.7023C58.0145 61.1234 61.5049 61.9344 63.2634 64.5571L68.1388 71.8288L52.8612 80.6493L49.1146 72.5723Z"
                                    fill="#F4F4F4"
                                />
                                <path
                                    id="Rectangle 12"
                                    d="M28.8854 5.42767C30.1926 8.24567 29.1327 11.5954 26.4425 13.1486L22.7202 15.2977C19.9855 16.8766 16.4951 16.0656 14.7366 13.4429L9.86118 6.17116L25.1388 -2.64936L28.8854 5.42767Z"
                                    fill="#F4F4F4"
                                />
                                <path
                                    id="Rectangle 13"
                                    d="M14.9827 64.5456C16.7696 62.0046 20.2005 61.2476 22.8907 62.8008L26.6131 64.9499C29.3477 66.5287 30.3906 69.957 28.9985 72.7912L25.1387 80.6494L9.86117 71.8289L14.9827 64.5456Z"
                                    fill="#F4F4F4"
                                />
                                <path
                                    id="Rectangle 14"
                                    d="M63.0173 13.4544C61.2304 15.9955 57.7995 16.7525 55.1093 15.1993L51.3869 13.0502C48.6523 11.4713 47.6094 8.0431 49.0015 5.20883L52.8613 -2.64931L68.1388 6.1712L63.0173 13.4544Z"
                                    fill="#F4F4F4"
                                />
                            </g>
                        </g>
                    </g>
                    <circle
                        id="Ellipse 18"
                        cx="39"
                        cy="39"
                        r="24"
                        fill={`url(#paint2_linear_${chipValue})`}
                        stroke="#F4F4F4"
                        strokeWidth="2"
                    />

                    <g id="Group 18">
                        <circle
                            id="Ellipse 12"
                            cx="38.4987"
                            cy="72.1356"
                            r="2.54889"
                            fill="#F4F4F4"
                            fillOpacity="0.5"
                        />
                        <circle
                            id="Ellipse 13"
                            cx="38.4987"
                            cy="5.86438"
                            r="2.54889"
                            fill="#F4F4F4"
                            fillOpacity="0.5"
                        />
                        <circle
                            id="Ellipse 14"
                            cx="67.0085"
                            cy="56.2642"
                            r="2.54889"
                            transform="rotate(-60 67.0085 56.2642)"
                            fill="#F4F4F4"
                            fillOpacity="0.5"
                        />
                        <circle
                            id="Ellipse 15"
                            cx="9.61589"
                            cy="23.1286"
                            r="2.54889"
                            transform="rotate(-60 9.61589 23.1286)"
                            fill="#F4F4F4"
                            fillOpacity="0.5"
                        />
                        <circle
                            id="Ellipse 16"
                            cx="67.5182"
                            cy="23.6384"
                            r="2.54889"
                            transform="rotate(-120 67.5182 23.6384)"
                            fill="#F4F4F4"
                            fillOpacity="0.5"
                        />
                        <circle
                            id="Ellipse 17"
                            cx="10.1257"
                            cy="56.774"
                            r="2.54889"
                            transform="rotate(-120 10.1257 56.774)"
                            fill="#F4F4F4"
                            fillOpacity="0.5"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_iii_618_667"
                        x="0"
                        y="0"
                        width="78"
                        height="78"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="0.5" />
                        <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                        />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.53 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_618_667"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="0.5" />
                        <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                        />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="effect1_innerShadow_618_667"
                            result="effect2_innerShadow_618_667"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset dy="-1" />
                        <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                        />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0.362993 0 0 0 0 0.393783 0 0 0 0 0.383676 0 0 0 1 0"
                        />
                        <feBlend
                            mode="overlay"
                            in2="effect2_innerShadow_618_667"
                            result="effect3_innerShadow_618_667"
                        />
                    </filter>
                    <filter
                        id="filter1_i_618_667"
                        x="0"
                        y="0"
                        width="78"
                        height="78"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="0.55" />
                        <feComposite
                            in2="hardAlpha"
                            operator="arithmetic"
                            k2="-1"
                            k3="1"
                        />
                        <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.31 0"
                        />
                        <feBlend
                            mode="normal"
                            in2="shape"
                            result="effect1_innerShadow_618_667"
                        />
                    </filter>
                    <linearGradient
                        id={`paint0_linear_${chipValue}`}
                        x1="39"
                        y1="-4.02331e-07"
                        x2="52.5"
                        y2="76.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={chipColor.startColor} />
                        <stop offset="1" stopColor={chipColor.stopColor} />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_618_667"
                        x1="39"
                        y1="-4.02331e-07"
                        x2="52.5"
                        y2="76.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={chipColor.startColor} />
                        <stop offset="1" stopColor={chipColor.stopColor} />
                    </linearGradient>
                    <linearGradient
                        id={`paint2_linear_${chipValue}`}
                        x1="39"
                        y1="14"
                        x2="47.6538"
                        y2="63.0385"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={chipColor.stopColor} />
                        <stop offset="1" stopColor={chipColor.startColor} />
                    </linearGradient>
                    <linearGradient
                        id="paint0_linear_1062_1174"
                        x1="11.4229"
                        y1="11.423"
                        x2="66.5772"
                        y2="66.5773"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="white" stopOpacity="0" />
                        <stop
                            offset="0.51"
                            stopColor="white"
                            stopOpacity="0.54"
                        />
                        <stop offset="1" stopColor="white" stopOpacity="0" />
                    </linearGradient>
                </defs>

                <div />
            </svg>

            <div className="amount">
                <div className="chip-text">{chipValue}</div>
            </div>
        </Container>
    );
};
