import * as ReactDOM from 'react-dom/client';

import {
    CameraViewContextProvider,
    GameContextProvider,
    LogicContextProvider,
} from '@roulette/contexts';
import { Themes, BaseAppLayout } from '@shared/ui/layouts';
import { AppLayout } from './app/layouts/app-layout';

import mainCameraImage from '@shared/assets/images/UI_DEMO_ROULETTE_DESKTOP_CAMERA_0.png';
import topCameraImage from '@shared/assets/images/UI_DEMO_ROULETTE_DESKTOP_CAMERA_1.png';

import './sentry.config';
import { GameSettingsContextProvider } from '@shared/ui/contexts';
import { rouletteGameInfo } from './game-info';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const RouletteApp = () => {
    return (
        <BaseAppLayout name="roulette" theme={Themes.STEEL_BLUE}>
            <CameraViewContextProvider
                cameraViews={[mainCameraImage, topCameraImage]}
                enabled={true}
            >
                <GameContextProvider>
                    <LogicContextProvider>
                        <GameSettingsContextProvider
                            gameInfo={rouletteGameInfo}
                        >
                            <AppLayout />
                        </GameSettingsContextProvider>
                    </LogicContextProvider>
                </GameContextProvider>
            </CameraViewContextProvider>
        </BaseAppLayout>
    );
};

root.render(<RouletteApp />);
