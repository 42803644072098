import styled from '@emotion/styled';
import { Button2 } from '../buttons';
import { AnimoUserData } from '@shared/aggregator/models';
import { formatCurrency } from '@shared/utils';

const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    /* background-color: cyan; */

    & > *:first-of-type {
        margin-left: ${({ isMobile }) => (isMobile ? '1.6rem' : '3.2rem')};
    }
    & > *:last-of-type {
        margin-right: 0.5rem;
    }
`;

interface LeftButtonProps {
    user: Pick<AnimoUserData, 'currency' | 'balance'>;
    roundInfo: {
        sideBetTotals: number;
        baseBetTotal: number;
        totalWon: number;
    };
    isVertical: boolean;
}

export const LeftButtons = ({
    user,
    roundInfo,
    isVertical,
}: LeftButtonProps) => {
    const totalBets = roundInfo.baseBetTotal + roundInfo.sideBetTotals;

    return (
        <Container isMobile={isVertical}>
            <Button2
                title={roundInfo?.totalWon ?? '' ? 'LAST WIN' : 'TOTAL BET '}
                value={
                    roundInfo?.totalWon
                        ? `${formatCurrency(roundInfo.totalWon, user.currency)}`
                        : `${formatCurrency(
                              totalBets ? totalBets : 0,
                              user.currency
                          )}
                      `
                }
                onClick={() => {
                    console.log('Clicked the Total Bet Button!');
                }}
                isVertical={isVertical}
            />
            <Button2
                title="Balance"
                value={`${formatCurrency(user?.balance, user.currency)}`}
                onClick={() => {
                    console.log('Clicked the Balance Button!');
                }}
                isVertical={isVertical}
            />
        </Container>
    );
};
