import { ReactComponent as ChartSVG } from '../../../assets/icons/chart.svg';
import { ReactComponent as LastResultsSVG } from '../../../assets/icons/star.svg';
import { ReactComponent as HotNColdSVG } from '../../../assets/icons/hot-n-cold.svg';

export const popupBoxComponents = [
    {
        name: 'charts',
        icon: ChartSVG,
    },
    {
        name: 'latest-results',
        icon: LastResultsSVG,
    },
    {
        name: 'hot-&-cold',
        icon: HotNColdSVG,
    },
];
