import React, {
    MouseEventHandler,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import {
    PlayerBaseBet,
    jeuZeroBetTypes,
    orphelinsBetTypes,
    tiersBetTypes,
    voisinsBetTypes,
} from '@roulette/models';

import {
    useGameContext,
    useLogicActionContext,
    useLogicStateContext,
} from '@roulette/contexts';

import { Chip } from '@shared/ui/components/chips';
import ChipForward from '@shared/assets/audio/single-chip.mp3';

import { ChipAnimation } from './chip-animation';
import { ChipAnimationGroup } from './chip-animation-group';
import { calculateTotalAmount, findSvgPathCentroid } from '@roulette/utils';
import { useAudio } from '@shared/ui/contexts';

const Svg = styled.svg<{
    activeChip: number | null;
    showRacetrack: boolean;
    isMobile?: boolean;
}>`
    height: 25.7rem;
    width: 50.3rem;

    path {
        pointer-events: ${({ showRacetrack }) =>
            showRacetrack ? 'fill' : 'none'};
    }

    .number:hover {
        stroke: ${({ activeChip }) => activeChip && 'var(--roulette-hover)'};
    }

    &.voisins-hover {
        .vvoisins {
            stroke: ${({ activeChip }) =>
                activeChip && 'var(--roulette-hover)'};
            stroke-width: ${({ activeChip }) => activeChip && '0.3rem'};
            pointer-events: none;
        }

        text {
            stroke: none;
        }

        .twentyFive,
        .two,
        .twentyOne,
        .four,
        .twentyTwo,
        .eighteen,
        .twentyNine,
        .seven,
        .twentyEight,
        .twelve,
        .thirtyFive,
        .three,
        .twentySix,
        .zero,
        .thirtyTwo,
        .fifteen,
        .nineteen,
        #Vector_39 {
            fill: ${({ activeChip }) => activeChip && '#0f4a7a'};
            fill-opacity: ${({ activeChip }) => activeChip && '40%'};
        }
    }

    &.orphelins-hover {
        .orphelins {
            stroke: ${({ activeChip }) =>
                activeChip && 'var(--roulette-hover)'};
            stroke-width: ${({ activeChip }) => activeChip && '0.3rem'};
            pointer-events: none;
        }

        text {
            stroke: none;
        }

        .nine,
        .thirtyOne,
        .fourteen,
        .twenty,
        .one,
        .seventeen,
        .thirtyFour,
        .six,
        #Vector_41 {
            fill: ${({ activeChip }) => activeChip && '#0f4a7a'};
            fill-opacity: ${({ activeChip }) => activeChip && '40%'};
        }
    }

    &.tiers-hover {
        .tiers {
            stroke: ${({ activeChip }) =>
                activeChip && 'var(--roulette-hover)'};
            stroke-width: ${({ activeChip }) => activeChip && '0.3rem'};
            pointer-events: none;
        }

        .twentySeven,
        .thirteen,
        .thirtySix,
        .eleven,
        .thirty,
        .eight,
        .twentyThree,
        .ten,
        .five,
        .twentyFour,
        .sixteen,
        .thirtyThree,
        #Vector_38 {
            fill: ${({ activeChip }) => activeChip && '#0f4a7a'};
            fill-opacity: ${({ activeChip }) => activeChip && '40%'};
        }

        text {
            stroke: none;
        }
    }

    &.jeu-zero-hover {
        .jeu-zero {
            stroke: ${({ activeChip }) =>
                activeChip && 'var(--roulette-hover)'};
            stroke-width: ${({ activeChip }) => activeChip && '0.3rem'};
            pointer-events: auto;
        }

        .twelve,
        .thirtyFive,
        .three,
        .twentySix,
        .zero,
        .thirtyTwo,
        .fifteen,
        #Ellipse-1 {
            fill: ${({ activeChip }) => activeChip && '#0f4a7a'};
            fill-opacity: ${({ activeChip }) => activeChip && '40%'};
        }

        text {
            stroke: none;
        }
    }

    text {
        pointer-events: none;
    }
`;

const Wrapper = styled.div`
    position: relative;
`;

const Container = styled.div<{ isMobile: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;

    pointer-events: none;

    .chip {
        display: block;
        position: absolute;
        width: 2.3rem;
        height: 2.3rem;
        border-radius: 50%;
        // border: 2px solid white;
        box-sizing: border-box;
        margin-left: -10px;
        margin-top: 0.6rem;
        transition: opacity 0.5s ease, transform 0.3s ease;

        transform: ${({ isMobile }) => (isMobile ? 'rotate(90deg)' : '')};
    }
`;

interface Props {
    setSelectedNumber: (number: number[]) => void;
    showRacetrack: boolean;
    isMobile: boolean;
}

const numbers = [
    4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5, 24, 16, 33, 1,
    20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26, 0, 32, 15, 19,
];

export const RacetrackComponent: React.FC<Props> = ({
    setSelectedNumber,
    showRacetrack,
    isMobile,
}) => {
    const ref = useRef<SVGSVGElement>(null);

    const { gameState, racetrackAdjacentCounter } = useGameContext();

    const { handlePlayerBet } = useLogicActionContext();

    const { activeChip, betHistory } = useLogicStateContext();

    const { effectsVolume, isEffectsMuted } = useAudio();

    const [chips, setChips] = useState<number[]>([]);

    const [hover, setHover] = useState('');

    const numberMap = useRef(
        new Map<number, { x: number; y: number }>()
    ).current;

    const [totalVoisinsBetAmount, setTotalVoisinsBetAmount] = useState(0);
    const [totalOrphelinsBetAmount, setTotalOrphelinsBetAmount] = useState(0);
    const [totalTiersBetAmount, setTotalTiersBetAmount] = useState(0);
    const [totalJeuZeroBetAmount, setTotalJeuZeroAmount] = useState(0);

    const voisinsBet = useMemo(
        () =>
            betHistory.filter(
                (bet) =>
                    bet.type === PlayerBaseBet.VOISINS_BET_SPLITS ||
                    bet.type === PlayerBaseBet.VOISINS_BET_CORNER ||
                    bet.type === PlayerBaseBet.VOISINS_BET_TRIO
            ),
        [betHistory]
    );

    const orphelinsBet = useMemo(
        () =>
            betHistory.filter(
                (bet) =>
                    bet.type === PlayerBaseBet.ORPHELINS_BET_SPLITS ||
                    bet.type === PlayerBaseBet.ORPHELINS_BET_STRAIGHT
            ),
        [betHistory]
    );

    const tiersBet = useMemo(
        () =>
            betHistory.filter(
                (bet) => bet.type === PlayerBaseBet.TIERS_BET_SPLIT
            ),
        [betHistory]
    );

    const jeuZeroBet = useMemo(
        () =>
            betHistory.filter(
                (bet) =>
                    bet.type === PlayerBaseBet.JEU_ZERO_SPLIT ||
                    bet.type === PlayerBaseBet.JEU_ZERO_STRAIGHT
            ),
        [betHistory]
    );

    const showVoisinsChips = gameState.ballPosition || voisinsBet.length > 0;

    const showOrphelinsChips =
        gameState.ballPosition || orphelinsBet.length > 0;

    const showTiersChips = gameState.ballPosition || tiersBet.length > 0;

    const showJeuZeroChips = gameState.ballPosition || jeuZeroBet.length > 0;

    useEffect(() => {
        const timer = setTimeout(() => {
            const totalVoisins = calculateTotalAmount(voisinsBet, 'voisins');
            const totalOrphelins = calculateTotalAmount(
                orphelinsBet,
                'orphelins'
            );
            const totalTiers = calculateTotalAmount(tiersBet, 'tiers');
            const totalJeuZero = calculateTotalAmount(jeuZeroBet, 'jeuZero');

            setTotalVoisinsBetAmount(totalVoisins);
            setTotalOrphelinsBetAmount(totalOrphelins);
            setTotalTiersBetAmount(totalTiers);
            setTotalJeuZeroAmount(totalJeuZero);
        }, 400);

        return () => clearTimeout(timer);
    }, [voisinsBet, orphelinsBet, tiersBet, jeuZeroBet]);

    useEffect(() => {
        if (gameState && gameState.ballPosition && gameState.ballPosition > 0) {
            setChips([]);
        }
    }, [gameState]);

    const CHIP_SOUNDS = [ChipForward];

    const handleClick: MouseEventHandler<SVGElement> = (event) => {
        if (!isEffectsMuted) {
            const sound = new Audio(CHIP_SOUNDS[0]);
            sound.volume = 0.4 * effectsVolume;
            sound.play();
        }

        const target = event.target as SVGElement;

        const selectedNumbers: number[] = [];
        if (target.tagName === 'path') {
            const parent = target.parentNode as SVGElement;

            const [type, , number] = parent.id.split('-');

            if (type === 'NUMBER') {
                // console.log('Picked', +number);

                selectedNumbers.push(+number);

                const index = numbers.findIndex((n) => n === +number);
                for (let i = 1; i <= racetrackAdjacentCounter; i++) {
                    const leftValueIndex =
                        (index - i + numbers.length) % numbers.length;
                    const rightValueIndex = (index + i) % numbers.length;

                    const leftValue = numbers[leftValueIndex];
                    const rightValue = numbers[rightValueIndex];

                    selectedNumbers.push(leftValue, rightValue);
                }

                if (activeChip) {
                    setChips((p) => {
                        if (p.includes(+number)) return p;
                        return [...p, ...selectedNumbers];
                    });
                }

                setSelectedNumber([...selectedNumbers]);
            } else if (type === 'VOISINS') {
                setTotalVoisinsBetAmount(0);
                voisinsBetTypes.forEach((value) => {
                    if (
                        value.number &&
                        value.type &&
                        activeChip &&
                        gameState.gameInfo &&
                        activeChip * voisinsBetTypes.length <
                            gameState.gameInfo.maxBet
                    ) {
                        handlePlayerBet(value.type, value.number);
                    }
                });
            } else if (type === 'ORPHELINS') {
                setTotalOrphelinsBetAmount(0);
                orphelinsBetTypes.forEach((value) => {
                    if (
                        value.number &&
                        value.type &&
                        activeChip &&
                        gameState.gameInfo &&
                        activeChip * orphelinsBetTypes.length <
                            gameState.gameInfo.maxBet
                    ) {
                        handlePlayerBet(value.type, value.number);
                    }
                });
            } else if (type === 'TIERS') {
                setTotalTiersBetAmount(0);
                tiersBetTypes.forEach((value) => {
                    if (
                        activeChip &&
                        gameState.gameInfo &&
                        activeChip * tiersBetTypes.length <
                            gameState.gameInfo.maxBet
                    ) {
                        handlePlayerBet(value.type, value.number);
                    }
                });
            } else if (type === 'racetrack') {
                setTotalJeuZeroAmount(0);
                jeuZeroBetTypes.forEach((value) => {
                    if (
                        activeChip &&
                        gameState.gameInfo &&
                        activeChip * jeuZeroBetTypes.length <
                            gameState.gameInfo.maxBet
                    ) {
                        handlePlayerBet(value.type, value.number);
                    }
                });
            }
        }
    };

    useEffect(() => {
        if (betHistory.length) {
            const numbers: number[] = [];
            const bets = betHistory.filter(
                (bets) => bets.type === PlayerBaseBet.RACETRACK_STRAIGHT_UP
            );
            bets.forEach((bet) => numbers.push(bet.betValues[0]));

            setChips([...numbers]);
        }
    }, [betHistory]);

    const getChipPosition = (number: number) => {
        if (numberMap.has(number)) {
            return numberMap.get(number);
        }
        return { x: 0, y: 0 };
    };

    useEffect(() => {
        if (ref.current) {
            for (let i = 0; i < ref.current.children.length; i += 1) {
                const child = ref.current.children.item(i);
                if (child) {
                    const [type, , number] = child.id.split('-');

                    if (type === 'NUMBER') {
                        const paths = child.getElementsByTagName('path');

                        if (paths.length > 0) {
                            const path = paths[0] as SVGPathElement;

                            const centroid = findSvgPathCentroid(path);

                            if (centroid) {
                                numberMap.set(+number, {
                                    x: centroid.x / 10,
                                    y: centroid.y / 10,
                                });
                            }
                        }
                    }
                }
            }
        }
    }, [numberMap]);

    return (
        <Wrapper>
            <Container isMobile={isMobile}>
                {chips.map((c) => {
                    const amount =
                        betHistory.length &&
                        betHistory.length &&
                        betHistory
                            .filter(
                                (bet) =>
                                    bet.type ===
                                        PlayerBaseBet.RACETRACK_STRAIGHT_UP &&
                                    bet.betValues.includes(c) &&
                                    bet.amount
                            )
                            .reduce(
                                (accumulator, currentBet) =>
                                    accumulator + currentBet.amount,
                                0
                            );

                    const pos = getChipPosition(c);
                    if (pos && amount) {
                        return (
                            <div
                                className="chip"
                                style={{
                                    left: `${pos.x}rem`,
                                    top: `${pos.y}rem`,
                                }}
                            >
                                <Chip value={amount} size={'x-small'} />
                            </div>
                        );
                    }
                    return null;
                })}
                {showVoisinsChips && (
                    <ChipAnimationGroup
                        bets={
                            totalVoisinsBetAmount
                                ? totalVoisinsBetAmount
                                : voisinsBet
                        }
                        betType={'voisins'}
                        radius={2}
                        x={18}
                        y={14}
                        offsetX={16}
                        offsetY={14}
                    >
                        {(chipPositions) =>
                            chipPositions.map((chip, index) => (
                                <ChipAnimation
                                    position={{ x: chip.x, y: chip.y }}
                                    value={chip.value}
                                    isMobile={isMobile}
                                />
                            ))
                        }
                    </ChipAnimationGroup>
                )}
                {showOrphelinsChips && (
                    <ChipAnimationGroup
                        bets={
                            totalOrphelinsBetAmount
                                ? totalOrphelinsBetAmount
                                : orphelinsBet
                        }
                        betType={'orphelins'}
                        radius={2}
                        x={28}
                        y={14}
                        offsetX={26}
                        offsetY={14}
                    >
                        {(chipPositions) =>
                            chipPositions.map((chip, index) => (
                                <ChipAnimation
                                    position={{ x: chip.x, y: chip.y }}
                                    value={chip.value}
                                    isMobile={isMobile}
                                />
                            ))
                        }
                    </ChipAnimationGroup>
                )}
                {showTiersChips && (
                    <ChipAnimationGroup
                        bets={
                            totalTiersBetAmount ? totalTiersBetAmount : tiersBet
                        }
                        betType={'tiers'}
                        radius={2}
                        x={40}
                        y={14}
                        offsetX={38}
                        offsetY={14}
                    >
                        {(chipPositions) =>
                            chipPositions.map((chip, index) => (
                                <ChipAnimation
                                    position={{ x: chip.x, y: chip.y }}
                                    value={chip.value}
                                    isMobile={isMobile}
                                />
                            ))
                        }
                    </ChipAnimationGroup>
                )}
                {showJeuZeroChips && (
                    <ChipAnimationGroup
                        bets={
                            totalJeuZeroBetAmount
                                ? totalJeuZeroBetAmount
                                : jeuZeroBet
                        }
                        betType={'jeuZero'}
                        radius={2}
                        x={10}
                        y={14}
                        offsetX={8}
                        offsetY={14}
                    >
                        {(chipPositions) =>
                            chipPositions.map((chip, index) => (
                                <ChipAnimation
                                    position={{ x: chip.x, y: chip.y }}
                                    value={chip.value}
                                    isMobile={isMobile}
                                />
                            ))
                        }
                    </ChipAnimationGroup>
                )}
            </Container>
            <Svg
                width="503"
                height="217"
                viewBox="0 0 503 217"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleClick}
                activeChip={activeChip}
                className={clsx(
                    hover === 'vvoisins' && 'voisins-hover',
                    hover === 'orphelins' && 'orphelins-hover',
                    hover === 'tiers' && 'tiers-hover',
                    hover === 'jeu-zero' && 'jeu-zero-hover'
                )}
                showRacetrack={showRacetrack}
                isMobile={isMobile}
            >
                <g id="racetrack" clipPath="url(#clip0_9_184)" ref={ref}>
                    <g id="Rectangle 66" filter="url(#filter0_b_9_184)">
                        <rect
                            width="503"
                            height="217"
                            rx="108.5"
                            fill="#0D0D0D"
                            fillOpacity="0.5"
                        />
                    </g>

                    <path
                        id="Ellipse-1"
                        className="jeu-zero"
                        onMouseOver={() => setHover('jeu-zero')}
                        onMouseOut={() => setHover('')}
                        data-attribute
                        data-type={'0-GAME'}
                        d="M168.594 108.5C168.594 140.399 142.704 166.259 110.767 166.259C78.8297 166.259 52.9404 140.399 52.9404 108.5C52.9404 76.6013 78.8297 50.7414 110.767 50.7414C142.704 50.7414 168.594 76.6013 168.594 108.5Z"
                        stroke="#767676"
                    />

                    <path
                        className="vvoisins orphelins"
                        id="Vector 1"
                        d="M225.815 51.3104L225.815 165.69"
                        stroke="#767676"
                    />

                    <path
                        className="orphelins tiers"
                        id="Vector 2"
                        d="M305.011 51.3104L356.916 166.224"
                        stroke="#767676"
                    />
                    <g className="number red two" id="NUMBER-BLACK-2">
                        <path
                            id="Vector"
                            d="M199.191 0H173.61V50.7336H199.191V0Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={2}
                        />
                        <text
                            id="2"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 187 28)' : ''}
                        >
                            <tspan x="183.006" y="31.0723">
                                2
                            </tspan>
                        </text>
                    </g>
                    <g className="number red twentyFive" id="NUMBER-RED-25">
                        <path
                            id="Vector_2"
                            d="M225.532 0H199.951V50.7336H225.532V0Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={25}
                        />
                        <text
                            id="25"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 212 28)' : ''}
                        >
                            <tspan x="206.551" y="31.0723">
                                25
                            </tspan>
                        </text>
                    </g>
                    <g className="number red eighteen" id="NUMBER-RED-18">
                        <path
                            id="Vector_3"
                            d="M199.191 166.266H173.61V217H199.191V166.266Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={18}
                        />
                        <text
                            id="18"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 185 189)' : ''}
                        >
                            <tspan x="180.866" y="193.555">
                                18
                            </tspan>
                        </text>
                    </g>

                    <g className="number black seventeen" id="NUMBER-BLACK-17">
                        <path
                            id="Vector_4"
                            data-type="NUMBER"
                            data-number={17}
                            data-attribute
                            d="M251.872 0H226.292V50.7336H251.872V0Z"
                            fill="#16181A"
                        />
                        <text
                            id="17"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 239 28)' : ''}
                        >
                            <tspan x="234.377" y="31.0723">
                                17
                            </tspan>
                        </text>
                    </g>

                    <g className="number red twentyOne" id="NUMBER-RED-21">
                        <path
                            id="Vector_5"
                            d="M172.843 0H147.262V50.7336H172.843V0Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={21}
                        />
                        <text
                            id="21"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 159 28)' : ''}
                        >
                            <tspan x="155.181" y="31.0723">
                                21
                            </tspan>
                        </text>
                    </g>

                    <g className="number red seven" id="NUMBER-RED-7">
                        <path
                            id="Vector_6"
                            d="M146.503 166.266H120.922V217H146.503V166.266Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={7}
                        />
                        <text
                            id="7"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 135 189)' : ''}
                        >
                            <tspan x="130.566" y="193.555">
                                7
                            </tspan>
                        </text>
                    </g>
                    <g className="number black twentyNine" id="NUMBER-BLACK-29">
                        <path
                            id="Vector_7"
                            d="M172.843 166.266H147.262V217H172.843V166.266Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={29}
                        />
                        <text
                            id="29"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 159 189)' : ''}
                        >
                            <tspan x="154.111" y="193.555">
                                29
                            </tspan>
                        </text>
                    </g>

                    <g className="number red thirtyFour" id="NUMBER-RED-34">
                        <path
                            id="Vector_8"
                            d="M278.213 0H252.632V50.7336H278.213V0Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={34}
                        />
                        <text
                            id="34"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 264 28)' : ''}
                        >
                            <tspan x="258.991" y="31.0723">
                                34
                            </tspan>
                        </text>
                    </g>

                    <g className="number black four" id="NUMBER-BLACK-4">
                        <path
                            id="Vector_9"
                            d="M146.503 0H120.922V50.7336H146.503V0Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={4}
                        />
                        <text
                            id="4"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 133 29)' : ''}
                        >
                            <tspan x="130.142" y="31.0723">
                                4
                            </tspan>
                        </text>
                    </g>

                    <g className="number red twentyThree" id="NUMBER-RED-23">
                        <path
                            id="Vector_10"
                            d="M446.984 88.0547C449.392 94.4116 450.722 101.3 450.722 108.5C450.722 108.743 450.706 108.986 450.699 109.229L502.992 109.29C502.992 109.024 503 108.766 503 108.5C503 92.8166 499.619 77.8776 493.564 64.3815L446.984 88.0547Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={23}
                        />
                        <text
                            id="23"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 476 92)' : ''}
                        >
                            <tspan x="469.823" y="95.2103">
                                23
                            </tspan>
                        </text>
                    </g>

                    <g className="number red thirty" id="NUMBER-RED-30">
                        <path
                            id="Vector_11"
                            d="M425.931 4.68602L409.209 53.0728C417.627 55.5411 425.255 59.8626 431.606 65.5815L465.408 26.5516C454.049 16.7011 440.64 9.16698 425.931 4.67843V4.68602Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={30}
                        />
                        <text
                            id="30"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 435 33)' : ''}
                        >
                            <tspan x="428.085" y="36.4172">
                                30
                            </tspan>
                        </text>
                    </g>

                    <g className="number black eight" id="NUMBER-BLACK-8">
                        <path
                            id="Vector_12"
                            d="M432.161 66.098C438.482 71.946 443.489 79.1915 446.703 87.3483L493.252 63.6904C486.817 49.6247 477.465 37.154 465.977 27.0604L432.161 66.098Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={8}
                        />
                        <text
                            id="8"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 464 58)' : ''}
                        >
                            <tspan x="459.121" y="61.0034">
                                8
                            </tspan>
                        </text>
                    </g>

                    <g className="number black ten" id="NUMBER-BLACK-10">
                        <path
                            id="Vector_13"
                            d="M445.814 131.793L492.971 153.902C499.194 140.512 502.757 125.672 502.977 110.057L450.676 109.996C450.478 117.735 448.769 125.102 445.814 131.793Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={10}
                        />
                        <text
                            id="10"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 476 127)' : ''}
                        >
                            <tspan x="470.894" y="130.486">
                                10
                            </tspan>
                        </text>
                    </g>

                    <g className="number black twentyTwo" id="NUMBER-BLACK-22">
                        <path
                            id="Vector_14"
                            d="M225.532 166.266H199.951V217H225.532V166.266Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={22}
                        />
                        <text
                            id="22"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 212 189)' : ''}
                        >
                            <tspan x="206.551" y="193.555">
                                22
                            </tspan>
                        </text>
                    </g>

                    <g className="number black eleven" id="NUMBER-BLACK-11">
                        <path
                            id="Vector_15"
                            d="M384.35 50.7336H392.935C398.322 50.7336 403.534 51.4855 408.48 52.8677L425.209 4.47336C415.454 1.57213 405.137 0.00759125 394.47 0.00759125H384.35V50.7412V50.7336Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={11}
                        />
                        <text
                            id="11"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 399 28)' : ''}
                        >
                            <tspan x="394.909" y="31.0723">
                                11
                            </tspan>
                        </text>
                    </g>

                    <g className="number red twentySeven" id="NUMBER-RED-27">
                        <path
                            id="Vector_16"
                            d="M330.901 0H305.321V50.7336H330.901V0Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={27}
                        />
                        <text
                            id="27"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 316 28)' : ''}
                        >
                            <tspan x="311.432" y="31.0723">
                                27
                            </tspan>
                        </text>
                    </g>

                    <g className="number black six" id="NUMBER-BLACK-6">
                        <path
                            id="Vector_17"
                            d="M304.561 0H278.98V50.7336H304.561V0Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={6}
                        />
                        <text
                            id="6"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 292 28)' : ''}
                        >
                            <tspan x="288.957" y="31.0723">
                                6
                            </tspan>
                        </text>
                    </g>

                    <g className="number black thirteen" id="NUMBER-BLACK-13">
                        <path
                            id="Vector_18"
                            d="M357.242 0H331.661V50.7336H357.242V0Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={13}
                        />

                        <text
                            id="13"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 344 28)' : ''}
                        >
                            <tspan x="339.257" y="31.0723">
                                13
                            </tspan>
                        </text>
                    </g>

                    <g className="number black twentyFour" id="NUMBER-BLACK-24">
                        <path
                            id="Vector_19"
                            data-type="NUMBER"
                            data-number={24}
                            d="M425.506 212.443C440.009 208.084 453.252 200.762 464.541 191.185L431.53 151.456C425.103 157.229 417.391 161.596 408.852 164.034L425.513 212.443H425.506Z"
                            fill="#16181A"
                        />

                        <text
                            id="24"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 433 183)' : ''}
                        >
                            <tspan x="428.085" y="186.072">
                                24
                            </tspan>
                        </text>
                    </g>

                    <g className="number black fifteen" id="NUMBER-BLACK-15">
                        <path
                            id="Vector_20"
                            d="M82.6988 3.13667C68.765 6.56954 55.872 12.7138 44.6125 20.9694L76.3549 61.6018C82.0834 57.4778 88.5869 54.3639 95.6222 52.5563L82.6988 3.13667Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={15}
                        />
                        <text
                            id="15"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 75 33)' : ''}
                        >
                            <tspan x="70.634" y="36.4172">
                                15
                            </tspan>
                        </text>
                    </g>

                    <g className="number red three" id="NUMBER-RED-3">
                        <path
                            id="Vector_21"
                            d="M16.3346 165.545L61.3118 139.487C57.9157 134.155 55.3706 128.231 53.8587 121.897L2.06653 129.53C4.64208 142.502 9.55007 154.661 16.3346 165.545Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={3}
                        />
                        <text
                            id="3"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 33 140)' : ''}
                        >
                            <tspan x="29.9659" y="142.245">
                                3
                            </tspan>
                        </text>
                    </g>

                    <g className="number black twentySix" id="NUMBER-BLACK-26">
                        <path
                            id="Vector_22"
                            d="M53.6839 121.161C52.7722 117.09 52.286 112.852 52.286 108.5C52.286 104.991 52.6051 101.566 53.2053 98.2318L1.53469 90.3559C0.531825 96.2571 0 102.318 0 108.5C0 115.427 0.66858 122.209 1.92217 128.786L53.6839 121.161Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={26}
                        />
                        <text
                            id="26"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 24 109)' : ''}
                        >
                            <tspan x="18.1936" y="112.314">
                                26
                            </tspan>
                        </text>
                    </g>

                    <g className="number red thirtyTwo" id="NUMBER-RED-32">
                        <path
                            id="Vector_23"
                            d="M60.7268 78.4244C64.6319 72.0371 69.7374 66.4777 75.7318 62.0423L43.9971 21.4175C32.73 29.7946 23.1268 40.2907 15.7952 52.3133L60.7268 78.432V78.4244Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={32}
                        />
                        <text
                            id="32"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 47 50)' : ''}
                        >
                            <tspan x="42.8085" y="54.5896">
                                32
                            </tspan>
                        </text>
                    </g>

                    <g className="number red nine " id="NUMBER-RED-9">
                        <path
                            id="Vector_24"
                            d="M251.872 166.266H226.292V217H251.872V166.266Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={9}
                        />
                        <text
                            id="9"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 240 189)' : ''}
                        >
                            <tspan x="236.517" y="193.555">
                                9
                            </tspan>
                        </text>
                    </g>

                    <g className="number red five" id="NUMBER-RED-5">
                        <path
                            id="Vector_25"
                            data-type="NUMBER"
                            data-number={5}
                            d="M445.502 132.485C442.288 139.51 437.692 145.768 432.085 150.94L465.119 190.691C476.652 180.765 486.08 168.469 492.66 154.586L445.51 132.485H445.502Z"
                            fill="#F24822"
                        />
                        <text
                            id="5"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 464 159)' : ''}
                        >
                            <tspan x="459.121" y="162.555">
                                5
                            </tspan>
                        </text>
                    </g>
                    <g className="number red sixteen" id="NUMBER-RED-16">
                        <path
                            id="Vector_26"
                            d="M408.115 164.239C403.275 165.552 398.192 166.266 392.935 166.266H384.35V217H394.47C404.977 217 415.15 215.481 424.784 212.663L408.122 164.246L408.115 164.239Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={16}
                        />
                        <text
                            id="16"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 400 192)' : ''}
                        >
                            <tspan x="394.909" y="193.555">
                                16
                            </tspan>
                        </text>
                    </g>

                    <g
                        className="number black twentyEight"
                        id="NUMBER-BLACK-28"
                    >
                        <path
                            id="Vector_27"
                            d="M120.162 166.266H110.073C105.537 166.266 101.13 165.727 96.8985 164.74L85.4795 214.509C92.9174 216.134 100.629 217 108.538 217H120.162V166.266Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={28}
                        />

                        <text
                            id="28"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 106 189)' : ''}
                        >
                            <tspan x="99.5298" y="193.555">
                                28
                            </tspan>
                        </text>
                    </g>

                    <g className="number black thirtyFive" id="NUMBER-BLACK-35">
                        <path
                            id="Vector_28"
                            d="M75.5495 154.829C70.1249 150.781 65.4449 145.798 61.7221 140.133L16.7373 166.198C23.9625 177.621 33.2543 187.616 44.0807 195.651L75.5495 154.836V154.829Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={35}
                        />
                        <text
                            id="35"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 48 165)' : ''}
                        >
                            <tspan x="42.8085" y="168.969">
                                35
                            </tspan>
                        </text>
                    </g>

                    <g className="number green zero" id="NUMBER-GREEN-0">
                        <path
                            id="Vector_29"
                            d="M1.66382 89.604L53.342 97.4799C54.6184 90.8724 57.0192 84.6674 60.3393 79.07L15.4077 52.9513C8.74469 64.0474 4.00385 76.4194 1.66382 89.5964V89.604Z"
                            fill="#14AE5C"
                            data-type="NUMBER"
                            data-number={0}
                        />
                        <text
                            id="0"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 32 80)' : ''}
                        >
                            <tspan x="27.8256" y="82.3827">
                                0
                            </tspan>
                        </text>
                    </g>

                    <g className="number red twelve" id="NUMBER-RED-12">
                        <path
                            id="Vector_30"
                            d="M84.7349 214.349L96.1539 164.573C88.8375 162.765 82.0834 159.568 76.1649 155.269L44.6885 196.091C56.4798 204.719 70.0565 211.03 84.7349 214.349Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={12}
                        />

                        <text
                            id="12"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 76 185)' : ''}
                        >
                            <tspan x="68.4937" y="188.21">
                                12
                            </tspan>
                        </text>
                    </g>

                    <g className="number red fourteen" id="NUMBER-RED-14">
                        <path
                            id="Vector_31"
                            d="M304.561 166.266H278.98V217H304.561V166.266Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={14}
                        />
                        <text
                            id="14"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 291 189)' : ''}
                        >
                            <tspan x="285.747" y="193.555">
                                14
                            </tspan>
                        </text>
                    </g>

                    <g className="number black twenty" id="NUMBER-BLACK-20">
                        <path
                            id="Vector_32"
                            d="M330.901 166.266H305.321V217H330.901V166.266Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={20}
                        />
                        <text
                            id="20"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 318 189)' : ''}
                        >
                            <tspan x="311.432" y="193.555">
                                20
                            </tspan>
                        </text>
                    </g>

                    <g className="number black thirtyOne" id="NUMBER-BLACK-31">
                        <path
                            id="Vector_33"
                            d="M278.213 166.266H252.632V217H278.213V166.266Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={31}
                        />
                        <text
                            id="31"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 265 189)' : ''}
                        >
                            <tspan x="260.062" y="193.555">
                                31
                            </tspan>
                        </text>
                    </g>

                    <g className="number red one" id="NUMBER-RED-1">
                        <path
                            id="Vector_34"
                            d="M357.242 166.266H331.661V217H357.242V166.266Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={1}
                        />
                        <text
                            id="1"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 346 189)' : ''}
                        >
                            <tspan x="342.468" y="193.555">
                                1
                            </tspan>
                        </text>
                    </g>

                    <g
                        className="number black thirtyThree"
                        id="NUMBER-BLACK-33"
                    >
                        <path
                            id="Vector_35"
                            d="M383.582 166.266H358.002V217H383.582V166.266Z"
                            fill="#16181A"
                            data-type="NUMBER"
                            data-number={33}
                        />
                        <text
                            id="33"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 372 189)' : ''}
                        >
                            <tspan x="363.872" y="193.555">
                                33
                            </tspan>
                        </text>
                    </g>
                    <g className="number red nineteen" id="NUMBER-RED-19">
                        <path
                            id="Vector_36"
                            d="M96.3591 52.3817C100.758 51.3108 105.347 50.7336 110.073 50.7336H120.162V0H108.538C99.9072 0 91.4967 1.0253 83.4358 2.9544L96.3591 52.3817Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={19}
                        />
                        <text
                            id="19"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 105 28.0723)' : ''}
                        >
                            <tspan x="99.5298" y="31.0723">
                                19
                            </tspan>
                        </text>
                    </g>
                    <g className="number red thirtySix" id="NUMBER-RED-36">
                        <path
                            id="Vector_37"
                            d="M383.582 0H358.002V50.7336H383.582V0Z"
                            fill="#F24822"
                            data-type="NUMBER"
                            data-number={36}
                        />
                        <text
                            id="36"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="11"
                            fontWeight="500"
                            letterSpacing="0em"
                            transform={
                                isMobile ? 'rotate(90 370.872 28.0723)' : ''
                            }
                        >
                            <tspan x="363.872" y="31.0723">
                                36
                            </tspan>
                        </text>
                    </g>
                    <text
                        id="jeu-zero"
                        fill="white"
                        xmlSpace="preserve"
                        style={{ whiteSpace: 'pre' }}
                        fontFamily="Chakra Petch"
                        fontSize="10"
                        letterSpacing="0em"
                        transform={isMobile ? 'rotate(90 105 110)' : ''}
                    >
                        <tspan x="92.0383" y="113.61">
                            {isMobile ? 'ZERO' : '0 GAME'}
                        </tspan>
                    </text>

                    <g
                        id="VOISINS"
                        onMouseOver={() => setHover('vvoisins')}
                        onMouseOut={() => setHover('')}
                    >
                        <path
                            id="Vector_39"
                            d="M110.878 51.0065C142.549 51.0065 168.217 76.7676 168.217 108.531C168.217 140.295 142.539 166.056 110.878 166.056H225.671V51.0065H110.878Z"
                            fill="black"
                            fillOpacity="0.01"
                        />
                        <text
                            id="Voisins"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="10"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 199 110)' : ''}
                        >
                            <tspan x="179.796" y="113.61">
                                Voisins
                            </tspan>
                        </text>

                        {hover === 'vvoisins' && (
                            <path
                                className="vvoisins"
                                d="M102.5 216C-17 211.5 -49.5 24 102.5 0H226V216H102.5Z"
                            />
                        )}
                    </g>

                    <g
                        id="ORPHELINS"
                        onMouseOver={() => setHover('orphelins')}
                        onMouseOut={() => setHover('')}
                    >
                        <path
                            id="Vector_41"
                            d="M225.671 51.0065V166.056H356.119L304.059 51.0065H225.671Z"
                            fill="black"
                            fillOpacity="0.01"
                        />
                        <text
                            id="Orphelins"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="10"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 274 110)' : ''}
                        >
                            <tspan x="254.711" y="113.61">
                                Orphelins
                            </tspan>
                        </text>

                        {hover === 'orphelins' && (
                            <path
                                id="Vector 3"
                                className="orphelins"
                                d="M357 216.5H226L225 0.5L304.5 0V51L357 167V216.5Z"
                            />
                        )}
                    </g>

                    <g
                        id="TIERS"
                        onMouseOver={() => setHover('tiers')}
                        onMouseOut={() => setHover('')}
                    >
                        <path
                            id="Vector_38"
                            d="M393.133 51.0065H304.059L356.119 166.066H393.133C424.804 166.066 450.472 140.305 450.472 108.541C450.472 76.778 424.794 51.0168 393.133 51.0168V51.0065Z"
                            fill="black"
                            fillOpacity="0.01"
                        />
                        <text
                            id="Tiers"
                            fill="white"
                            xmlSpace="preserve"
                            style={{ whiteSpace: 'pre' }}
                            fontFamily="Chakra Petch"
                            fontSize="10"
                            letterSpacing="0em"
                            transform={isMobile ? 'rotate(90 389 110)' : ''}
                        >
                            <tspan x="379.926" y="113.61">
                                Tiers
                            </tspan>
                        </text>

                        {hover === 'tiers' && (
                            <path
                                id="Vector 4"
                                className="tiers"
                                d="M403.5 0.5C523.5 9.5 547.5 194.5 403.5 216H357.5V167L304.5 50.5V0.5L404.5 0.579252"
                            />
                        )}
                    </g>

                    {hover === 'jeu-zero' && (
                        <path
                            id="Vector 8"
                            className="jeu-zero"
                            d="M96 164L85 214.5C-15 195.5 -39 40.5 83.5 3L96 52.5"
                        />
                    )}
                </g>
                <defs>
                    <filter
                        id="filter0_b_9_184"
                        x="-40"
                        y="-40"
                        width="583"
                        height="297"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feGaussianBlur
                            in="BackgroundImageFix"
                            stdDeviation="20"
                        />
                        <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur_9_184"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_9_184"
                            result="shape"
                        />
                    </filter>
                    <clipPath id="clip0_9_184">
                        <rect width="503" height="217" fill="white" />
                    </clipPath>
                </defs>
            </Svg>
        </Wrapper>
    );
};
