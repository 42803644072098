import {
    isNumberBlack,
    isNumberEven,
    isNumberGreen,
    isNumberHigh,
    isNumberInTwelve,
    isNumberLow,
    isNumberRed,
} from './score.utils';

export const RouletteStats = (resultHistory: number[]) => {
    const totalCount = resultHistory.length;

    const numbers: { [key: string]: number } = new Array(37)
        .fill(0)
        .reduce((a, c, i) => ({ ...a, [i]: c }), {});

    const results = resultHistory.reduce(
        (acc, cur) => {
            acc.evenNumbersLength += +isNumberEven(cur);
            acc.oneToEighteen += +isNumberLow(cur);
            acc.eighteenToThirtySix += +isNumberHigh(cur);
            acc.redCount += +isNumberRed(cur);
            acc.blackCount += +isNumberBlack(cur);
            acc.greenCount += +isNumberGreen(cur);
            acc.firstDozen += +isNumberInTwelve(cur, 1);
            acc.secondDozen += +isNumberInTwelve(cur, 2);
            acc.thirdDozen += +isNumberInTwelve(cur, 3);

            acc.numbers[cur] += 1;

            return acc;
        },
        {
            evenNumbersLength: 0,
            oneToEighteen: 0,
            eighteenToThirtySix: 0,
            redCount: 0,
            greenCount: 0,
            blackCount: 0,

            firstDozen: 0,
            secondDozen: 0,
            thirdDozen: 0,
            numbers: { ...numbers },
        }
    );

    const oddNumbersLength = totalCount - results.evenNumbersLength;

    const evenPercentage = (
        totalCount > 0 ? (results.evenNumbersLength / totalCount) * 100 : 0
    ).toFixed(1);

    const oddPercentage = (
        totalCount > 0 ? (oddNumbersLength / totalCount) * 100 : 0
    ).toFixed(1);

    const oneToEighteenPercentage = (
        totalCount > 0 ? (results.oneToEighteen / totalCount) * 100 : 0
    ).toFixed(1);
    const eighteenToThirtySixPercentage = (
        totalCount > 0 ? (results.eighteenToThirtySix / totalCount) * 100 : 0
    ).toFixed(1);

    const firstDozenPercentage =
        totalCount > 0 ? (results.firstDozen / totalCount) * 100 : 0;

    const secondDozenPercentage =
        totalCount > 0 ? (results.secondDozen / totalCount) * 100 : 0;

    const thirdDozenPercentage =
        totalCount > 0 ? (results.thirdDozen / totalCount) * 100 : 0;

    const redPercentage = +((results.redCount / totalCount) * 100).toFixed(1);

    const greenPrecentage = +((results.greenCount / totalCount) * 100).toFixed(
        1
    );

    const blackPercentage = +((results.blackCount / totalCount) * 100).toFixed(
        1
    );

    const sortedNumbers = Object.entries(results.numbers)
        .map(([key, value]) => ({
            number: key,
            amount: value,
        }))
        .sort((a, b) => {
            return b.amount - a.amount;
        });

    const hotNumbers = sortedNumbers.slice(0, 5).filter((n) => n.amount > 0);
    const coldNumbers = sortedNumbers.slice(-5);

    return {
        evenPercentage,
        oddPercentage,
        oneToEighteenPercentage,
        eighteenToThirtySixPercentage,
        redPercentage,
        greenPrecentage,
        blackPercentage,
        firstDozenPercentage,
        secondDozenPercentage,
        thirdDozenPercentage,
        hotNumbers,
        coldNumbers,
    };
};
