import styled from '@emotion/styled';

import { InputRange } from './input-range';

const Container = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    color: var(--color-primary);
`;

const Title = styled.div`
    color: white;
    font-family: Chakra Petch;
`;

interface AudioControllerProps {
    volume: number;
    setVolume: (value: number) => void;
    title: string;
}

export const AudioController = ({
    title,
    volume,
    setVolume,
}: AudioControllerProps) => {
    return (
        <Container>
            <Title>{title}</Title>
            <InputRange
                value={volume * 100}
                minValue={0}
                maxValue={100}
                onChange={(v) => setVolume(v / 100)}
            />
        </Container>
    );
};
