import styled from '@emotion/styled';
import clsx from 'clsx';

import { FaChartSimple, FaStar } from 'react-icons/fa6';
import { IoMenu, IoRepeat } from 'react-icons/io5';

import {
    useGameContext,
    useLogicActionContext,
    useLogicStateContext,
} from '@roulette/contexts';
import { RouletteStates } from '@roulette/models';

import { ChipRow } from '@roulette/components';
import { ChipRowButton } from '@shared/ui/components/chips';
import { useMemo } from 'react';
import { useAudio } from '@shared/ui/contexts';

const PERSPECTIVE_TIMER = 1000;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: auto;
    height: 100%;
    color: var(--color-theme-white);

    pointer-events: auto;
`;

const MenuRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 12.6rem;

    .menu-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        height: 3.6rem;
        width: 3.6rem;
        color: var(--color-secondary);
        border-radius: 0.8rem;
    }

    .border {
        border: 0.01rem solid var(--color-border);
    }

    .menu-icon {
        height: 2rem;
        width: 2rem;
    }

    .show-racetrack {
        background: radial-gradient(
                112.5% 112.5% at 50% 15.28%,
                rgba(12, 12, 12, 0.53) 24.5%,
                rgba(0, 0, 0, 0.2332) 100%
            ),
            var(--color-secondary);
        border: 0.5px solid var(--color-border-accent);
        border-radius: 8px;
    }

    .hide-racetrack {
        background-color: none;
        color: var(--color-secondary);
    }
`;

const ChipRowContainer = styled.div<{ changePerspective: boolean }>`
    transform: scale(0.7) rotate(270deg);

    transition: transform ${PERSPECTIVE_TIMER}ms ease-in-out,
        opacity ${PERSPECTIVE_TIMER}ms ease-in-out;
    transform: ${({ changePerspective }) =>
        changePerspective
            ? 'scale(0.6) rotate(270deg) translateY(100px)'
            : ' scale(0.6) rotate(270deg) translateX(0)'};
    opacity: ${({ changePerspective }) => (changePerspective ? 0 : 1)};
`;

const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .icon {
        height: 2rem;
        width: 2rem;
    }

    div {
        height: 3rem;
        width: 3rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        margin-bottom: 0.5rem;
        border-radius: 1rem;
        background: black;
        border: 0.1rem solid #63839e;
        align-content: center;
    }

    .selected {
        box-sizing: border-box;

        background: radial-gradient(
                112.5% 112.5% at 50% 15.28%,
                rgba(12, 12, 12, 0.53) 24.5%,
                rgba(0, 0, 0, 0.2332) 100%
            ),
            #93c2e8;
        border: 0.5px solid var(--roulette-blue);
        border-radius: 8px;
    }
`;

interface Props {
    showRacetrack: boolean;
    setShowRacetrack: (boolean: boolean) => void;
    changePerspective: boolean;
    activePopupBox: string;
    setActivePopupBox: (string: string) => void;
}

export default function MobileMenu(props: Props) {
    const { gameState } = useGameContext();

    const { canRepeat } = useLogicStateContext();

    const { repeatLast } = useLogicActionContext();

    const { effectsVolume, isEffectsMuted } = useAudio();

    const canBet = useMemo(
        () =>
            [
                RouletteStates.WaitingForPlayers,
                RouletteStates.WaitingForBets,
            ].includes(gameState.currentState.next_state),
        [gameState.currentState.next_state]
    );

    const repeatButtonDisabled = !canBet || !canRepeat;

    return (
        <Container>
            <BoxContainer>
                <div
                    className={clsx(
                        'last-results',
                        props.activePopupBox === 'last-five-hundred' &&
                            'selected'
                    )}
                    onClick={() => props.setActivePopupBox('last-five-hundred')}
                >
                    <FaStar
                        className={clsx('icon')}
                        color="var(--roulette-gray-color)"
                    />
                </div>
                <div
                    className={clsx(
                        'charts',
                        props.activePopupBox === 'hot-n-cold' && 'selected'
                    )}
                    onClick={() => props.setActivePopupBox('hot-n-cold')}
                >
                    <FaChartSimple
                        className={clsx('icon')}
                        color="var(--roulette-gray-color)"
                    />
                </div>
            </BoxContainer>
            <ChipRowContainer changePerspective={props.changePerspective}>
                <ChipRow isMobile={true} />
            </ChipRowContainer>
            <MenuRow>
                <div className="menu-button">
                    <ChipRowButton
                        onClickHandler={repeatLast}
                        disabled={repeatButtonDisabled}
                        svg={
                            <IoRepeat
                                color={
                                    !repeatButtonDisabled
                                        ? 'var(--color-secondary)'
                                        : ''
                                }
                                style={{ height: '2rem', width: '2rem' }}
                            />
                        }
                        volume={effectsVolume}
                        muted={isEffectsMuted}
                    />
                </div>
                <div
                    className={clsx(
                        'menu-button',
                        'border',
                        props.showRacetrack
                            ? 'show-racetrack'
                            : 'hide-racetrack'
                    )}
                    onClick={() => props.setShowRacetrack(!props.showRacetrack)}
                >
                    Track
                </div>
                <div className="menu-button border">
                    <IoMenu className="menu-icon" />
                </div>
            </MenuRow>
        </Container>
    );
}
