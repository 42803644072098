import { useEffect, useRef } from 'react';

export interface AudioPlayerProps {
    autoPlay: boolean;
    volume: number;
    muted: boolean;
    loop: boolean;
    src: string;
}

export const AudioPlayer = (props: AudioPlayerProps) => {
    const { muted, volume, loop, src } = props;
    const audioRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
    }, [volume]);

    return (
        <audio autoPlay muted={muted} ref={audioRef} loop={loop}>
            <source src={src} type="audio/mpeg" />
        </audio>
    );
};
