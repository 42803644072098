import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
} from 'react';

export enum GlobalMessage {
    CLOSE_IFRAME = 'close-iframe',
}

export interface GlobalMessageData {
    message: GlobalMessage;
}

export interface GlobalMessagesContextProviderProps extends PropsWithChildren {
    allowedGameUrls: string[];
}

const GlobalMessagesContext = createContext<{ allowedGameUrls: string[] }>({
    allowedGameUrls: [],
});

export const GlobalMessagesContextProvider = (
    props: GlobalMessagesContextProviderProps
) => {
    const { children, allowedGameUrls } = props;

    return (
        <GlobalMessagesContext.Provider value={{ allowedGameUrls }}>
            {children}
        </GlobalMessagesContext.Provider>
    );
};

export const useGlobalMessagesContext = () => {
    const context = useContext(GlobalMessagesContext);
    return context;
};

export const useSendGlobalMessage = () => {
    const postMessage = useCallback((data: GlobalMessageData) => {
        window.parent.postMessage(data, window.location.origin);
    }, []);

    return postMessage;
};

export const useListenGlobalMessage = (
    callback: (data: GlobalMessageData) => void
) => {
    const { allowedGameUrls } = useGlobalMessagesContext();

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            // Block requests without a valid gameUrl
            if (allowedGameUrls.includes(event.origin)) {
                callback(event.data);
            } else {
                console.warn(
                    `Blocked message from untrusted origin: ${event.origin}`
                );
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [callback, allowedGameUrls]);
};
